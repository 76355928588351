<template>
	<PageHeader state="true" msg="设备注册"></PageHeader>
	<div class="bg">
		<div class="container_box">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="设备唯一码" prop="uniqueCode">
					<el-input v-model="ruleForm.uniqueCode" placeholder="请输入设备唯一码"></el-input>
			</el-form-item>
			<div class="add" @click="scan()">扫码</div>
		<el-form-item label="投放区域" prop="region">
			<el-select v-model="ruleForm.region" placeholder="请选择投放地址">
				<el-option v-for="item in addressList" :key="item.id" :label="item.area + item.addressName" :value="item.id"></el-option>
	    </el-select>
	  </el-form-item>
	  <div class="add" @click="goAddress()">新增投放地址</div>
		<el-form-item label="套餐选择" prop="type">
		  <el-checkbox-group v-model="ruleForm.type">
		    <el-checkbox
		      v-for="item in taocan"
		      :key="item.id"
		      :label="item.packageName"
		      name="type"
		    ></el-checkbox>
		  </el-checkbox-group>
		</el-form-item>
		<div class="add" @click="goPackage()">新增套餐</div>
		
        <!-- <el-form-item label="设备唯一码" prop="uniqueCode">
          <el-input
            v-model="ruleForm.uniqueCode"
            placeholder="请输入设备唯一码"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="设备编号" prop="chargerIndex">
          <el-input
            v-model="ruleForm.chargerIndex"
            placeholder="自定义设备编号,同一场地不可相同"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="端口数量" prop="portCount">
          <el-input
            v-model.number="ruleForm.portCount"
            placeholder="请输入充电桩端口数量"
          ></el-input>
        </el-form-item> -->
        

        <el-form-item label="备注" prop="desc">
          <el-input
            v-model.number="ruleForm.desc"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
	<div class="btn" @click="submitForm('ruleForm')">保存</div>
  </div>
</template>

<script>
import {
  chargerPackageList,
  addressList,
  addCharger,
} from "../../../http/index.js";
import { ElMessage } from "element-plus";
export default {
  data() {
    return {
      taocan: [],
      addressList: [],
      ruleForm: {
        uniqueCode: "",
        chargerIndex: "",
        portCount: "",
        desc: "",
        type: [],
        region: "",
      },
      rules: {
        uniqueCode: [
          { required: true, message: "请输入设备唯一码", trigger: "blur" },
        ],
        chargerIndex: [
          {
            required: true,
            message: "请输入设备编号，以便区分充电桩",
            trigger: "blur",
          },
        ],
        // portCount: [
        //   { required: true, message: "请输入端口数量", trigger: "blur" },
        //   { type: "number", message: "端口数量为整数", trigger: "blur" },
        // ],
        type: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个套餐",
            trigger: "change",
          },
        ],
        region: [
          { required: true, message: "请选择投放地址", trigger: "change" },
        ],
      },
    };
  },
	methods: {
		submitForm(formName) {
			let that = this
			this.$refs[formName].validate((valid) => {
			if (valid) {
			let data = {
				userId: localStorage.getItem("userId"),
				uniqueCode: this.ruleForm.uniqueCode,
				packageIds: "",
				portCount: 20,
				desc: this.ruleForm.desc,
				addressId: this.ruleForm.region,
				chargerIndex: this.ruleForm.chargerIndex,
			};
			for (var i = 0; i < this.ruleForm.type.length; i++) {
				for (var w = 0; w < this.taocan.length; w++) {
					if (this.ruleForm.type[i] == this.taocan[w]["packageName"]) {
						data["packageIds"] += this.taocan[w]["id"] + ",";
					}
				}
			}
          data["packageIds"] = data["packageIds"].slice(
            0,
            data["packageIds"].length - 1
          );
          addCharger(data)
            .then((res) => {
              if (res.code == 200) {
                this.succes("添加设备成功");
				localStorage.removeItem('unique')
				localStorage.removeItem('ruleForm')
				setTimeout(function(){
					that.$router.back();
				},1500)
              } else {
                this.warnings(res.message);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    goAddress() {
		localStorage.setItem('ruleForm',JSON.stringify(this.ruleForm))
      this.$router.push("addressHome");
    },
    goPackage() {
		localStorage.setItem('ruleForm',JSON.stringify(this.ruleForm))
      this.$router.push("packageHome");
    },
    getList() {
      let data = {
        userId: localStorage.getItem("userId"),
      };
      chargerPackageList(data)
        .then((res) => {
          this.taocan = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAddressList() {
      let data = {
        userId: localStorage.getItem("userId"),
        pageNo: 1,
        pageCount: 100,
      };
      addressList(data)
        .then((res) => {
          this.addressList = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    warnings(mess) {
      ElMessage.warning({
        message: mess,
        type: "warning",
        center: true,
        customClass: "warnClass",
        duration: 1500,
        showClose: true,
      });
    },
    succes(mess) {
      ElMessage.success({
        message: mess,
        type: "success",
        center: true,
        customClass: "warnClass",
        duration: 2000,
        showClose: true,
      });
    },
	scan(){
		localStorage.setItem('ruleForm',JSON.stringify(this.ruleForm))
		window.location.href = "https://agentest.cxhdkj.cn/static/code.html";
	}
  },
  mounted() {
    this.getList();
    this.getAddressList();
	
	if(localStorage.getItem('ruleForm')){
		this.ruleForm = JSON.parse(localStorage.getItem('ruleForm'))
	}
	if(localStorage.getItem('unique')){
		this.ruleForm.uniqueCode = localStorage.getItem('unique')
	}
  },
};
</script>

<style scoped>
.bg {
  background-color: #f5f5f5;
  height: calc(100vh - 5rem);
  padding-top: 1rem;
}
.container_box {
  width: 88%;
  margin: 0 auto;
  background-color: #ffff;
  padding: 1.5rem 1rem;
  border-radius: 0.8rem;
}
.btn {
  background-color: #f9d855;
  margin: 2rem auto 0;
  width: 90%;
  color: #fff;
  border-radius: 1rem;
  line-height: 4rem;
  text-align: center;
  font-size: 1.5rem;
}
.add {
  text-align: right;
  color: #65c2fc;
  font-size: 1.3rem;
  position: relative;
  top: -1.5rem;
}
</style>
