<!-- <template>
  <PageHeader state="true" msg="实名认证"></PageHeader>
  <div v-show="btn == 1" class="container_box">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="真实姓名" prop="realName">
        <el-input v-model="ruleForm.realName"></el-input>
      </el-form-item>
      <el-form-item label="身份证" prop="cardNum">
        <el-input v-model="ruleForm.cardNum"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="telNum">
        <el-input v-model="ruleForm.telNum"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="gender">
        <el-radio-group v-model="ruleForm.gender">
          <el-radio label="男"></el-radio>
          <el-radio label="女"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="家庭住址" prop="location">
        <el-input v-model="ruleForm.location"></el-input>
      </el-form-item>
      <div class="btn" @click="submitForm('ruleForm')">保存</div>
    </el-form>
  </div>
  <div v-show="btn == 2" class="container_box">
    <el-form label-width="100px" class="demo-ruleForm">
      <el-form-item label="真实姓名">
        <el-input disabled v-model="info.realName"></el-input>
      </el-form-item>
      <el-form-item label="身份证" prop="cardNum">
        <el-input disabled v-model="info.cardNum"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="gender">
        <el-radio-group v-model="info.gender">
          <el-radio :label="info.gender"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="家庭住址" prop="location">
        <el-input disabled v-model="info.location"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { agentIdentity } from "../../../http/index.js";
import { ElMessage } from "element-plus";
export default {
	data() {
		return {
			btn: 1,
			info: [],
			ruleForm: {
				realName: "",
				cardNum: "",
				telNum: "",
				gender: "",
				location: "",
			},
			rules: {
				realName: [
					{ required: true, message: "请输入您的真实姓名", trigger: "blur" },
				],
				cardNum: [
					{ required: true, message: "请输入您的身份证号码", trigger: "blur" },
				],
				telNum: [
					{ required: true, message: "请输入您的手机号码", trigger: "blur" },
					{ min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
				],
				gender: [
					{ required: true, message: "请选择您的性别", trigger: "change" },
				],
				location: [
					{ required: true, message: "请输入您的家庭住址", trigger: "blur" },
				],
			},
		};
	},
	methods: {
		submitForm(formName) {
			let that = this
			this.$refs[formName].validate((valid) => {
				if (valid) {
					let data = {
						userId: localStorage.getItem("userId"),
						realName: this.ruleForm.realName,
						cardNum: this.ruleForm.cardNum,
						gender: this.ruleForm.gender,
						location: this.ruleForm.location,
						telNum: this.ruleForm.telNum,
					};
					agentIdentity(data).then((res) => {
						if (res.code == -1) {
							this.warnings(res.message);
						} else {
							this.succes("实名认证成功");
							let userInfo = JSON.parse(localStorage.getItem('userInfo'))
							userInfo['identityStatus'] = 1
							localStorage.setItem('userInfo',JSON.stringify(userInfo))
							setTimeout(function(){
								that.$router.back();
							},1500)
						}
					})
					.catch((error) => {
						console.log(error);
					});
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		warnings(mess) {
			ElMessage.warning({
				message: mess,
				type: "warning",
				center: true,
				customClass: "warnClass",
				duration: 1500,
				showClose: true,
			});
		},
		succes(mess) {
			ElMessage.success({
				message: mess,
				type: "success",
				center: true,
				customClass: "warnClass",
				duration: 1500,
				showClose: true,
			});
		},
	},
	mounted() {
		let userInfo = JSON.parse(localStorage.getItem('userInfo'))
		if (localStorage.getItem("identity") && userInfo['identityStatus'] == 1) {
			this.btn = 2;
			this.infos = JSON.parse(localStorage.getItem("identity"));
			this.info = {
				realName: this.infos.realName,
				cardNum: this.infos.cardNum,
				gender: this.infos.gender,
				location: this.infos.location,
				telNum: this.infos.telNum,
			};
		}
	},
};
</script>

<style scoped lang="scss">
.container_box {
	width: 92%;
	margin: 1.875rem auto 0;
}
.btn {
	background-color: #f9d855;
	width: 90%;
	margin: 10rem auto 0;
	color: #fff;
	border-radius: 1rem;
	line-height: 4rem;
	text-align: center;
	font-size: 1.5rem;
}
</style> -->



<template>
	<PageHeader state="true" msg="实名认证"></PageHeader>
	<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
		<el-form-item label="认证类型">
			<el-radio-group v-model="type">
				<el-radio v-show="types == 1 || types == 2" label="个人" @click="geren()"></el-radio>
				<el-radio v-show="types == 1 || types == 3" label="企业" @click="qiye()"></el-radio>
			</el-radio-group>
		</el-form-item>
	</el-form>
	<div v-show="type=='个人'">
		<div v-show="btn == 1" class="container_box">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="真实姓名" prop="realName">
					<el-input v-model="ruleForm.realName"></el-input>
				</el-form-item>
				<el-form-item label="身份证" prop="cardNum">
					<el-input v-model="ruleForm.cardNum"></el-input>
				</el-form-item>
				<el-form-item label="性别" prop="gender">
					<el-radio-group v-model="ruleForm.gender">
						<el-radio label="男"></el-radio>
						<el-radio label="女"></el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="家庭住址" prop="location">
					<el-input v-model="ruleForm.location"></el-input>
				</el-form-item>
				<div class="btn" @click="submitForm('ruleForm')">保存</div>
			</el-form>
		</div>
		<div v-show="btn == 2" class="container_box">
			<el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="真实姓名" prop="realName">
					<el-input readonly v-model="ruleForm.realName"></el-input>
				</el-form-item>
				<el-form-item label="身份证" prop="cardNum">
					<el-input readonly v-model="ruleForm.cardNum"></el-input>
				</el-form-item>
				<el-form-item label="性别" prop="gender">
					<el-radio-group v-model="ruleForm.gender">
						<el-radio v-show="ruleForm.gender == '男'" label="男"></el-radio>
						<el-radio v-show="ruleForm.gender == '女'" label="女"></el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="家庭住址" prop="location">
					<el-input type="textarea" readonly v-model="ruleForm.location"></el-input>
				</el-form-item>
			</el-form>
		</div>
	</div>
	<div v-show="type=='企业'">
		<div v-show="btn == 1" class="container_box">
			<el-form :model="ruleForm2" :rules="rules2" ref="ruleForm2" label-width="100px" class="demo-ruleForm">
				<el-form-item label="企业名称" prop="corpName">
					<el-input v-model="ruleForm2.corpName"></el-input>
				</el-form-item>
				<el-form-item label="开户行所在省市" prop="areaCode">
					<el-cascader v-model="value" :options="options" @change="handleChange" placeholder="选择开户行省市"></el-cascader>
				</el-form-item>
				<el-form-item label="社会信用码" prop="socialCreditCode">
					<el-input v-model="ruleForm2.socialCreditCode"></el-input>
				</el-form-item>
				<el-form-item label="社会信用证有效日期" prop="socialCreditCodeExpires">
					<el-input v-model="ruleForm2.socialCreditCodeExpires" placeholder="例:20220101"></el-input>
				</el-form-item>
				<el-form-item label="法人姓名" prop="legalPerson">
					<el-input v-model="ruleForm2.legalPerson"></el-input>
				</el-form-item>
				<el-form-item label="法人身份证" prop="legalCertId">
					<el-input v-model="ruleForm2.legalCertId"></el-input>
				</el-form-item>
				<el-form-item label="身份证有效期" prop="legalCertIdExpires">
					<el-input v-model="ruleForm2.legalCertIdExpires" placeholder="例:20220101"></el-input>
				</el-form-item>
				<el-form-item label="法人手机号" prop="legalMp">
					<el-input v-model="ruleForm2.legalMp"></el-input>
				</el-form-item>
				<el-form-item label="公司地址" prop="address">
					<el-input v-model="ruleForm2.address"></el-input>
				</el-form-item>
				<el-form-item label="经营范围" prop="businessScope">
					<el-input type="textarea" v-model="ruleForm2.businessScope"></el-input>
				</el-form-item>
				<el-form-item label="附件" prop="attachFile">
					<el-input readonly type="textarea" v-model="attachFiles"></el-input>
					<el-upload class="upload-demo" action="https://test.cxhdkj.cn/chargerApi/upload/Image" name='file' :on-success='succ'>
						<el-button size="small" type="primary">选择文件</el-button>
					</el-upload>
				</el-form-item>
				
				<div class="btn" @click="submitForm2('ruleForm2')">保存</div>
			</el-form>
		</div>
		<div v-show="btn == 2" class="container_box">
			<el-form :model="ruleForm2" :rules="rules2" ref="ruleForm2" label-width="100px" class="demo-ruleForm">
				<el-form-item label="企业名称" prop="corpName">
					<el-input v-model="ruleForm2.corpName"></el-input>
				</el-form-item>
				<el-form-item label="开户行所在省市" prop="areaCode">
					<el-input v-model="value"></el-input>
					<!-- <el-cascader v-model="value" :options="options" @change="handleChange" placeholder="选择开户行省市"></el-cascader> -->
				</el-form-item>
				<el-form-item label="社会信用码" prop="socialCreditCode">
					<el-input v-model="ruleForm2.socialCreditCode"></el-input>
				</el-form-item>
				<el-form-item label="社会信用证有效日期" prop="socialCreditCodeExpires">
					<el-input v-model="ruleForm2.socialCreditCodeExpires" placeholder="例:20220101"></el-input>
				</el-form-item>
				<el-form-item label="法人姓名" prop="legalPerson">
					<el-input v-model="ruleForm2.legalPerson"></el-input>
				</el-form-item>
				<el-form-item label="法人身份证" prop="legalCertId">
					<el-input v-model="ruleForm2.legalCertId"></el-input>
				</el-form-item>
				<el-form-item label="身份证有效期" prop="legalCertIdExpires">
					<el-input v-model="ruleForm2.legalCertIdExpires" placeholder="例:20220101"></el-input>
				</el-form-item>
				<el-form-item label="法人手机号" prop="legalMp">
					<el-input v-model="ruleForm2.legalMp"></el-input>
				</el-form-item>
				<el-form-item label="公司地址" prop="address">
					<el-input v-model="ruleForm2.address"></el-input>
				</el-form-item>
				<el-form-item label="经营范围" prop="businessScope">
					<el-input type="textarea" v-model="ruleForm2.businessScope"></el-input>
				</el-form-item>
				<!-- <el-form-item label="附件" prop="attachFile">
					<el-input readonly type="textarea" v-model="attachFiles"></el-input>
					<el-upload class="upload-demo" action="https://test.cxhdkj.cn/chargerApi/upload/Image" name='file' :on-success='succ'>
						<el-button size="small" type="primary">选择文件</el-button>
					</el-upload>
				</el-form-item> -->
				
				<!-- <div class="btn" @click="submitForm2('ruleForm2')">保存</div> -->
			</el-form>
		</div>
	</div>
</template>

<script>
import aa from "../../../assets/json/Adapay.json";
import { agentIdentity,agentEnterpriseIdentity } from "../../../http/index.js";
import { ElMessage } from "element-plus";
export default {
	data() {
		return {
			types:1,
			attachFiles:'',
			value:'',
			options: aa,
			btn: 1,
			info: [],
			type:'个人',
			ruleForm: {
				realName: "",
				cardNum: "",
				gender: "",
				location: "",
			},
			rules: {
				realName: [
					{ required: true, message: "请输入您的真实姓名", trigger: "blur" },
				],
				cardNum: [
					{ required: true, message: "请输入您的身份证号码", trigger: "blur" },
				],
				gender: [
					{ required: true, message: "请选择您的性别", trigger: "change" },
				],
				location: [
					{ required: true, message: "请输入您的家庭住址", trigger: "blur" },
				],
			},
			ruleForm2: {
				corpName: "",//公司名称
				socialCreditCode: "",//统一社会信用码
				socialCreditCodeExpires: "",//统一社会信用证有效期
				businessScope: "",//经营范围
				legalPerson: "",//法人姓名
				legalCertId: "",//法人身份证号码
				legalCertIdExpires: "",//法人身份证有效期
				legalMp: "",//法人手机号
				address: "",//企业地址
				attachFile:'',//上传附件
				provCode:'',//省份编码
				areaCode:'',//地区编码
				provName:'',//省份
				areaName:'',//地区
				userId:localStorage.getItem('userId')
			},
			rules2: {
				corpName: [
					{ required: true, message: "请输入公司名称", trigger: "blur" },
				],
				socialCreditCode: [
					{ required: true, message: "请输入统一社会信用码", trigger: "blur" },
				],
				socialCreditCodeExpires: [
					{ required: true, message: "请输入统一社会信用证有效期", trigger: "blur" },
				],
				businessScope: [
					{ required: true, message: "请输入经营范围", trigger: "blur" },
				],
				legalPerson: [
					{ required: true, message: "请输入法人姓名", trigger: "blur" },
				],
				legalCertId: [
					{ required: true, message: "请输入法人身份证号码", trigger: "blur" },
				],
				legalCertIdExpires: [
					{ required: true, message: "请输入法人身份证有效期", trigger: "blur" },
				],
				legalMp: [
					{ required: true, message: "请输入法人手机号", trigger: "blur" },
				],
				address: [
					{ required: true, message: "请输入企业地址", trigger: "blur" },
				],
				attachFile: [
					{ required: true, message: "请上传含有三证合一证件照、法人身份证正面照、法人身份证反面照、开户银行许可证照的压缩包", trigger: "blur" },
				],
				areaCode: [
					{ required: true, message: "请选择公司所在省市", trigger: "blur" },
				],
			},
		};
	},
	methods: {
		succ(response){
			this.ruleForm2.attachFile = response.data.imgUrl
			let arr = response.data.imgUrl.split('/')
			this.attachFiles = arr[arr.length-1]
		},
		geren(){
			this.type = '个人'
		},
		qiye(){
			this.type = '企业'
		},
		handleChange(value) {
			this.ruleForm2.provCode = value[0].split(",")[0];
			this.ruleForm2.areaCode = value[1].split(",")[0];
			this.ruleForm2.provName = value[0].split(",")[1];
			this.ruleForm2.areaName = value[1].split(",")[1];
		},
		submitForm(formName) {
			let that = this
			this.$refs[formName].validate((valid) => {
				if (valid) {
					let data = {
						userId: localStorage.getItem("userId"),
						realName: this.ruleForm.realName,
						cardNum: this.ruleForm.cardNum,
						gender: this.ruleForm.gender,
						location: this.ruleForm.location,
						telNum: JSON.parse(localStorage.getItem('userInfo'))['mobile'],
					};
					agentIdentity(data).then((res) => {
						if (res.code == -1) {
							this.warnings(res.message);
						} else {
							this.succes("实名认证成功");
							let userInfo = JSON.parse(localStorage.getItem('userInfo'))
							userInfo['identityStatus'] = 1
							localStorage.setItem('userInfo',JSON.stringify(userInfo))
							setTimeout(function(){
								that.$router.back();
							},1500)
						}
					})
					.catch((error) => {
						console.log(error);
					});
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		submitForm2(formName2) {
			let that = this
			this.$refs[formName2].validate((valid) => {
				if (valid) {
					agentEnterpriseIdentity(this.ruleForm2).then((res)=>{
						console.log(res)
						if (res.code == -1 || res.code == 400) {
							this.warnings(res.message);
						} else {
							this.succes("信息已上传，请等待工作人员进行审核");
							// let userInfo = JSON.parse(localStorage.getItem('userInfo'))
							// userInfo['identityStatus'] = 1
							// localStorage.setItem('userInfo',JSON.stringify(userInfo))
							setTimeout(function(){
								that.$router.back();
							},1500)
						}
					})
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		warnings(mess) {
			ElMessage.warning({
				message: mess,
				type: "warning",
				center: true,
				customClass: "warnClass",
				duration: 1500,
				showClose: true,
			});
		},
		succes(mess) {
			ElMessage.success({
				message: mess,
				type: "success",
				center: true,
				customClass: "warnClass",
				duration: 1500,
				showClose: true,
			});
		},
	},
	mounted() {
		let userInfo = JSON.parse(localStorage.getItem('userInfo'))
		if (localStorage.getItem("identity") && userInfo['identityStatus'] == 1) {
			this.btn = 2;
			let infos = JSON.parse(localStorage.getItem("identity"));
			if(infos.type == 1){
				this.type = '个人'
				this.types = 2
				this.ruleForm = {
					realName: infos.realName,
					cardNum: infos.cardNum,
					gender: infos.gender,
					location: infos.location
				};
			}else{
				this.type = '企业'
				this.types = 3
				this.ruleForm2 =  {
					corpName: infos.corpName,//公司名称
					socialCreditCode: infos.socialCreditCode,//统一社会信用码
					socialCreditCodeExpires: infos.socialCreditCodeExpires,//统一社会信用证有效期
					businessScope: infos.businessScope,//经营范围
					legalPerson: infos.legalPerson,//法人姓名
					legalCertId: infos.legalCertId,//法人身份证号码
					legalCertIdExpires: infos.legalCertIdExpires,//法人身份证有效期
					legalMp: infos.legalMp,//法人手机号
					address: infos.address,//企业地址
				}
				this.value = infos.provName+'/'+infos.areaName
			}
			
		}
	},
};
</script>

<style scoped lang="scss">
.container_box {
	width: 92%;
	margin: 1.875rem auto 0;
}
.btn {
	background-color: #f9d855;
	// position: fixed;
	// bottom: 5%;
	width: 90%;
	// left: 5%;
	margin: 5rem auto 5rem;
	color: #fff;
	border-radius: 1rem;
	line-height: 4rem;
	text-align: center;
	font-size: 1.5rem;
}
</style>
