<template>
	<PageHeader state="true" msg="修理店管理"></PageHeader>
	<div v-show="load == 2">
		<div class="list_box">
			<ul infinite-scroll-disabled="disabled" class="infinite-list" v-infinite-scroll="getList" style="overflow: auto; height: 100%" infinite-scroll-immediate="false">
				<div class="item" v-for="item in list" :key="item.id" @click="goEdit(item.id)">
					<div class="name">{{ item.shopName }}</div>
					<div class="clearfix">
						<div class="fl left">
							<div>地址：{{ item.address }}</div>
							<div>电话：{{ item.tel }}</div>
						</div>
						<div class="fl edit_img">
							<img src="../../../assets/icon/bianji.png" />
						</div>
					</div>
				</div>
			</ul>
		</div>
		<div class="btn" @click="goAdd()">添加</div>
	</div>
	<div v-show="load == 1">
		<el-empty description="暂无店铺信息,快去添加吧" :image-size="200"></el-empty>
		<div class="btn" @click="goAdd()">添加</div>
	</div>
</template>

<script>
import { bikeShopList } from "../../../http/index.js";
export default {
  data() {
    return {
      state: false,
      list: [],
      load: 1,
      par: {},
    };
  },
  methods: {
    getList(data) {
      var datas = {};
      if (data) {
        datas = data;
      } else {
        datas = this.par;
      }
      bikeShopList(datas).then((res) => {
          this.par["currentPage"] = this.par["currentPage"] + 1;
          if (res.data) {
            this.list = this.list.concat(res.data);
          }
          if (this.list.length > 0) {
            this.load = 2;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goAdd() {
      this.$router.push("bikeAdd");
    },
    goEdit(eBikeId) {
      this.$router.push({ name: "BikeEdit", params: { eBikeId: eBikeId } });
    },
  },
  computed: {
    disabled() {
      return this.state;
    },
  },
  mounted() {
    let data = {
      currentPage: 1,
      agentId: localStorage.getItem("userId"),
      pagNum: 10,
      longitude: localStorage.getItem("lng"),
      latitude: localStorage.getItem("lat"),
    };
    this.par = data;
    this.getList(data);
  },
};
</script>

<style scoped lang="scss">
.list_box {
  // background-color: red;
  height: calc(100vh - 15rem);
  .item {
    width: 88%;
    margin: 0.8rem auto 0;
    padding: 0.5rem 2%;
    box-shadow: 2px 2px 7px #ccc;
    line-height: 2rem;
    border-radius: 0.5rem;
    .name {
      font-size: 1.5rem;
      font-weight: 700;
    }
    .left {
      width: 85%;
    }
    .edit_img {
      margin-left: 1rem;
      margin-top: 0.46875rem;
      width: 2.5rem;
      height: 2.5rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.btn {
  background-color: #f9d855;
  position: fixed;
  bottom: 5%;
  width: 90%;
  left: 5%;
  color: #fff;
  border-radius: 1rem;
  line-height: 4rem;
  text-align: center;
  font-size: 1.5rem;
}
</style>
