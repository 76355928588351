<template>
	<PageHeader state="true" msg="分成人员管理"></PageHeader>
	<div class="personnal_box">
		<div class="list_box">
			<div class="item" v-for="item in list" :key="item.id">
				<div class="clearfix items">
					<div class="fl item_name">{{item.realName}}</div>
					<div class="fl">{{item.telNum}}</div>
					<div class="fr item_btn" @click="del(item.id)">删除</div>
				</div>
				<div class="clearfix items">
					<div class="fl">实名认证</div>
					<div class="fr item_icon"><img src="../../../assets/icon/right.png" ></div>
					<div v-show="item.identity == 0" class="fr item_txt" @click="goFashionableAddPersonnel(item.bindingUserId)">未实名</div>
					<div v-show="item.identity == 1" class="fr item_txt1" @click="goFashionableAddPersonnel(item.bindingUserId)">已实名</div>
				</div>
				<div class="clearfix items">
					<div class="fl">银行卡号</div>
					<div class="fr item_icon"><img src="../../../assets/icon/right.png" ></div>
					<div v-show="item.bank == 0" class="fr item_txt" @click="goFashionableAddBank(item.bindingUserId)">未绑定</div>
					<div v-show="item.bank == 1" class="fr item_txt1" @click="goFashionableAddBank(item.bindingUserId)">已绑定</div>
				</div>
				<div class="clearfix items">
					<div class="fl">分成场地</div>
					<div class="fr">1个</div>
				</div>
			</div>
			
			<!-- <div class="item">
				<div class="clearfix items">
					<div class="fl item_name">吴金伟</div>
					<div class="fl">15372241585</div>
					<div class="fr item_btn">删除</div>
				</div>
				<div class="clearfix items">
					<div class="fl">银行卡号</div>
					<div class="fr item_icon"><img src="../../../assets/icon/right.png" ></div>
					<div class="fr item_txt1">绑定成功</div>
				</div>
				<div class="clearfix items">
					<div class="fl">分成场地</div>
					<div class="fr">1个</div>
				</div>
			</div> -->
		</div>
		<div class="add" @click="open">+</div>
		<!-- 添加用户 -->
		<div class="bg" v-show="addState == 2">
			<div class="bg_box">
				<div class="bg_box_title">添加人员</div>
				<div class="bg_box_item">
					<div class="bg_box_item_label">姓名：</div>
					<div class="bg_box_item_input">
						<input v-model="realName" placeholder="请输入姓名"/>
					</div>
				</div>
				<div class="bg_box_item">
					<div class="bg_box_item_label">手机号：</div>
					<div class="bg_box_item_input">
						<input maxlength="11" v-model="telNum" placeholder="请输入手机号"/>
					</div>
				</div>
				<div class="clearfix bg_box_btn">
					<div class="fl bg_box_btn_item" @click="close">取消</div>
					<div class="fl bg_box_btn_item bg_box_btn_item2" @click="save()">确定</div>
				</div>
			</div>
			
		</div>
	</div>
	
</template>

<script>
	import { addAgentSplit,agentSplitList,delAgentSplit } from "../../../http/index.js";
	import { ElMessage } from "element-plus";
	export default{
		data(){
			return{
				addState:1,
				realName:'',
				telNum:'',
				list:[]
			}
		},
		methods:{
			open(){
				this.addState = 2
			},
			close(){
				this.addState = 1
			},
			goFashionableAddPersonnel(bindingUserId){
				this.$router.push({name:'FashionableAddPersonnel',params:{bindingUserId:bindingUserId}})
			},
			goFashionableAddBank(bindingUserId){
				this.$router.push({name:'FashionableAddBank',params:{bindingUserId:bindingUserId}})
			},
			getList(){
				let data = {
					userId:localStorage.getItem('userId')
				}
				agentSplitList(data).then((res)=>{
					if(res.code == 200){
						this.list = res.data
					}
				})
			},
			del(id){
				let data = {
					splitId:id
				}
				delAgentSplit(data).then((res)=>{
					console.log(res)
				})
			},
			save(){
				if(this.realName == ''){
					this.warnings('请输入姓名')
					return false;
				}
				if(this.telNum == ''){
					this.warnings('请输入手机号码')
					return false;
				}
				let data = {
					userId : localStorage.getItem('userId'),
					realName:this.realName,
					telNum:this.telNum
				}
				addAgentSplit(data).then((res)=>{
					if(res.code == 200){
						this.succes('添加成功')
						this.addState = 1
						this.getList()
					}else{
						this.warnings(res.message)
					}
				})
			},
			warnings(mess) {
				ElMessage.warning({
					message: mess,
					type: "warning",
					center: true,
					customClass: "warnClass",
					duration: 1500,
					showClose: true,
				});
			},
			succes(mess) {
				ElMessage.success({
					message: mess,
					type: "success",
					center: true,
					customClass: "warnClass",
					duration: 2000,
					showClose: true,
				});
			},
		},
		mounted() {
			this.getList()
		}
	}
</script>

<style scoped lang="scss">
	.personnal_box{
		background-color: #F5F5F5;
		height: calc(100vh - 4rem);
		.list_box{
			padding: 1rem 0;
			.item{
				width: 86%;
				margin: 0 auto 1.5rem;
				padding: 1rem;
				border-radius: 0.875rem;
				font-size: 1.5rem;
				background-color: #fff;
				box-shadow: 0.125rem 0.125rem 0.375rem #ccc;
				line-height: 3rem;
				.items{
					border-bottom: 0.0625rem solid #ccc;
					padding: 1rem 0;
					.item_txt{
						color: red;
					}
					.item_txt1{
						color: #999;
					}
				}
				.item_name{
					font-weight: 600;
					margin-right: 1.5rem;
				}
				.item_btn{
					line-height: 2.8rem;
					border-radius: 0.625rem;
					border: 0.0625rem solid #ccc;
					padding: 0 1.5rem;
				}
				.item_icon{
					width: 2rem;
					height: 2rem;
					margin-top: 0.5rem;
					img{
						width: 2rem;
						height: 2rem;
					}
				}
			}
		}
		.add{
			background-color: #F9D855;
			position: fixed;
			right: 5%;
			bottom: 20%;
			width: 5rem;
			height: 5rem;
			border-radius: 100%;
			line-height: 5rem;
			color: #fff;
			font-size: 4rem;
			text-align: center;
		}
	}
	.bg{
		background-color: rgba($color: #000000, $alpha: 0.7);
		width: 100%;
		height: calc(100% - 4rem);
		position: fixed;
		left: 0;
		top: 4rem;
		.bg_box{
			position: absolute;
			width: 70%;
			left: calc(15% - 2rem);
			top: 10rem;
			background-color: #fff;
			border-radius: 0.875rem;
			// height: 100px;
			padding: 2rem 2rem 3rem;
			.bg_box_title{
				text-align: center;
				font-size: 1.6rem;
				font-weight: 600;
				border-bottom: 0.0625rem solid #ccc;
				line-height: 3rem;
			}
			.bg_box_item{
				font-size: 1.5rem;
				margin-top: 1rem;
				.bg_box_item_label{
					margin-bottom: 0.5rem;
				}
				.bg_box_item_input{
					border: 0.0625rem solid #ccc;
					height: 3rem;
					input{
						height: 100%;
						width: 90%;
						padding: 0 5%;
					}
				}
			}
			.bg_box_btn{
				font-size: 1.5rem;
				margin-top: 3rem;
				.bg_box_btn_item{
					width: calc(40% - 0.2rem);
					text-align: center;
					border: 0.1rem solid #ccc;
					line-height: 3.5rem;
				}
				.bg_box_btn_item2{
					background-color: #F9D855;
					margin-left: 20%;
					border: 0.1rem solid #F9D855;
					color: #fff;
				}
			}
			
		}
	}
</style>
