<template>
	<PageHeader state="true" msg="读取设备的版本号"></PageHeader>
	<div class="set8_box">版本号：{{ info }}</div>
</template>

<script>
import { getVersion } from "../../../../http/index.js";
import { ElMessage } from "element-plus";
export default {
	data() {
		return {
			info: "",
		};
	},
	methods: {
		getInfo() {
			let data = {
				deviceUnique: localStorage.getItem("uniqueCode"),
			};
			getVersion(data).then((res) => {
				if (res.code == -1) {
					this.warnings(res.message);
				} else {
					this.succes("获取设备版本号成功");
					this.info = res.data;
				}
			})
			.catch((err) => {
				console.log(err);
			});
		},
		warnings(mess) {
			ElMessage.warning({
				message: mess,
				type: "warning",
				center: true,
				customClass: "warnClass",
				duration: 1500,
				showClose: true,
			});
		},
		succes(mess) {
			ElMessage.success({
				message: mess,
				type: "success",
				center: true,
				customClass: "warnClass",
				duration: 2000,
				showClose: true,
			});
		},
	},
	mounted() {
		this.getInfo();
	},
};
</script>

<style scoped>
.set8_box {
	width: 92%;
	margin: 1rem auto 0;
	font-size: 1.4rem;
}
</style>
