<template>
	<PageHeader state="true" msg="收益分成设置"></PageHeader>
	<div class="divided_box">
		<div class="info">
			<div class="clearfix item">
				<div class="fl item_label">分成场地</div>
				<div class="fl">{{name}}</div>
			</div>
			<div class="clearfix item">
				<div class="fl item_label">分成类别</div>
				<div class="fl">在线支付收益</div>
			</div>
			<div class="clearfix item">
				<div class="fl item_label">分账方式</div>
				<div class="fl">自动结算到账</div>
			</div>
		</div>
		
		<div class="personal_box">
			<div class="clearfix">
				<div class="fl personal_title1">分成方设</div>
				<div class="fr personal_title2" @click="open">+添加分成方</div>
			</div>
			<div>
				<div v-for="item in list3" :key="item.id" class="clearfix personal_item">
					<div class="fl">{{item.agentSplit.realName}}</div>
					<div class="fl">{{item.proportion}}%</div>
					
					<div class="fr del" @click="del(item.id)">删除</div>
					<div class="fr del" @click="open1(item.id,item.agentSplit.realName)">编辑</div>
				</div>
			</div>
		</div>
	</div>
	<div class="divided_bg" v-show="addState == 2">
		<div class="divided_bg_box">
			<div class="divided_bg_box_title">添加分成人员
				<div class="close" @click="close()">X</div>
			</div>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="6rem" class="demo-ruleForm">
				<el-form-item label="人员" prop="bindingUserId">
					<el-select v-model="ruleForm.bindingUserId" placeholder="请选择分成人员">
						<el-option v-for="item in personalList" :key="item.id" :label="item.realName" :value="item.bindingUserId"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="比例" prop="proportion">
					<el-input v-model="ruleForm.proportion"  placeholder="请输入分成比例/%"></el-input>
				</el-form-item>
				
				<div class="btn"  @click="submitForm('ruleForm')">保存</div>
			</el-form>
		</div>
	</div>
	
	<div class="divided_bg" v-show="editState == 2">
		<div class="divided_bg_box">
			<div class="divided_bg_box_title">编辑分成人员
				<div class="close" @click="close1()">X</div>
			</div>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="6rem" class="demo-ruleForm">
				<el-form-item label="人员">
					<el-input v-model="names"  placeholder="请输入分成比例/%"></el-input>
					<!-- <el-select v-model="ruleForm.bindingUserId" placeholder="请选择分成人员">
						<el-option v-for="item in personalList" :key="item.id" :label="item.realName" :value="item.bindingUserId"></el-option>
					</el-select> -->
				</el-form-item>
				<el-form-item label="比例" prop="proportion">
					<el-input v-model="ruleForm.proportion"  placeholder="请输入分成比例/%"></el-input>
				</el-form-item>
				
				<div class="btn"  @click="save">保存</div>
			</el-form>
		</div>
	</div>
</template>

<script>
	import { agentSplitList,addAgentProportion,agentProportionList,delAgentProportion,editAgentProportion } from "../../../http/index.js";
	import { ElMessage } from "element-plus";
	export default{
		data() {
			return{
				names:'',
				total:100,
				addState:1,
				editState:1,
				list3:[],
				name:'',
				addressId:'',
				personalList:[],
				ruleForm: {
					proportion: "",
					bindingUserId: "",
				}, //form表单数据
				rules: {
					proportion: [
						{ required: true, message: "请设置分成人员比例", trigger: "blur" },
					],
					bindingUserId: [
						{ required: true, message: "请选择分成人员", trigger: "change" },
					],
				}, //form表单验证
			}
		},
		methods:{
			open(){
				this.addState = 2
			},
			close(){
				this.addState = 1
			},
			open1(id,names){
				this.proportionId = id
				this.names = names
				this.editState = 2
			},
			close1(){
				this.editState = 1
			},
			del(proportionId){
				let data = {
					proportionId:proportionId
				}
				delAgentProportion(data).then((res)=>{
					if(res.code == 200){
						this.succes('删除成功')
						this.getAgentProportionList()
					}
				})
			},
			// edit(){
			// 	let data = {
			// 		proportionId:1,
			// 		proportion:1
			// 	}
			// 	editAgentProportion(data).then((res)=>{
			// 		console.log(res)
			// 	})
			// },
			getList(){
				// var arr = [{
				// 	bindingUserId:localStorage.getItem('userId'),
				// 	realName:'自己本人'
				// }]
				let data = {
					userId:localStorage.getItem('userId')
				}
				agentSplitList(data).then((res)=>{
					if(res.code == 200){
						this.personalList = res.data
					}
				})
			},
			getAgentProportionList(){
				let data = {
					userId:localStorage.getItem('userId'),
					addressId:this.addressId
				}
				agentProportionList(data).then((res)=>{
					// console.log(res)
					this.list3 = res.data
					var total = 0
					for(var i = 0;i < res.data.length; i++){
						total = total + res.data[i]['proportion']
					}
					this.total = 100-total
				})
			},
			submitForm(formName) {
				// let that = this
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if(this.ruleForm.proportion < 10 || this.ruleForm.proportion > this.total){
							this.warnings('比例范围为10-'+this.total)
							return false;
						}
						let data = {
							userId: localStorage.getItem('userId'),
							bindingUserId: this.ruleForm.bindingUserId,
							proportion: this.ruleForm.proportion,
							addressId: this.addressId,
						};
						// console.log(data)
						addAgentProportion(data).then((res)=>{
							// console.log(res)
							if(res.code == 200 && res.data.length > 0){
								// console.log(111)
								this.addState = 1
								this.list2 = res.data
								
								// console.log(total)
								this.getAgentProportionList()
							}
						})
					}	
				});
			},
			save(){
				if(this.ruleForm.proportion < 10 || this.ruleForm.proportion > this.total){
					this.warnings('比例范围为10-'+this.total)
					return false;
				}
				let data = {
					proportionId: this.proportionId,
					proportion: this.ruleForm.proportion,
				};
				editAgentProportion(data).then((res)=>{
					// console.log(res)
					if(res.code == 200){
						this.editState = 1
						this.succes('编辑成功')
						this.getAgentProportionList()
					}
				})
			},
			warnings(mess) {
				ElMessage.warning({
					message: mess,
					type: "warning",
					center: true,
					customClass: "warnClass",
					duration: 1500,
					showClose: true,
				});
			},
			succes(mess) {
				ElMessage.success({
					message: mess,
					type: "success",
					center: true,
					customClass: "warnClass",
					duration: 2000,
					showClose: true,
				});
			},
		},
		mounted() {
			this.getList()
			this.name = this.$route.params.addressName
			this.addressId = this.$route.params.addressId
			this.getAgentProportionList()
		}
	}
</script>

<style scoped lang="scss">
	.divided_box{
		width: 100%;
		padding-top: 1rem;
		height: calc(100vh - 5rem);
		background-color: #f5f5f5;
		.info{
			background-color: #fff;
			width: calc(92% - 3rem);
			padding: 1.5rem;
			margin: 0 auto;
			border-radius: 0.8rem;
			.item{
				font-size: 1.5rem;
				line-height: 4rem;
				border-bottom: 0.1rem solid #ccc;
				.item_label{
					color: #999;
					margin-right: 1.5rem;
				}
			}
		}
		.personal_box{
			width: calc(92% - 3rem);
			// height: 10rem;
			padding: 1.5rem;
			margin: 1rem auto;
			border-radius: 0.8rem;
			background-color: #fff;
			font-size: 1.5rem;;
			line-height: 2.3rem;
			.personal_title1{
				color: #666;
			}
			.personal_title2{
				font-size: 1.4rem;
				color: skyblue;
			}
			.personal_item{
				color: #999;
				font-size: 1.5rem;
				margin-top: 0.5rem;
				div{
					margin-right: 1.5rem;
				}
				.del{
					border: 0.1rem solid #ccc;
					padding: 0 1rem;
				}
			}
		}
	}
	.divided_bg{
		background-color: rgba($color: #000000, $alpha: 0.7);
		position: fixed;
		top: 4rem;
		left: 0;
		width: 100%;
		height: calc(100vh - 4rem);
		.divided_bg_box{
			border-radius: 0.8rem;
			background-color: #fff;
			position: absolute;
			left: 15%;
			top: 8rem;
			padding: 0 5%;
			width: 60%;
			// height: 10rem;
			.divided_bg_box_title{
				text-align: center;
				font-size: 1.5rem;
				line-height: 4rem;
				position: relative;
				.close{
					position: absolute;
					right: 0;
					top: 0;
				}
			}
		}
		.btn{
			background-color: #F9D855;
			line-height: 4rem;
			border-radius: 0.8rem;
			text-align: center;
			color: #fff;
			margin-bottom: 2rem;
		}
	}
</style>
