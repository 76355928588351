<template>
  <PageHeader state="true" msg="IC卡、投币、最大功率设置"></PageHeader>
  <div class="set2_box">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item class="items2" label="最大输出限制功率" prop="power">
        <el-input placeholder="0~5000" v-model="ruleForm.power"></el-input>
      </el-form-item>
      <el-form-item class="items2" label="刷IC卡消费金额" prop="money">
        <el-input placeholder="0~10" v-model="ruleForm.money"></el-input>
      </el-form-item>
      <el-form-item class="items2" label="第一个币的充电时间" prop="time1">
        <el-input placeholder="0~240" v-model="ruleForm.time1"></el-input>
      </el-form-item>
      <el-form-item class="items2" label="第二个币的充电时间" prop="time2">
        <el-input placeholder="0~240" v-model="ruleForm.time2"></el-input>
      </el-form-item>
      <el-form-item class="items2" label="第三个币的充电时间" prop="time3">
        <el-input placeholder="0~240" v-model="ruleForm.time3"></el-input>
      </el-form-item>
    </el-form>
    <div class="btn" @click="submitForm('ruleForm')">保存设置</div>
  </div>
</template>

<script>
import { setMaxPower, getMaxPower } from "../../../../http/index.js";
import { ElMessage } from "element-plus";
export default {
  data() {
    return {
      ruleForm: {
        power: "",
        money: "",
        time1: "",
        time2: "",
        time3: "",
      },
      rules: {
        power: [
          {
            required: true,
            message: "请输入最大输出限制功率",
            trigger: "blur",
          },
        ],
        money: [
          { required: true, message: "请输入刷IC卡消费金额", trigger: "blur" },
        ],
        time1: [
          {
            required: true,
            message: "请输入第一个币的充电时间",
            trigger: "blur",
          },
        ],
        time2: [
          {
            required: true,
            message: "请输入第二个币的充电时间",
            trigger: "blur",
          },
        ],
        time3: [
          {
            required: true,
            message: "请输入第三个币的充电时间",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    getInfo() {
      let data = {
        deviceUnique: localStorage.getItem("uniqueCode"),
        // deviceUnique:92022674
      };
      getMaxPower(data)
        .then((res) => {
          if (res.code == -1) {
            this.warnings(res.message);
          } else {
            this.succes("获取信息成功");
            this.ruleForm["power"] = parseInt(res.data.power, 16);
            this.ruleForm["money"] = parseInt(res.data.money, 16);
            this.ruleForm["time1"] = parseInt(res.data.time1, 16);
            this.ruleForm["time2"] = parseInt(res.data.time2, 16);
            this.ruleForm["time3"] = parseInt(res.data.time3, 16);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            deviceUnique: localStorage.getItem("uniqueCode"),
            power: this.ruleForm.power,
            money: this.ruleForm.money,
            time1: this.ruleForm.time1,
            time2: this.ruleForm.time2,
            time3: this.ruleForm.time3,
          };
          setMaxPower(data)
            .then((res) => {
              if (res.code == -1) {
                this.warnings(res.message);
              } else {
                this.succes("保存成功");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    warnings(mess) {
      ElMessage.warning({
        message: mess,
        type: "warning",
        center: true,
        customClass: "warnClass",
        duration: 1500,
        showClose: true,
      });
    },
    succes(mess) {
      ElMessage.success({
        message: mess,
        type: "success",
        center: true,
        customClass: "warnClass",
        duration: 2000,
        showClose: true,
      });
    },
  },
  mounted() {
    this.getInfo();
  },
};
</script>

<style lang="scss">
.set2_box {
  width: 92%;
  margin: 1rem auto 0;
  .items2 {
    .el-form-item__label {
      width: 15rem !important;
    }
    .el-input__inner {
      width: 15rem;
    }
  }
  .btn {
    background-color: #f9d855;
    position: fixed;
    bottom: 5%;
    width: 90%;
    left: 5%;
    color: #fff;
    border-radius: 1rem;
    line-height: 4rem;
    text-align: center;
    font-size: 1.5rem;
  }
}
</style>
