<template>
  <div class="nav clearfix">
    <div class="item fl" @click="didClickedItem(0)">
      <img :src="actives[0] ? img[0][0] : img[0][1]" alt="" />
      <div :class="{ active: actives[0] }">首页</div>
    </div>
    <div class="item fl" @click="didClickedItem(1)">
      <img :src="actives[1] ? img[1][0] : img[1][1]" alt="" />
      <div :class="{ active: actives[1] }">我的</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      actives: [true, false, false, false, false],
      img: [
        [
          require("../assets/nav/shouye_hl.png"),
          require("../assets/nav/shouye.png"),
        ],
        [
          require("../assets/nav/wode_hl.png"),
          require("../assets/nav/wode.png"),
        ],
      ],
      nav: 0,
    };
  },
  mounted() {
    this.actives = this.actives.map(function () {
      return false;
    });
    this.actives[sessionStorage.getItem("nav")] = true;
  },

  methods: {
    didClickedItem: function (tag) {
      this.actives = this.actives.map(function () {
        return false;
      });
      sessionStorage.setItem("nav", tag);
      if (tag === 0) {
        this.actives[0] = true;
        this.$router.push({ name: "Home" });
      } else if (tag === 1) {
        this.actives[1] = true;
        this.$router.push({ name: "Me" });
      }
      this.$emit("select-item", tag);
    },
  },
  // watch: {
  // 	$route(newVal, oldVal) {
  // 	  console.log(newVal, oldVal);
  // 	  if (newVal.meta.index >= 0) {
  // 		this.nav = newVal.meta.index;
  // 		sessionStorage.setItem("nav", this.nav);
  // 	  }
  // 	}
  //   }
};
</script>

<style type="text/css" lang="scss" scoped>
.nav {
  background-color: #fff;
  width: 100%;
  position: fixed;
  bottom: 0;
  .item {
    width: 50%;
    text-align: center;
    padding: 0.5rem 0;
    img {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}
.active {
  color: #f9d855 !important;
}
</style>
