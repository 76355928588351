<template>
	<PageHeader state="true" msg="个人资料"></PageHeader>
	<div class="containers_box">
		<div class="item_list">
			<div class="item clearfix">
				<div class="fl">用户昵称</div>
				<div class="fr contents">{{ userInfo["username"] }}</div>
			</div>
			<div class="item clearfix">
				<div class="fl">手机</div>
				<div class="fr contents">{{ userInfo["mobile"] }}</div>
			</div>
			<div class="item clearfix">
				<div class="fl">实名状态</div>
				<div class="fr icons">
					<img src="../../../assets/icon/right.png" />
				</div>
				<div v-show="identity == 1" class="fr contents" @click="goIdentity()">未实名</div>
				<div v-show="identity == 2" class="fr contents" @click="goIdentity()">已实名</div>
			</div>
			<div class="item clearfix">
				<div class="fl">银行卡</div>
				<div class="fr icons">
					<img src="../../../assets/icon/right.png" />
				</div>
				<div v-show="bank == 1" class="fr contents" @click="goBank()">未绑定</div>
				<div v-show="bank == 2" class="fr contents" @click="goBank()">已绑定</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getUserInfo } from "../../../http/index.js";
export default {
	data() {
		return {
			userInfo: [],
			identity: 0,
			bank: 0,
		};
	},
	methods: {
		getUserInfos() {
			let data = {
				userId: localStorage.getItem("userId"),
			};
			getUserInfo(data).then((res) => {
				// console.log(res)
				this.userInfo = res.data.data["userInfo"];
				//判断是否实名
				if (res.data.data.identity) {
					this.identity = 2;
					localStorage.setItem("identity",JSON.stringify(res.data.data.identity));
				} else {
					this.identity = 1;
					localStorage.removeItem("identity");
				}
				//判断是否绑定银行卡
				if (res.data.data.bank) {
					this.bank = 2;
					localStorage.setItem("bank", JSON.stringify(res.data.data.bank));
				} else {
					this.bank = 1;
					localStorage.removeItem("bank");
				}
			})
			.catch((error) => {
				console.log(error);
			});
		},
		goIdentity() {
			this.$router.push("identity");
		},
		goBank() {
			this.$router.push("bankAdd");
		},
	},
	mounted() {
		this.getUserInfos();
	},
};
</script>

<style scoped lang="scss">
.containers_box {
	width: 92%;
	margin-left: 4%;
	font-size: 1.5rem;
	.item {
		border-bottom: 0.0625rem solid #ccc;
		padding: 1rem 0;
		.contents {
			color: #aaa;
		}
		.icons {
			width: 2rem;
			height: 2rem;
			img {
				width: 100%;
				height: 100%;
			}
		}
	}
}
</style>
