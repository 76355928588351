<template>
	<PageHeader state="true" msg="查询消费总金额数据"></PageHeader>
	<div class="set1_box">
		<div class="item">
			<div>机器消费刷卡总金额：{{ card }}元</div>
		</div>
		<div class="item">
			<div>机器消费投币总金额：{{ coins }}元</div>
		</div>
	</div>
</template>

<script>
import { getConsumption } from "../../../../http/index.js";
import { ElMessage } from "element-plus";
export default {
	data() {
		return {
			card: "",
			coins: "",
		};
	},
	methods: {
		getInfo() {
			let data = {
				deviceUnique: localStorage.getItem("uniqueCode"),
			};
			getConsumption(data).then((res) => {
				if (res.code == -1) {
					this.warnings(res.message);
				} else {
					this.succes("获取信息成功");
					(this.coins = res.data.coins), (this.card = res.data.card);
				}
			})
			.catch((err) => {
				console.log(err);
			});
		},
		warnings(mess) {
			ElMessage.warning({
				message: mess,
				type: "warning",
				center: true,
				customClass: "warnClass",
				duration: 1500,
				showClose: true,
			});
		},
		succes(mess) {
			ElMessage.success({
				message: mess,
				type: "success",
				center: true,
				customClass: "warnClass",
				duration: 2000,
				showClose: true,
			});
		},
	},
	mounted() {
		this.getInfo();
	},
};
</script>

<style scoped lang="scss">
.set1_box {
	width: 92%;
	margin: 1rem auto 0;
	.item {
		font-size: 1.3rem;
		padding: 1rem 0;
		border-bottom: 0.0625rem solid #ccc;
	}
}
</style>
