<template>
	<div class="container_box">
		<div class="title">
			<div class="name1">长兴华电科技</div>
			<div class="name2">-代理端</div>
		</div>
		<div class="input_box">
			<div class="input_item">
				<el-input v-model="account" placeholder="手机号/用户名"></el-input>
			</div>
			<div class="input_item">
				<el-input v-model="password" placeholder="6-15位数字/字母组合密码" show-password></el-input>
			</div>
			<el-checkbox v-model="remembers" label="记住密码" class="fl reg"></el-checkbox>
			<div @click="forget()" class="fl reg forget">忘记密码？</div>
	  <!-- <div @click="remember()" class="reg fl">记住密码</div> -->
			<div @click="reg()" class="fr reg">立即注册</div>
		</div>
	
		<div @click="log()" class="btn">登录</div>
	</div>
</template>

<script>
import { ref } from "vue";
import { loginByPwd } from "../../http/index.js";
import { ElMessage } from "element-plus";
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
	data() {
		return {
			account: ref(""),
			password: ref(""),
			remembers:true
		};
	},
	methods: {
		reg() {
			this.$router.push("/reg");
		},
		forget(){
			this.$router.push("forget");
		},
		log() {
			let data = {
				mobile: this.account,
				psw: this.password,
			};
			let that = this
			
			loginByPwd(data).then((res) => {
				if (res.code == -1) {
					this.warnings(res.message);
				} else if (res.code == 200) {
					if(this.remembers){
						localStorage.setItem("mobile", this.account);
						localStorage.setItem("password", this.password);
					}else{
						localStorage.removeItem('mobile')
						localStorage.removeItem('password')
					}
					localStorage.setItem("userInfo", JSON.stringify(res.data));
					localStorage.setItem("userId", res.data["id"]);
					localStorage.setItem("token", res.data["token"]);
					localStorage.setItem("userName", res.data["username"]);
					that.succes('登录中......')
					setTimeout(()=>{
						that.$router.push("home");
						// location.href = 'https://agentest.cxhdkj.cn/home'
					},2000)
				}
			})
			.catch((err) => {
				console.log(err);
			});
		},
		warnings(mess) {
			ElMessage.warning({
				message: mess,
				type: "warning",
				center: true,
				customClass: "warnClass",
				duration: 2000,
				showClose: true,
			});
		},
		succes(mess) {
			ElMessage.success({
				message: mess,
				type: "success",
				center: true,
				customClass: "warnClass",
				duration: 2000,
				showClose: true,
			});
		},
    // loadPosition() {
    //   return new Promise((resolve, reject) => {
    //     const AMap = window.AMap;
    //     AMap.plugin("AMap.Geolocation", function () {
    //       const geolocation = new AMap.Geolocation({
    //         enableHighAccuracy: true,
    //         timeout: 10000000,
    //         noIpLocate: 3,
    //       });
    //       geolocation.getCurrentPosition(function (status, result) {
    //         if (status === "complete") {
    //           localStorage.setItem("lat", result.position.lat);
    //           localStorage.setItem("lng", result.position.lng);
    //         } else {
    //           console.error("高德失败");
    //           reject(result);
    //         }
    //       });
    //     });
    //   });
    // },
		loadPosition() {
			AMapLoader.load({
				key: "1b39665d13beae59c6c66e4180b47b13",
				securityJsCode:'814eb2ae092a456dcc65f0738569dda8',
				version: "1.4.16",
				plugins: [],
				AMapUI: {
					version: "1.1",
					plugins: [],
				},
				Loca: {
					version: "1.3.2",
				},
			}).then((AMap) => {
				AMap.plugin("AMap.Geolocation", function () {
					const geolocation = new AMap.Geolocation({
						enableHighAccuracy: true,
						timeout: 10000000,
						noIpLocate: 3,
					});
					geolocation.getCurrentPosition(function (status, result) {
						if (status === "complete") {
							localStorage.setItem("lat", result.position.lat);
							localStorage.setItem("lng", result.position.lng);
						} else {
							console.error("高德失败");
						}
					});
				});
			})
			.catch((e) => {
				console.log(e);
			});
		},
	},
	mounted() {
		this.loadPosition();
		if(localStorage.getItem('mobile')){
			this.account = localStorage.getItem('mobile')
			this.password = localStorage.getItem('password')
		}
	},
	created() {
		localStorage.removeItem('huancun')
		localStorage.removeItem('identity')
		localStorage.removeItem('bank')
		localStorage.removeItem("userInfo");
		localStorage.removeItem("userId");
		localStorage.removeItem("token");
		localStorage.removeItem("userName");
		localStorage.removeItem("addressId");
		
		sessionStorage.setItem("nav", 0);
	}
};
</script>

<style scoped lang="scss">
.container_box {
	width: 92%;
	margin: 0 auto;
}
.title {
	font-weight: 700;
	padding-top: 6.25rem;
	.name1 {
		font-size: 3rem;
	}
	.name2 {
		font-size: 2rem;
		margin-left: 18rem;
	}
}
.input_box {
	margin-top: 8rem;
	.input_item {
		margin-top: 1.875rem;
	}
	.reg {
		font-size: 1.3rem;
		margin-top: 1rem;
		color: #1e90ff;
	}
	.forget{
		margin-left: 2rem;
	}
}
.btn {
	background-color: #f9d855;
	border-radius: 1rem;
	color: #fff;
	line-height: 4.5rem;
	text-align: center;
	font-size: 2rem;
	width: 92%;
	margin: 10rem auto 0;
}
</style>
