import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/logReg/login.vue"; //登录
import Reg from "../views/logReg/reg.vue"; //注册
import Me from "../views/me/home.vue"; //我的
import Home from "../views/home/home.vue"; //首页
import PackageHome from "../views/me/package/home.vue"; //套餐管理首页
import PackageAdd from "../views/me/package/add.vue"; //套餐管理添加
import PackageEdit from "../views/me/package/edit.vue"; //套餐管理编辑
import AddressHome from "../views/me/address/home.vue"; //地址管理首页
import AddressAdd from "../views/me/address/add.vue"; //地址管理添加
import AddressEdit from "../views/me/address/edit.vue"; //地址管理编辑
import FaultHome from "../views/me/fault/home.vue"; //故障列表
import FaultDetail from "../views/me/fault/detail.vue"; //故障详情
import RegHome from "../views/me/reg/home.vue"; //设备注册
import ManageHome from "../views/me/manage/home.vue"; //设备管理
import BankAdd from "../views/me/personal/bank.vue"; //银行卡添加
import PersonalHome from "../views/me/personal/home.vue"; //个人资料
import Identity from "../views/me/personal/identity.vue"; //实名认证
import Paramset from "../views/me/manage/paramSet.vue"; //参数设置
import Earnings from "../views/home/earnings/home.vue"; //场地收益统计
import EarningsDetail from "../views/home/earnings/detail.vue"; //场地收益详情
import EarningsTrend from "../views/home/earnings/trend.vue"; //收益趋势
import MemberHome from "../views/home/member/home.vue"; //会员管理
import MemberDetail from "../views/home/member/detail.vue"; //会员消费记录
import BikeHome from "../views/home/bikeShop/home.vue"; //修理店列表
import BikeAdd from "../views/home/bikeShop/add.vue"; //修理店新增
import BikeEdit from "../views/home/bikeShop/edit.vue"; //修理店编辑
import HelpHome from "../views/me/help/home.vue"; //修理店编辑

import ParamSet2 from "../views/me/manage/paramset/2.vue"; //
import ParamSet7 from "../views/me/manage/paramset/7.vue"; //
import ParamSet8 from "../views/me/manage/paramset/8.vue"; //
import ParamSet6 from "../views/me/manage/paramset/6.vue"; //
import ParamSet3 from "../views/me/manage/paramset/3.vue"; //
import ParamSet1 from "../views/me/manage/paramset/1.vue"; //
import ParamSet4 from "../views/me/manage/paramset/4.vue"; //
import ParamSet5 from "../views/me/manage/paramset/5.vue"; //

import RemoteHome from "../views/me/manage/remote/home.vue"; //
import PortHome from "../views/me/manage/port/home.vue"; //
import PackagesHome from "../views/me/manage/packages/home.vue"; //
import PackagesAdd from "../views/me/manage/packages/add.vue"; //
import PackagesEdit from "../views/me/manage/packages/edit.vue"; //
import ManageAddress from "../views/me/manage/address/home.vue"; //
import ManageDetail from "../views/me/manage/detail.vue"; //

import AdvHome from "../views/me/adv/home.vue"; //广告图列表
import AdvAdd from "../views/me/adv/add.vue"; //广告图添加
import AdvEdit from "../views/me/adv/edit.vue"; //广告图编辑

import BannerHome from "../views/me/banner/home.vue"; //轮播图列表
import BannerAdd from "../views/me/banner/add.vue"; //轮播图列表
import BannerEdit from "../views/me/banner/edit.vue"; //轮播图列表
import Forget from "../views/logReg/forget.vue"; //忘记密码

import FashionableHome from "../views/me/setfashionable/home.vue"; //设置分账首页
import FashionablePersonnel from "../views/me/setfashionable/personnel.vue"; //分成人员管理
import FashionableAddPersonnel from "../views/me/setfashionable/addPersonnel.vue"; //添加分成人员
import FashionableAddBank from "../views/me/setfashionable/addBank.vue"; //分成人员绑定银行卡
import FashionableSite from "../views/me/setfashionable/site.vue"; //分成场地列表
import FashionableDivided from "../views/me/setfashionable/divided.vue"; //分成场地人员设置
import FashionableQuery from "../views/me/setfashionable/query.vue"; //分成场地人员设置
const routes = [
	{
		path: "/fashionableQuery",
		name: "FashionableQuery",
		component: FashionableQuery,
		meta: {
			footShow: false,
		},
	},
	{
		path: "/fashionableDivided",
		name: "FashionableDivided",
		component: FashionableDivided,
		meta: {
			footShow: false,
		},
	},
	{
		path: "/fashionableSite",
		name: "FashionableSite",
		component: FashionableSite,
		meta: {
			footShow: false,
		},
	},
	{
		path: "/fashionableAddBank",
		name: "FashionableAddBank",
		component: FashionableAddBank,
		meta: {
			footShow: false,
		},
	},
	{
		path: "/fashionableAddPersonnel",
		name: "FashionableAddPersonnel",
		component: FashionableAddPersonnel,
		meta: {
			footShow: false,
		},
	},
	{
		path: "/fashionablePersonnel",
		name: "FashionablePersonnel",
		component: FashionablePersonnel,
		meta: {
			footShow: false,
		},
	},
	{
		path: "/fashionableHome",
		name: "FashionableHome",
		component: FashionableHome,
		meta: {
			footShow: false,
		},
	},
	{
	  path: "/forget",
	  name: "Forget",
	  component: Forget,
	  meta: {
	    footShow: false,
	  },
	},
	{
	  path: "/bannerAdd",
	  name: "BannerAdd",
	  component: BannerAdd,
	  meta: {
	    footShow: false,
	  },
	},
	{
	  path: "/bannerEdit",
	  name: "BannerEdit",
	  component: BannerEdit,
	  meta: {
	    footShow: false,
	  },
	},
	{
	  path: "/advAdd",
	  name: "AdvAdd",
	  component: AdvAdd,
	  meta: {
	    footShow: false,
	  },
	},
	{
	  path: "/advEdit",
	  name: "AdvEdit",
	  component: AdvEdit,
	  meta: {
	    footShow: false,
	  },
	},
	{
	  path: "/advHome",
	  name: "AdvHome",
	  component: AdvHome,
	  meta: {
	    footShow: false,
	  },
	},
	{
	  path: "/bannerHome",
	  name: "BannerHome",
	  component: BannerHome,
	  meta: {
	    footShow: false,
	  },
	},
	{
	  path: "/manageDetail",
	  name: "ManageDetail",
	  component: ManageDetail,
	  meta: {
	    footShow: false,
	  },
	},
  {
    path: "/manageAddress",
    name: "ManageAddress",
    component: ManageAddress,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/faultDetail",
    name: "FaultDetail",
    component: FaultDetail,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/packagesEdit",
    name: "PackagesEdit",
    component: PackagesEdit,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/packagesAdd",
    name: "PackagesAdd",
    component: PackagesAdd,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/packagesHome",
    name: "PackagesHome",
    component: PackagesHome,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/portHome",
    name: "PortHome",
    component: PortHome,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/remoteHome",
    name: "RemoteHome",
    component: RemoteHome,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/paramSet5",
    name: "ParamSet5",
    component: ParamSet5,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/paramSet4",
    name: "ParamSet4",
    component: ParamSet4,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/paramSet1",
    name: "ParamSet1",
    component: ParamSet1,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/paramSet3",
    name: "ParamSet3",
    component: ParamSet3,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/paramSet6",
    name: "ParamSet6",
    component: ParamSet6,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/paramSet8",
    name: "ParamSet8",
    component: ParamSet8,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/paramSet7",
    name: "ParamSet7",
    component: ParamSet7,
    meta: {
      footShow: false,
      keepAlive: true, //此组件需要缓存
      isBack: false, //用来判断是否是返回上一页
    },
  },
  {
    path: "/paramSet2",
    name: "ParamSet2",
    component: ParamSet2,
    meta: {
      footShow: false,
      keepAlive: true, //此组件需要缓存
      isBack: false, //用来判断是否是返回上一页
    },
  },
  {
    path: "/helpHome",
    name: "HelpHome",
    component: HelpHome,
    meta: {
      footShow: false,
      keepAlive: true, //此组件需要缓存
      isBack: false, //用来判断是否是返回上一页
    },
  },
  {
    path: "/bikeEdit",
    name: "BikeEdit",
    component: BikeEdit,
    meta: {
      footShow: false,
      keepAlive: true, //此组件需要缓存
      isBack: false, //用来判断是否是返回上一页
    },
  },
  {
    path: "/bikeAdd",
    name: "BikeAdd",
    component: BikeAdd,
    meta: {
      footShow: false,
      keepAlive: true, //此组件需要缓存
      isBack: false, //用来判断是否是返回上一页
    },
  },
  {
    path: "/bikeHome",
    name: "BikeHome",
    component: BikeHome,
    meta: {
      footShow: false,
      keepAlive: true, //此组件需要缓存
      isBack: false, //用来判断是否是返回上一页
    },
  },
  {
    path: "/memberDetail",
    name: "MemberDetail",
    component: MemberDetail,
    meta: {
      footShow: false,
      keepAlive: true, //此组件需要缓存
      isBack: false, //用来判断是否是返回上一页
    },
  },
  {
    path: "/memberHome",
    name: "MemberHome",
    component: MemberHome,
    meta: {
      footShow: false,
      keepAlive: true, //此组件需要缓存
      isBack: false, //用来判断是否是返回上一页
    },
  },
  {
    path: "/earningstrend",
    name: "EarningsTrend",
    component: EarningsTrend,
  },
  {
    path: "/earningsdetail",
    name: "EarningsDetail",
    component: EarningsDetail,
  },
  {
    path: "/earnings",
    name: "Earnings",
    component: Earnings,
  },
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/reg",
    name: "Reg",
    component: Reg,
  },
  {
    path: "/me",
    name: "Me",
    component: Me,
    meta: {
      footShow: true,
    },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      footShow: true,
    },
  },
  {
    path: "/packageHome",
    name: "PackageHome",
    component: PackageHome,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/packageAdd",
    name: "PackageAdd",
    component: PackageAdd,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/packageEdit",
    name: "PackageEdit",
    component: PackageEdit,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/addressHome",
    name: "AddressHome",
    component: AddressHome,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/addressAdd",
    name: "AddressAdd",
    component: AddressAdd,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/addressEdit",
    name: "AddressEdit",
    component: AddressEdit,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/faultHome",
    name: "FaultHome",
    component: FaultHome,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/regHome",
    name: "RegHome",
    component: RegHome,
    meta: {
      footShow: false,
      keepAlive: true, //此组件需要缓存
      isBack: false, //用来判断是否是返回上一页
    },
  },
  {
    path: "/manageHome",
    name: "ManageHome",
    component: ManageHome,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/personalHome",
    name: "PersonalHome",
    component: PersonalHome,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/bankAdd",
    name: "BankAdd",
    component: BankAdd,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/identity",
    name: "Identity",
    component: Identity,
    meta: {
      footShow: false,
    },
  },
  {
    path: "/paramset",
    name: "Paramset",
    component: Paramset,
    meta: {
      footShow: false,
    },
  },
];

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  
  routes,
});

export default router;
