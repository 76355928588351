<template>
	<PageHeader state="true" msg="设置分账"></PageHeader>
	<div>
		<div class="item clearfix" @click="goPersonnel">
			<div class="fl item_icon1"><img src="../../../assets/icon/fashionable1.png" ></div>
			<div class="fl item_txt1">分成人员管理</div>
			<div class="fr item_icon2"><img src="../../../assets/icon/right.png" ></div>
			<div class="fr item_txt2">共{{total}}人</div>
		</div>
		<div class="item clearfix" @click="goFashionableSite">
			<div class="fl item_icon1"><img src="../../../assets/icon/fashionable2.png" ></div>
			<div class="fl item_txt1">分成比例设置</div>
			<div class="fr item_icon2"><img src="../../../assets/icon/right.png" ></div>
		</div>
		<div class="item clearfix" @click="goFashionableQuery">
			<div class="fl item_icon1"><img src="../../../assets/icon/fashionable3.png" ></div>
			<div class="fl item_txt1">收益分成查询</div>
			<div class="fr item_icon2"><img src="../../../assets/icon/right.png" ></div>
			<div class="fr item_txt2">分账统计</div>
		</div>
		
	</div>
</template>

<script>
	import { agentSplitList } from "../../../http/index.js";
	export default{
		data(){
			return{
				total:0
			}
		},
		methods:{
			goPersonnel(){
				this.$router.push('fashionablePersonnel')
			},
			goFashionableSite(){
				this.$router.push('fashionableSite')
			},
			goFashionableQuery(){
				this.$router.push('fashionableQuery')
			},
			getList(){
				let data = {
					userId:localStorage.getItem('userId')
				}
				agentSplitList(data).then((res)=>{
					console.log(res)
					if(res.code == 200){
						this.total = res.data.length
					}
				})
			},
		},
		mounted() {
			this.getList()
		}
	}
</script>

<style scoped lang="scss">
	.item{
		font-size: 1.5rem;
		line-height: 3rem;
		width: 88%;
		margin: 0.5rem auto 1.5rem;
		padding: 1.5rem 1rem;
		box-shadow: 0.125rem 0.125rem 0.375rem #ccc;
		border-radius: 0.875rem;
		.item_icon1{
			width: 3rem;
			height: 3rem;
			margin-right: 1rem;
			img{
				width: 3rem;
				height: 3rem;
			}
		}
		.item_icon2{
			margin-top: 0.5rem;
			width: 2rem;
			height: 2rem;
			img{
				width: 2rem;
				height: 2rem;
			}
		}
		.item_txt2{
			color: #999;
		}
	}
</style>
