<template>
	<PageHeader state="true" msg="设备管理"></PageHeader>
	<div v-if="list.length > 0">
		<div class="item clearfix" v-for="item in list" :key="item.id" @click="goDetail(item.addressId)">
			<div class="fl">{{item.addressName}}</div>
			<div class="icon fr" >
				<img src="../../../assets/icon/bianji.png" >
			</div>
			<div class="fr">在线{{item.onlineCount}}/{{item.chargerNum}}台</div>
			<!-- <div class="fr" style="margin-right: 1rem;">在线{{item.onlineCount}}台</div> -->
		</div>
	</div>
	<div v-else>
		  <el-empty description="暂无设备,快去注册吧" :image-size="200"></el-empty>
		  <div class="btn" @click="goAdd()">注册</div>
	</div>
</template>

<script type="text/javascript">
	import {agentAddressChargerList} from "../../../http/index.js";
	export default{
		data(){
			return {
				list:[]
			}
		},
		methods:{
			getList(){
				let data = {
					userId:localStorage.getItem('userId')
				}
				agentAddressChargerList(data).then((res)=>{
					if(res.code == 200){
						this.list = res.data
					}
				})
			},
			goDetail(id){
				this.$router.push({ name: "ManageDetail", params: { id: id } });
			},
			goAdd(){
				this.$router.push('regHome')
			},
		},
		mounted() {
			this.getList()
		}
	}
</script>

<style scoped lang="scss">
	.item{
		font-size: 1.5rem;
		background-color: rgba(255,185,0, 0.1);
		width: 92%;
		padding: 1rem 4%;
		margin-top: 1.5rem;
		.icon{
			width: 2.25rem;
			height: 2.25rem;
			margin-left: 1rem;
			img{
				width: 2.25rem;
				height: 2.25rem;
			}
		}
	}
	.btn {
		background-color: #f9d855;
		position: fixed;
		bottom: 5%;
		width: 90%;
		left: 5%;
		color: #fff;
		border-radius: 1rem;
		line-height: 4rem;
		text-align: center;
		font-size: 1.5rem;
	}
</style>