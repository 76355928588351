<template>
	<PageHeader state="true" msg="设置最低浮充功率、浮充时间"></PageHeader>
	<div class="set10_box">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
			<el-form-item class="items2" label="最低浮充功率" prop="floatingPower">
				<el-input placeholder="0~500" v-model="ruleForm.floatingPower"></el-input>
			</el-form-item>
			<el-form-item class="items2" label="最低浮充时间" prop="floatingTime">
				<el-input placeholder="0~500" v-model="ruleForm.floatingTime"></el-input>
			</el-form-item>
		</el-form>
		<div class="btn" @click="submitForm('ruleForm')">保存设置</div>
	</div>
</template>

<script>
import { setFloating } from "../../../../http/index.js";
import { ElMessage } from "element-plus";
export default {
	data() {
		return {
			ruleForm: {
				floatingPower: "",
				floatingTime: "",
			},
			rules: {
				floatingPower: [{ required: true, message: "请输入最大输出限制功率", trigger: "blur",},],
				floatingTime: [{ required: true, message: "请输入刷IC卡消费金额", trigger: "blur" },],
			},
		};
	},
	methods: {
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					let data = {
						deviceUnique: localStorage.getItem("uniqueCode"),
						floatingPower: this.ruleForm.floatingPower,
						floatingTime: this.ruleForm.floatingTime,
					};
					setFloating(data).then((res) => {
						if (res.code == -1) {
							this.warnings(res.message);
						} else {
							this.succes("保存成功");
						}
					})
					.catch((err) => {
						console.log(err);
					});
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		warnings(mess) {
			ElMessage.warning({
				message: mess,
				type: "warning",
				center: true,
				customClass: "warnClass",
				duration: 1500,
				showClose: true,
			});
		},
		succes(mess) {
			ElMessage.success({
				message: mess,
				type: "success",
				center: true,
				customClass: "warnClass",
				duration: 2000,
				showClose: true,
			});
		},
	},
};
</script>

<style lang="scss">
.set10_box {
	width: 92%;
	margin: 1rem auto 0;
	.items2 {
		.el-form-item__label {
			width: 15rem !important;
		}
		.el-input__inner {
			width: 15rem;
		}
	}
	.btn {
		background-color: #f9d855;
		position: fixed;
		bottom: 5%;
		width: 90%;
		left: 5%;
		color: #fff;
		border-radius: 1rem;
		line-height: 4rem;
		text-align: center;
		font-size: 1.5rem;
	}
}
</style>
