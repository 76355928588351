<template>
  <PageHeader state="true" msg="会员消费记录"></PageHeader>
  <div class="container_box">
    <div class="info_box">
      <div>{{ userName }}</div>
      <div>ID:{{ userId }}</div>
      <div class="total_num">
        <div>累计消费</div>
        <div class="num">{{ allAmount }}元</div>
      </div>
    </div>

    <div class="time_boxs clearfix">
      <div class="block fl">
        <el-date-picker v-model="value1" type="date" placeholder="开始时间">
        </el-date-picker>
      </div>
      <div class="block fl">
        <el-date-picker v-model="value2" type="date" placeholder="结束时间">
        </el-date-picker>
      </div>
      <el-button class="search_btn" @click="search()" icon="el-icon-search"
        >搜索</el-button
      >
    </div>

    <div class="list_box">
      <ul
        infinite-scroll-disabled="disabled"
        class="infinite-list"
        v-infinite-scroll="getInfo"
        style="overflow: auto; height: 100%; padding-bottom: 1rem"
        infinite-scroll-immediate="false"
      >
        <div class="item" v-for="item in info" :key="item.orderNo">
          <div class="clearfix item1">
            <div class="fl">设备启动</div>
            <div class="fr">{{ item.amount }}元</div>
          </div>
          <div class="clearfix">
            <div class="fl">{{ item.payTime }}</div>
            <div class="fr color1">交易成功</div>
          </div>
          <div class="clearfix">
            <div class="fl">交易单号</div>
            <div class="fr">{{ item.orderNo }}</div>
          </div>
          <div class="clearfix">
            <div class="fl">交易场地</div>
            <div class="fr">{{ item.addressName }}</div>
          </div>
          <div class="clearfix">
            <div class="fl">设备名称</div>
            <div class="fr">{{ item.chargerIndex }}</div>
          </div>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import { agentUserOrder } from "../../../http/index.js";
export default {
	data() {
		return {
			par: {},
			userName: "",
			userId: "",
			info: [],
			state: false,
			allAmount: "",
			value1: "",
			value2: "",
		};
	},
	methods: {
		getInfo(data) {
			var datas = {};
			if (!data) {
				datas = this.par;
			} else {
				datas = data;
			}
			agentUserOrder(datas).then((res) => {
				this.par["pageNo"] = this.par["pageNo"] + 1;
				if (res.data.orderList.length == 0) {
					this.state = true;
				}
				this.allAmount = res.data.allAmount;
				this.info = this.info.concat(res.data.orderList);
			})
			.catch((error) => {
				console.log(error);
			});
		},
		search() {
			if (this.value1 == "" || this.value2 == "") {
				return false;
			}
			var data = this.par;
			data["startDate"] = this.dateToString(this.value1);
			data["endDate"] = this.dateToString(this.value2).split(" ")[0]+" 23:59:59";
			data["pageNo"] = 1;
			this.par = data;
			this.info = []
			this.getInfo(this.data);
		},
		dateToString(date) {
			const year = date.getFullYear();
			let month = date.getMonth() + 1;
			let day = date.getDate();
			let hour = date.getHours();
			let minute = date.getMinutes();
			let second = date.getSeconds();
			month = month > 9 ? month : "0" + month;
			day = day > 9 ? day : "0" + day;
			hour = hour > 9 ? hour : "0" + hour;
			minute = minute > 9 ? minute : "0" + minute;
			second = second > 9 ? second : "0" + second;
			const dateTime = year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
			return dateTime;
		},
	},
	computed: {
		disabled() {
			return this.state;
		},
	},
	mounted() {
		this.userName = this.$route.params.userName;
		this.userId = this.$route.params.userId;
		this.par = {
			userId: this.$route.params.userId,
			agentId: localStorage.getItem("userId"),
			startDate: this.$route.params.start,
			endDate: this.$route.params.end,
			pageNo: 1,
			pageCount: 5,
		};
		this.getInfo(this.par);
	},
};
</script>

<style scoped lang="scss">
.container_box {
	height: calc(100vh - 4rem);
	background-color: #f5f5f5;
}
.info_box {
	background-color: #f9d855;
	position: relative;
	height: 8rem;
	color: #fff;
	text-align: center;
	padding: 1rem 0 0;
	font-size: 1.5rem;
	.total_num {
		color: #000;
		position: absolute;
		width: 90%;
		left: 5%;
		top: 6rem;
		background-color: #fff;
		height: 6rem;
		border-radius: 1rem;
		text-align: center;
		line-height: 3rem;
		padding-bottom: 0.5rem;
		.num {
			font-size: 2rem;
			font-size: 700;
		}
	}
}
.list_box {
	margin-top: 1rem;
	height: calc(100vh - 30rem);
  // background-color: #FF0000;

	.item {
		background-color: #fff;
		width: 88%;
		margin: 0 auto;
		padding: 0.8rem 3%;
		box-shadow: 2px 2px 7px #ccc;
		border-radius: 1rem;
		color: #aaa;
		line-height: 2rem;
		margin-top: 1rem;
		.item1 {
			font-size: 1.5rem;
			font-weight: 700;
			color: #000;
		}
		.color1 {
			color: #f9d855;
		}
	}
}
.time_boxs {
	margin-top: 5rem;
	.block {
		width: 12rem;
		margin-left: 3%;
	}
	.search_btn {
		margin-left: 3%;
		background-color: #f9d855;
		color: #fff;
	}
}
</style>
