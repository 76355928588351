<template>
  <PageHeader state="true" msg="编辑充电桩套餐"></PageHeader>
  <div class="container_box">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="套餐名称" prop="name">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="价格/元" prop="price">
        <el-input v-model.number="ruleForm.price"></el-input>
      </el-form-item>
      <el-form-item label="时间/分" prop="time">
        <el-input v-model.number="ruleForm.time"></el-input>
      </el-form-item>
      <div>
        <div class="btn" @click="submitForm('ruleForm')">保存</div>
        <div class="btn btn1" @click="del()">删除</div>
      </div>
    </el-form>
  </div>
</template>

<script>
import {
  editChargerCharging,
  delChargerCharging,
  chargerChargingInfo,
} from "../../../../http/index.js";
import { ElMessage } from "element-plus";
export default {
  data() {
    return {
      id: "",
      ruleForm: {
        name: "",
        price: "",
        time: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入套餐名称", trigger: "blur" },
          {
            min: 3,
            max: 10,
            message: "长度在 3 到 10 个汉字",
            trigger: "blur",
          },
        ],
        price: [
          { required: true, message: "请输入套餐价格", trigger: "blur" },
          { type: "number", message: "套餐价格为整数", trigger: "blur" },
        ],
        time: [
          { required: true, message: "请输入套餐时间", trigger: "blur" },
          { type: "number", message: "套餐时间为整数", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
		let that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            chargerChargingId: this.id,
            // userId:localStorage.getItem('userId'),
            packageName: this.ruleForm.name,
            amount: this.ruleForm.price,
            minutes: this.ruleForm.time,
          };
          editChargerCharging(data)
            .then((res) => {
              if (res.code == 200) {
                this.succes("编辑套餐成功");
				setTimeout(function(){
					that.$router.back();
				},1500)
              } else {
                this.warnings(res.message);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    del() {
      let data = {
        chargerChargingIds: this.id,
      };
      delChargerCharging(data)
        .then((res) => {
          if (res.code == 200) {
            this.succes("删除套餐成功");
          } else {
            this.warnings(res.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getInfo(id) {
      let data = {
        chargerChargingId: id,
      };
      chargerChargingInfo(data)
        .then((res) => {
          this.ruleForm["name"] = res.data.data.packageName;
          this.ruleForm["price"] = res.data.data.amount;
          this.ruleForm["time"] = res.data.data.minutes;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    warnings(mess) {
      ElMessage.warning({
        message: mess,
        type: "warning",
        center: true,
        customClass: "warnClass",
        duration: 1500,
        showClose: true,
      });
    },
    succes(mess) {
      ElMessage.success({
        message: mess,
        type: "success",
        center: true,
        customClass: "warnClass",
        duration: 2000,
        showClose: true,
      });
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.getInfo(this.$route.params.id);
      this.id = this.$route.params.id;
    }
  },
};
</script>

<style scoped>
.container_box {
  width: 92%;
  margin: 1.875rem auto 0;
}
.btn {
  background-color: #f9d855;
  position: fixed;
  bottom: 5%;
  width: 42.5%;
  left: 5%;
  color: #fff;
  border-radius: 1rem;
  line-height: 4rem;
  text-align: center;
  font-size: 1.5rem;
}
.btn1 {
  left: 52.5% !important;
  background-color: red;
}
</style>
