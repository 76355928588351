<template>
  <PageHeader state="true" msg="充电桩套餐列表"></PageHeader>
  <div v-show="load == 2" class="container_box">
    <div class="item_list">
      <div class="item clearfix" v-for="item in list" :key="item.id">
        <div class="name">{{ item.packageName }}</div>
        <div class="fl">
          <div>时间：{{ item.minutes }}分钟</div>
          <div>价格：￥{{ item.amount }}</div>
        </div>
        <div class="fr edit" @click="goEdit(item.id)">
          <img src="../../../../assets/icon/bianji.png" />
        </div>
      </div>
    </div>
    <div class="btn" @click="goAdd()">添加</div>
  </div>
  <div v-show="load == 1">
    <el-empty description="暂无套餐,快去添加吧" :image-size="200"></el-empty>
    <div class="btn" @click="goAdd()">添加</div>
  </div>
</template>

<script>
import { chargerChargingList } from "../../../../http/index.js";
import { ElMessage } from "element-plus";
export default {
  data() {
    return {
      load: 1,
      list: [],
    };
  },
  methods: {
    getInfo(chargerId) {
      let data = {
        chargerId: chargerId,
      };
      chargerChargingList(data)
        .then((res) => {
          if (res.data.data.length > 0) {
            this.load = 2;
            this.list = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    warnings(mess) {
      ElMessage.warning({
        message: mess,
        type: "warning",
        center: true,
        customClass: "warnClass",
        duration: 1500,
        showClose: true,
      });
    },
    succes(mess) {
      ElMessage.success({
        message: mess,
        type: "success",
        center: true,
        customClass: "warnClass",
        duration: 2000,
        showClose: true,
      });
    },
    goEdit(id) {
      this.$router.push({ name: "PackagesEdit", params: { id: id } });
    },
    goAdd() {
      this.$router.push({
        name: "PackagesAdd",
        params: { id: this.chargerId },
      });
    },
  },
  mounted() {
    if (!this.$route.params.id) {
      this.chargerId = localStorage.getItem("chargerId2");
    } else {
      localStorage.setItem("chargerId2", this.$route.params.id);
      this.chargerId = this.$route.params.id;
    }
    this.getInfo(this.chargerId);
  },
};
</script>

<style scoped lang="scss">
.container_box {
  width: 92%;
  margin: 0 auto;
  .item {
    box-shadow: 2px 2px 7px #aaa;
    border-radius: 0.5rem;
    margin-top: 1rem;
    padding: 0.9375rem;
    .name {
      font-weight: 700;
      font-size: 1.5rem;
    }
    .edit {
      img {
        width: 2.6rem;
        height: 2.6rem;
      }
    }
  }
}
.btn {
  background-color: #f9d855;
  position: fixed;
  bottom: 5%;
  width: 90%;
  left: 5%;
  color: #fff;
  border-radius: 1rem;
  line-height: 4rem;
  text-align: center;
  font-size: 1.5rem;
}
</style>
