<template>
	<div class="container_box">
		<div class="title">
			<div class="name1">长兴华电科技</div>
			<div class="name2">-商家端</div>
		</div>
		<div class="input_box">
			<div class="input_item">
				<el-input v-model="mobile" placeholder="手机号" maxlength="11" show-word-limit></el-input>
			</div>
			<div class="input_item">
				<el-input v-model="password" placeholder="6-15位数字/字母组合密码" show-password></el-input>
			</div>
			<div class="input_item">
				<el-input v-model="password2" placeholder="再次输入密码" show-password></el-input>
			</div>
			<div class="input_item clearfix">
				<el-input class="fl" style="width: 16rem;" v-model="msgCode" placeholder="验证码"></el-input>
				<div v-show="codeState==1" class="fl getCode" @click="getCode()">获取验证码</div>
				<div v-show="codeState==2" class="fl getCode">{{time}}s后再次发送</div>
			</div>
			<div @click="log()" class="fr reg">已有账号？立即登录</div>
		</div>
		<div @click="reg()" class="btn">重置密码</div>
	</div>
</template>

<script>
import { ref } from "vue";
import { agentForgetPwdReq,getSMSCode } from "../../http/index.js";
import { ElMessage } from "element-plus";
export default {
	data() {
		return {
			mobile: ref(""),
			password: ref(""),
			password2: ref(""),
			msgCode: ref(""),
			userName: ref(""),
			codeState:1,
			time:60
		};
	},
	methods: {
		log() {
			this.$router.push("/");
		},
		reg() {
			if(this.password != this.password2){
				this.warnings("两次密码输入不一致，请检查后再输入");
				return false;
			}
			if(this.mobile == ''){
				this.warnings("请输入您的手机号码");
				return false;
			}
			
			let data = {
				phone: this.mobile,
				newPsw: this.password,
				code: this.msgCode,
			};
			let that = this
			agentForgetPwdReq(data).then((res) => {
				if (res.code == 200) {
					this.succes("密码重置成功")
					setTimeout(()=>{
						that.$router.push('/')
					},1500)
				} else if (res.code == -1) {
					this.warnings(res.message);
				}
			})
			.catch((err) => {
				console.log(err);
			});
		},
		warnings(mess) {
			ElMessage.warning({
				message: mess,
				type: "warning",
				center: true,
				customClass: "warnClass",
				duration: 2000,
				showClose: true,
			});
		},
		succes(mess) {
			ElMessage.success({
				message: mess,
				type: "success",
				center: true,
				customClass: "warnClass",
				duration: 2000,
				showClose: true,
			});
		},
		getCode(){
			let that = this
			if(this.mobile){
				let data ={
					phone:this.mobile,
					type:'forget'
				}
				getSMSCode(data).then((res)=>{
					if(res.code == 200){
						that.codeState = 2
						that.succes('发送成功')
						setInterval(()=>{
							if(that.time>0){
								that.time = that.time-1
							}else{
								that.codeState = 1
							}
						},1000)
					}else if(res.code == -1){
						that.warnings(res.messgae)
					}
				})
				.catch((err)=>{
					console.log(err)
				})
			}else{
				that.warnings('请输入您的手机号码')
				return false;
			}
		}
	},
};
</script>

<style scoped lang="scss">
.container_box {
  width: 92%;
  margin: 0 auto;
}
.title {
  font-weight: 700;
  padding-top: 6.25rem;
  .name1 {
    font-size: 3rem;
  }
  .name2 {
    font-size: 2rem;
    margin-left: 18rem;
  }
}
.input_box {
  margin-top: 8rem;
  .input_item {
    margin-top: 1.875rem;
  }
  .reg {
    font-size: 1.3rem;
    margin-top: 1rem;
    color: #1e90ff;
  }
}
.btn {
  background-color: #f9d855;
  border-radius: 1rem;
  color: #fff;
  line-height: 4.5rem;
  text-align: center;
  font-size: 2rem;
  margin: 10rem auto 0;
  width: 92%;
}
.getCode{
	font-size: 1.6rem;
	background-color: #F9D855;
	color: #fff;
	text-align: center;
	line-height: 4rem;
	margin-left: 1rem;
	padding: 0 1rem;
}
</style>
