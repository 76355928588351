<template>
	<PageHeader state="true" msg="收益分成设置"></PageHeader>
	<div>
		<div class="item" v-for="item in list" :key="item.id" @click="goSet(item.addressId,item.addressName)">
			<div class="clearfix">
				<div class="item_title fl">{{item.addressName}}</div>
				<div class="fl item_num">{{item.chargerNum}}台</div>
			</div>
			<div v-show="item.proportion.length == 0">
				暂未设置分成人员
			</div>
			<div v-show="item.proportion.length > 0" class="clearfix">
				<div class="fl" v-for="item2 in item.proportion" :key="item2.id">{{item2.realName}}{{item2.proportion}}%，</div>
			</div>
			
		</div>
		
	</div>
</template>

<script>
	import { addressProportionList } from "../../../http/index.js";
	export default{
		data() {
			return {
				list:[]
			}
		},
		methods:{
			getList(){
				let data = {
					userId:localStorage.getItem('userId')
				}
				addressProportionList(data).then((res=>{
					console.log(res)
					if(res.code == 200){
						this.list = res.data
					}
				}))
			},
			goSet(addressId,addressName){
				this.$router.push({name:'FashionableDivided',params:{addressId:addressId,addressName:addressName}})
			}
		},
		mounted() {
			this.getList()
		}
	}
</script>

<style scoped lang="scss">
	.item{
		width: calc(92% - 3rem);
		padding: 1.5rem;
		margin: 0.5rem auto;
		// height: 10rem;
		box-shadow: 0.125rem 0.125rem 0.4375rem #ccc;
		border-radius: 0.875rem;
		color: #999;
		.item_title{
			font-size: 1.6rem;
			font-weight: 600;
			color: #000;
		}
		.item_num{
			margin-left: 1rem;
			border: 0.2rem solid #ccc;
			color: #666;
			line-height: 2rem;
			padding: 0 1rem;
			border-radius: 0.8rem;
		}
	}
</style>
