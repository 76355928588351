<template>
	<PageHeader state="true" msg="场地设备管理列表"></PageHeader>
	<div class="container_box" v-if="list.length > 0">
		<ul class="infinite-list" v-infinite-scroll="getChargerList" style="overflow: auto; height: 100%">
			<li v-for="item in list" :key="item.id" class="infinite-list-item" @click="open(item.chargerId, item.uniqueCode)">
				<div class="clearfix">
					<div class="img_box fl">
						<img src="../../../assets/img/2.jpg" />
					</div>
					<div class="box2 fl">
						<div class="clearfix">
							<div class="name fl">设备唯一号：{{ item.uniqueCode }} </div>
							<div class="box3 fl">
								<div class="state1" v-show="item.onLine == 1">在线</div>
								<div class="state1 state2" v-show="item.onLine == 0">离线</div>
							</div>
							<div class="fl" style="margin-left: 0.5rem;">
								<div :class="{ hint_signal_active: item.signalStrength > 4 && item.signalStrength < 16}" class="hint_signal fl " style="height: 1rem;margin-top: 1.925rem;"></div>
								<div :class="{ hint_signal_active: item.signalStrength > 15 && item.signalStrength < 23}" class="hint_signal fl" style="height: 1.5rem;margin-top: 1.425rem;"></div>
								<div :class="{ hint_signal_active: item.signalStrength > 22 && item.signalStrength < 32}" class="hint_signal fl" style="height: 2rem;margin-top: 0.925rem;"></div>
							</div>
						</div>
						<div>地址：{{item.addressName}}</div>
						<div>编号：{{item.chargerIndex}}</div>
					</div>
				</div>
			</li>
		</ul>
	</div>
  
	<div v-else>
		<el-empty description="暂无设备,快去注册吧" :image-size="200"></el-empty>
		<div class="btn" @click="goAdd()">注册</div>
	</div>
  
	<div v-show="list.length > 0" class="goEarnings" @click="goEarnings()">
		查看场地收益
	</div>
  
	<el-drawer :title="title" v-model="drawer" :direction="direction" size="40%" destroy-on-close>
		<el-row :gutter="20">
			<el-col :span="6">
				<div class="item" @click="goPackagesHome()">
					<div><img src="../../../assets/icon/taocan.png" /></div>
					套餐设置
				</div>
			</el-col>
			<el-col :span="6">
				<div class="item" @click="del()">
					<div><img src="../../../assets/icon/jiechu.png" /></div>
					解除绑定
				</div>
			</el-col>
			<el-col :span="6">
				<div class="item" @click="goManageAddress()">
					<div><img src="../../../assets/icon/zhuanyi.png" /></div>
					转移设备
				</div>
			</el-col>
			<el-col :span="6">
				<div class="item" @click="goRemoteHome()">
					<div><img src="../../../assets/icon/qidong.png" /></div>
					远程启动
				</div>
			</el-col>
		</el-row>
		<el-row :gutter="20">
		  <!-- <el-col :span="6">
			<div class="item" @click="goPortHome()">
			  <div><img src="../../../assets/icon/map.png" /></div>
			  端口管理
			</div>
		  </el-col> -->
		  <!-- <el-col :span="6">
			<div class="item" @click="stop()">
			  <div><img src="../../../assets/icon/map.png" /></div>
			  设备禁用
			</div>
		  </el-col> -->
			<el-col :span="6">
				<div class="item" @click="createCode()">
					<div><img src="../../../assets/icon/erweima.png" /></div>
					生成二维码
				</div>
			</el-col>
			<el-col :span="6">
				<div class="item" @click="renewal()">
					<div><img src="../../../assets/icon/xufei.png" /></div>
					流量续费
				</div>
			</el-col>
			<el-col :span="6">
				<div class="item" @click="goParamSet()">
					<div><img src="../../../assets/icon/canshu.png" /></div>
					参数设置
				</div>
			</el-col>
	  <!-- <el-col :span="6">
	    <div class="item" @click="goUrl()">
	      <div><img src="../../../assets/icon/canshu.png" /></div>
	      参数设置
	    </div>
	  </el-col> -->
		</el-row>
	</el-drawer>
	
	<!-- 二维码 -->
	<div @click="colse" v-show="codeState" class="code">
		<qrcode-vue v-show="false" id="picture" class="codes" :value="value" size="200" level="H" />
		<img id="codeImg" src="" class="codes" style="width: 20rem;height: 20rem;">
		<div class="hint">长按图片保存到本地</div>
	</div>
</template>

<script>
import {unbindCharger,forbiddenCharger, addressChargerList,flow,flowWeCharPay} from "../../../http/index.js";
import { ElMessageBox } from 'element-plus'
import QrcodeVue from 'qrcode.vue'
export default {
	data() {
		return {
			count: 1,
			list: [],
			direction: "btt",
			drawer: false,
			chargerId: "", //点击选中的设备id
			addressId:'',
			wxcontent: "",
			value:'',
			codeState:false,
			src:""
		};
	},
	components: {
		QrcodeVue,
	},
	mounted() {
		if(this.$route.params.id){
			this.addressId = this.$route.params.id;
			localStorage.setItem('addressId',this.$route.params.id)
		}else{
			this.addressId = localStorage.getItem('addressId')
		}
		this.getChargerList()
	},
	methods: {
		colse(){
			this.codeState = false
		},
		getChargerList() {
			let datas = {
				addressId: this.addressId,
			};
			addressChargerList(datas).then((res)=>{
				// console.log(res.data)
				this.list = res.data;
			})
		},
		goUrl(){
			window.location.href = this.url
		},
		goEarnings(){
			this.$router.push('earnings')
		},
		goAdd(){
			this.$router.push('regHome')
		},
		//打开设备功能
		open(id, uniqueCode) {
			this.drawer = true;
			this.chargerId = id;
			localStorage.setItem("uniqueCode", uniqueCode);
			this.title = "设备唯一码：" + uniqueCode;
			this.value = 'https://usertest.cxhdkj.cn?unique=' + uniqueCode;
		},
		//解绑
		del() {
			this.$confirm(
				"您确定要解绑设备吗？解绑后将无法使用在线支付/启动功能",
				"解除绑定",
				{
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}
			)
			.then(() => {
				let data = {
					chargerId: this.chargerId,
					userId: localStorage.getItem("userId"),
				};
				unbindCharger(data).then(() => {
					this.$message({
						type: "success",
						message: "解绑成功!",
					});
					setTimeout(function () {
						location.reload();
					}, 1000);
				})
				.catch((error) => {
					console.log(error);
				});
			})
			.catch(() => {
				this.$message({
					type: "info",
					message: "已取消解绑",
				});
			});
		},
		//禁用
		stop() {
			this.$confirm(
				"禁用设备后，用户将无法付款启动该设备，您可以通过“启用设备”来恢复使用，确定要禁用该设备吗",
				"禁用设备",
				{
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}
			).then(() => {
				let data = {
					chargerId: this.chargerId,
				};
				forbiddenCharger(data).then(() => {
					this.$message({
						type: "success",
						message: "禁用成功!",
					});
					setTimeout(function () {
						location.reload();
					}, 2000);
					})
				.catch((error) => {
					console.log(error);
				});
			})
			.catch(() => {
				this.$message({
					type: "info",
					message: "已取消",
				});
			});
		},
		//进入到参数设置页面
		goParamSet() {
			this.$router.push("paramset");
		},
		//进入到远程启动页面
		goRemoteHome() {
			this.$router.push("remoteHome");
		},
		//进入到端口管理页面
		goPortHome() {
			this.$router.push({ name: "PortHome", params: { id: this.chargerId } });
		},
		//进入到套餐管理页面
		goPackagesHome() {
			this.$router.push({
				name: "PackagesHome",
				params: { id: this.chargerId },
			});
		},
		//进入到设备转移页面
		goManageAddress() {
			this.$router.push({
				name: "ManageAddress",
				params: { id: this.chargerId },
			});
		},
		//生成二维码
		createCode() {
			this.codeState = true
			this.drawer = false
			const myCanvas = document.getElementById('picture')
			const a = document.getElementById('codeImg')
			a.src = myCanvas.toDataURL('image/png')
		},
		//流量续费
		renewal(){
			let data = {
				chargerIds: this.chargerId,
				agentId: localStorage.getItem("userId"),
			}
			flow(data).then((res)=>{
				ElMessageBox.confirm(
					'是否确认支付流量费用?',
					'提示',
					{
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
					}
				)
				.then((res2) => {
					if(res2 == 'confirm'){
						flowWeCharPay({orderId:res.data.id}).then((result)=>{
							let url = encodeURIComponent(window.location.href)
							window.location.href = result.data.mweb_url+'&redirect_url='+url
						})
					}
				})
				.catch(() => {})
			})
		},
		jsApiCall() {
			let that = this;
			let wxcon = that.wxcontent;
			// eslint-disable-next-line no-undef
			WeixinJSBridge.invoke(
				"getBrandWCPayRequest",
				JSON.parse(wxcon),
				function (res) {
					// eslint-disable-next-line no-undef
					WeixinJSBridge.log(res.err_msg);
					// eslint-disable-next-line no-undef
					WeixinJSBridge.log(res);
					if (res.err_msg == "get_brand_wcpay_request:ok") {
						that.$router.push("orderSuccess");
					}
				}
			);
		},
		callpay(val) {
			let jsApiParameters = JSON.parse(val);
			if (jsApiParameters == "undefined") {
				alert("参数错误！");
				return false;
			}
			if (typeof WeixinJSBridge == "undefined") {
				if (document.addEventListener) {
					document.addEventListener(
						"WeixinJSBridgeReady",
						this.jsApiCall(),
						false
					);
				} else if (document.attachEvent) {
					document.attachEvent("WeixinJSBridgeReady", this.jsApiCall());
					document.attachEvent("onWeixinJSBridgeReady", this.jsApiCall());
				}
			} else {
				this.jsApiCall();
			}
		},
	},
};
</script>

<style scoped lang="scss">
.container_box {
	width: 90%;
	margin: 0 auto;
	height: 70vh;
}
.infinite-list-item {
	border: 0.0625rem solid #ccc;
	border-radius: 0.8rem;
	padding: 0.5rem 2%;
	margin-top: 1rem;
	.hint_signal{
		background-color: #ccc;
		width: 0.5rem;
		
		margin-right: 0.2rem;
	}
	.hint_signal_active{
		background-color: green;
	}
	.img_box {
		width: 8rem;
		height: 6rem;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.box2 {
		margin-left: 1rem;
		width: calc(100% - 9rem);
		// margin-right: 1rem;
		.name {
			font-weight: 700;
			font-size: 1.5rem;
		}
	}
	.state1 {
		background-color: #00cd00;
		color: #fff;
		line-height: 3rem;
		padding: 0 0.5rem;
		border-radius: 0.5rem;
		margin-left: 0.5rem;
	}
	.state2 {
		background-color: red !important;
	}
}
.item {
	text-align: center;
	margin-top: 1.5rem;
	img {
		width: 3.5rem;
		height: 3.5rem;
	}
}
.btn {
	background-color: #f9d855;
	position: fixed;
	bottom: 5%;
	width: 90%;
	left: 5%;
	color: #fff;
	border-radius: 1rem;
	line-height: 4rem;
	text-align: center;
	font-size: 1.5rem;
}
.goEarnings{
	width: 92%;
	position: fixed;
	left: 4%;
	bottom: 5rem;
	color: #FFFFFF;
	background-color: #F9D855;
	text-align: center;
	line-height: 4rem;
	font-size: 1.5rem;
	border-radius: 0.8rem;
}
.code{
	position: fixed;
	left: 0;
	top: 4rem;
	height: calc(100vh - 4rem);
	width: 100%;
	background-color: rgba($color: #000000, $alpha: 0.7);
	.codes{
		position: absolute;
		left: 50%;
		top: calc(50vh - 20rem);
		margin-left: -10rem;
	}
	.hint{
		color: #fff;
		width: 15rem;
		font-size: 1.6rem;;
		text-align: center;
		position: absolute;
		left: 50%;
		top: calc(50vh + 5rem);
		margin-left: -7.5rem;
	}
}
</style>
