<template>
  <PageHeader state="true" msg="故障详情"></PageHeader>
  <div>
    <div class="item">
      <div class="clearfix">
        <div class="fl">设备编号：{{ info.chargerId }}</div>
      </div>
      <div class="clearfix">
        <div class="fl">报修时间：{{ info.busCreateTime }}</div>
        <div class="fr">报修人编号：{{ info.addUserId }}</div>
      </div>
      <div>故障描述：</div>
      <div>
        {{ info.desc }}
      </div>
      <div class="clearfix">
        <div class="fl mr1">状态</div>
        <div class="fl">
          <el-radio-group v-model="status">
            <el-radio label="未处理"></el-radio>
            <el-radio label="已处理"></el-radio>
          </el-radio-group>
        </div>
      </div>
      <div>备注</div>
      <div>
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="textarea"
        >
        </el-input>
      </div>
    </div>

    <div v-show="status == '未处理'" class="btn" @click="save()">保存提交</div>
  </div>
</template>

<script>
import { faultDetails, handleFault } from "../../../http/index.js";
import { ref } from "vue";
import { ElMessage } from "element-plus";
export default {
  data() {
    return {
      info: [],
      status: "",
      textarea: ref(""),
      id: "",
    };
  },
  methods: {
    getInfo(id) {
      let data = {
        faultId: id,
      };
      faultDetails(data)
        .then((res) => {
          this.info = res.data.data;
          this.textarea = res.data.data.dealDesc;
          if (res.data.data.status == 0) {
            this.status = "未处理";
          } else {
            this.status = "已处理";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    warnings(mess) {
      ElMessage.warning({
        message: mess,
        type: "warning",
        center: true,
        customClass: "warnClass",
        duration: 2000,
        showClose: true,
      });
    },
    succes(mess) {
      ElMessage.success({
        message: mess,
        type: "success",
        center: true,
        customClass: "warnClass",
        duration: 2000,
        showClose: true,
      });
    },
    save() {
      if (this.status == "未处理") {
        this.warnings("您还未处理该故障");
        return false;
      }
      let data = {
        userId: localStorage.getItem("userId"),
        faultId: this.id,
        desc: this.textarea,
      };
      handleFault(data)
        .then((res) => {
          if (res.code == -1) {
            this.warnings(res.message);
          } else {
            this.succes("您已成功处理该故障");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getInfo(this.$route.params.id);
  },
};
</script>

<style scoped lang="scss">
.item {
  width: 84%;
  margin: 1rem auto 0;
  box-shadow: 2px 2px 7px #aaa;
  background-color: #fff;
  padding: 0.625rem 4%;
  border-radius: 0.5rem;
  font-size: 1.3rem;
  line-height: 2.5rem;
  .mr1 {
    margin-right: 1rem;
  }
}
.btn {
  background-color: #f9d855;
  position: fixed;
  bottom: 5%;
  width: 90%;
  left: 5%;
  color: #fff;
  border-radius: 1rem;
  line-height: 4rem;
  text-align: center;
  font-size: 1.5rem;
}
</style>
