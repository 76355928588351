<template>
  <PageHeader state="true" msg="会员管理"></PageHeader>
  <div>
    <div class="clearfix time_box">
      <div class="fl">榜单:</div>
      <div
        @click="changeTime(1)"
        class="fl item"
        :class="{ active: timeId == 1 }"
      >
        今日
      </div>
      <div
        @click="changeTime(2)"
        class="fl item"
        :class="{ active: timeId == 2 }"
      >
        昨天
      </div>
      <div
        @click="changeTime(3)"
        class="fl item"
        :class="{ active: timeId == 3 }"
      >
        本周
      </div>
      <div
        @click="changeTime(4)"
        class="fl item"
        :class="{ active: timeId == 4 }"
      >
        上周
      </div>
      <div
        @click="changeTime(5)"
        class="fl item"
        :class="{ active: timeId == 5 }"
      >
        本月
      </div>
	  <div
	    @click="changeTime(6)"
	    class="fl item"
	    :class="{ active: timeId == 6 }"
	  >
	    全部 {{userCount}}
	  </div>
    </div>
    <div class="container_box">
      <div style="height: 100%">
        <ul
          infinite-scroll-disabled="disabled"
          class="infinite-list"
          v-infinite-scroll="getInfo"
          style="overflow: auto; height: 100%"
          infinite-scroll-immediate="false"
        >
          <div
            class="item clearfix"
            v-for="(item, index) in info"
            :key="item.userId"
            @click="goDetail(item.userId, item.userName)"
          >
            <div class="fl sort">
              <div class="medal" v-if="index == 0">
                <img src="../../../assets/icon/1.png" />
              </div>
              <div class="medal" v-if="index == 1">
                <img src="../../../assets/icon/2.png" />
              </div>
              <div class="medal" v-if="index == 2">
                <img src="../../../assets/icon/3.png" />
              </div>
              <div v-if="index >= 3">
                {{ index + 1 }}
              </div>
            </div>
            <div class="fl">
              <div>{{ item.userName }}</div>
              <div class="userId">{{ item.userId }}</div>
            </div>
            <div class="fr price">
              <div class="fr img_box">
                <img src="../../../assets/icon/right.png" />
              </div>
              <div class="fr">{{ item.amount }}</div>
            </div>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { agentUserRank } from "../../../http/index.js";
export default {
	data() {
		return {
			timeId: 1,
			info: [],
			data: [],
			count: 1,
			state: false,
			userCount:0
		};
	},
	methods: {
		changeTime(val) {
			this.timeId = val;
			this.info = [];
			this.count = 1;
			this.state = false;
			switch (val) {
				case 1:
					var start = this.dateToString(new Date(new Date(new Date().getTime()).setHours(0, 0, 0, 0)));
					var end = this.dateToString(new Date(new Date(new Date().getTime()).setHours(23, 59, 59, 999)));
					var data = {
						startDate: start,
						endDate: end,
						userId: localStorage.getItem("userId"),
						pageNo: 1,
						pageCount: 10,
					};
					this.getInfo(data);
				break;
				case 2:
					var start2 = this.dateToString(new Date(new Date(new Date().getTime()).setHours(0, 0, 0, 0) - 24 * 60 * 60 * 1000));
					var end2 = this.dateToString(new Date(new Date(new Date().getTime()).setHours(0, 0, 0, 0) - 1000));
					var data2 = {
						startDate: start2,
						endDate: end2,
						userId: localStorage.getItem("userId"),
						pageNo: 1,
						pageCount: 10,
					};
					this.getInfo(data2);
				break;
				case 3:
					var now = new Date();
					var nowTime = new Date(new Date().getTime()).setHours(0, 0, 0, 0);
					var day = now.getDay();
					var start3 = this.dateToString(new Date(nowTime - (day - 1) * 24 * 60 * 60 * 1000));
					var end3 = this.dateToString(new Date(nowTime + (8 - day) * 24 * 60 * 60 * 1000 - 1));
					var data3 = {
						startDate: start3,
						endDate: end3,
						userId: localStorage.getItem("userId"),
						pageNo: 1,
						pageCount: 10,
					};
					this.getInfo(data3);
				break;
				case 4:
					var now4 = new Date();
					var nowTime2 = new Date(new Date().getTime()).setHours(0, 0, 0, 0);
					var day2 = now4.getDay();
					var start4 = this.dateToString(new Date(nowTime2 - (day2 + 6) * 24 * 60 * 60 * 1000));
					var end4 = this.dateToString(new Date(nowTime2 + (1 - day2) * 24 * 60 * 60 * 1000 - 1));
					var data4 = {
						startDate: start4,
						endDate: end4,
						userId: localStorage.getItem("userId"),
						pageNo: 1,
						pageCount: 10,
					};
					this.getInfo(data4);
				break;
				case 5:
					var now2 = new Date();
					var nowMonth = now2.getMonth();
					var nowYear = now2.getFullYear();
					var start5 = this.dateToString(new Date(nowYear, nowMonth, 1));
					var end5 = this.dateToString(new Date(new Date(new Date().getTime()).setHours(23, 59, 59, 999)));
					var data5 = {
						startDate: start5,
						endDate: end5,
						userId: localStorage.getItem("userId"),
						pageNo: 1,
						pageCount: 10,
					};
					this.getInfo(data5);
				break;
				case 6:
					var data6 = {
					startDate: "2020-10-01 00:00:00",
					endDate: "2120-10-01 00:00:00",
					userId: localStorage.getItem("userId"),
					pageNo: 1,
					pageCount: 10,
				};
				this.getInfo(data6);
				break;  
				default:
			}
		},
		getInfo(data) {
			if (data) {
				this.data = data;
			} else {
				var a = this.data;
				a["pageNo"] = this.count;
				data = a;
			}
			agentUserRank(data).then((res) => {
				this.count = this.count + 1;
				if (res.data.length == 0) {
					this.state = true;
				}
				this.info = this.info.concat(res.data.list);
				this.userCount = res.data.userCount
			})
			.catch((error) => {
				console.log(error);
			});
		},
		dateToString(date) {
			const year = date.getFullYear();
			let month = date.getMonth() + 1;
			let day = date.getDate();
			let hour = date.getHours();
			let minute = date.getMinutes();
			let second = date.getSeconds();
			month = month > 9 ? month : "0" + month;
			day = day > 9 ? day : "0" + day;
			hour = hour > 9 ? hour : "0" + hour;
			minute = minute > 9 ? minute : "0" + minute;
			second = second > 9 ? second : "0" + second;
			const dateTime =year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
			return dateTime;
		},
		goDetail(userId, userName) {
			this.$router.push({
				name: "MemberDetail",
				params: {
					userId: userId,
					start: this.data["startDate"],
					end: this.data["endDate"],
					userName: userName,
				},
			});
		},
	},
	computed: {
		disabled() {
			return this.state;
		},
	},
	mounted() {
		this.changeTime(1);
	},
};
</script>

<style scoped lang="scss">
.time_box {
	margin-left: 4%;
	line-height: 3.2rem;
	.item {
		background-color: #f5f5f5;
		margin-left: 0.8rem;
		padding: 0 0.8rem;
		font-size: 1.2rem;
		border: 0.0625rem solid #f5f5f5;
		border-radius: 0.5rem;
	}
	.active {
		background-color: #fff !important;
		color: #f9d855;
		border-color: #f9d855;
	}
}
.container_box {
	margin-top: 1rem;
	height: calc(100vh - 25rem);
	.item {
		padding: 1rem 0;
		width: 92%;
		margin: 0 auto;
		border-bottom: 0.0625rem solid #ccc;
		.medal {
			width: 3.5rem;
			height: 3.5rem;
			img {
				width: 100%;
				height: 100%;
			}
		}
		.sort {
			line-height: 3.6rem;
			margin-right: 1.5rem;
			font-size: 1.5rem;
		}
		.userId {
			color: #aaa;
		}
		.price {
			color: #f9d855;
			font-weight: 700;
			line-height: 3.6rem;
			.img_box {
				margin-left: 1rem;
				width: 1.5rem;
				height: 1.5rem;
				margin-top: 0.2rem;
				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
}
</style>
