<template>
  <!-- <PageHeader msg="场地收益统计" state="true"></PageHeader> -->
  <div class="page_header">
    <div @click="goBack()" class="icon">
      <i class="el-icon-arrow-left"></i>
    </div>
    <div class="content">场地收益统计</div>
  </div>
  <div>
    <div class="clearfix time_box">
      <div
        @click="changeTime(1)"
        class="fl item"
        :class="{ active: timeId == 1 }"
      >
        今日
      </div>
      <div
        @click="changeTime(2)"
        class="fl item"
        :class="{ active: timeId == 2 }"
      >
        昨天
      </div>
      <div
        @click="changeTime(3)"
        class="fl item"
        :class="{ active: timeId == 3 }"
      >
        本周
      </div>
      <div
        @click="changeTime(4)"
        class="fl item"
        :class="{ active: timeId == 4 }"
      >
        本月
      </div>
      <div
        @click="changeTime(5)"
        class="fl item"
        :class="{ active: timeId == 5 }"
      >
        半年
      </div>
    </div>
	
	<div class="time_boxs clearfix">
	  <div class="block fl">
	    <el-date-picker v-model="value1" type="date" placeholder="开始时间">
	    </el-date-picker>
	  </div>
	  <div class="block fl">
	    <el-date-picker v-model="value2" type="date" placeholder="结束时间">
	    </el-date-picker>
	  </div>
	  <el-button class="search_btn" @click="search()" icon="el-icon-search"
	    >搜索</el-button
	  >
	</div>

    <div class="total_box">
      <div class="box1">
        <div class="name1">合计：{{ info.allAmount }}元</div>
        <div class="name2">
          其中现金支付{{ info.totalOfflineAmount }}元在机器钱箱中
        </div>
      </div>
      <div class="box2 clearfix">
        <div class="fl item fg">
          <div>在线支付</div>
          <div>{{ info.totalOnlineAmount }}元</div>
        </div>
        <div class="fl item">
          <div>现金支付</div>
          <div>{{ info.totalOfflineAmount }}元</div>
        </div>
      </div>
    </div>

    <div class="list_box">
      <div
        class="item"
        v-for="item in info.addressChargerList"
        :key="item.addressId"
        @click="goDetail(item.addressId)"
      >
        <div class="clearfix title">
          <div class="fl">{{ item.addressName }}({{ item.chargerNum }}台)</div>
          <div class="fr price">合计:{{ item.totalAmount }}元</div>
        </div>
        <div class="content clearfix">
          <div class="fl">在线支付</div>
          <div class="fr">{{ item.onLineAmount }}元</div>
        </div>
        <div class="content clearfix">
          <div class="fl">线下支付</div>
          <div class="fr">{{ item.offLineAmount }}元</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { operationInfo } from "../../../http/index.js";
export default {
  data() {
    return {
      timeId: 1,
      info: [],
      start: "",
      end: "",
	  value1: "",
	  value2: "",
    };
  },
  methods: {
    //选择查询时间
    changeTime(val) {
      this.timeId = val;
	  localStorage.setItem('timeId',val)
	  this.info = []
      switch (val) {
        case 1:
          var start = this.dateToString(
            new Date(new Date(new Date().getTime()).setHours(0, 0, 0, 0))
          );
          var end = this.dateToString(
            new Date(new Date(new Date().getTime()).setHours(23, 59, 59, 999))
          );
          var data = {
            startDate: start,
            endDate: end,
            userId: localStorage.getItem("userId"),
          };
          this.getInfo(data);
          break;
        case 2:
          var start2 = this.dateToString(
            new Date(
              new Date(new Date().getTime()).setHours(0, 0, 0, 0) -
                24 * 60 * 60 * 1000
            )
          );
          var end2 = this.dateToString(
            new Date(new Date(new Date().getTime()).setHours(0, 0, 0, 0) - 1000)
          );
          var data2 = {
            startDate: start2,
            endDate: end2,
            userId: localStorage.getItem("userId"),
          };
          this.getInfo(data2);
          break;
        case 3:
          var now = new Date();
          var nowTime = new Date(new Date().getTime()).setHours(0, 0, 0, 0);
          var day = now.getDay();
          var start3 = this.dateToString(
            new Date(nowTime - (day - 1) * 24 * 60 * 60 * 1000)
          );
          var end3 = this.dateToString(
            new Date(nowTime + (8 - day) * 24 * 60 * 60 * 1000 - 1)
          );
          var data3 = {
            startDate: start3,
            endDate: end3,
            userId: localStorage.getItem("userId"),
          };
          this.getInfo(data3);
          break;
        case 4:
          var now2 = new Date();
          var nowMonth = now2.getMonth();
          var nowYear = now2.getFullYear();
          var start4 = this.dateToString(new Date(nowYear, nowMonth, 1));
          var end4 = this.dateToString(
            new Date(new Date(new Date().getTime()).setHours(23, 59, 59, 999))
          );
          var data4 = {
            startDate: start4,
            endDate: end4,
            userId: localStorage.getItem("userId"),
          };
          this.getInfo(data4);
          break;
        case 5:
          var now3 = new Date();
          now3.setMonth(now3.getMonth() - 6);
          var start5 = this.dateToString(
            new Date(new Date(now3.getTime()).setHours(0, 0, 0, 0))
          );
          var end5 = this.dateToString(
            new Date(new Date(new Date().getTime()).setHours(23, 59, 59, 999))
          );
          var data5 = {
            startDate: start5,
            endDate: end5,
            userId: localStorage.getItem("userId"),
          };
          this.getInfo(data5);
          break;
        default:
      }
    },
    getInfo(data) {
      this.start = data.startDate;
      this.end = data.endDate;
      operationInfo(data)
        .then((res) => {
          this.info = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goDetail(id) {
      this.$router.push({
        name: "EarningsDetail",
        params: { id: id, start: this.start, end: this.end },
      });
    },
    dateToString(date) {
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let minute = date.getMinutes();
      let second = date.getSeconds();
      month = month > 9 ? month : "0" + month;
      day = day > 9 ? day : "0" + day;
      hour = hour > 9 ? hour : "0" + hour;
      minute = minute > 9 ? minute : "0" + minute;
      second = second > 9 ? second : "0" + second;
      const dateTime =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second;
      return dateTime;
    },
	search(flag = 0) {
	  

	  this.timeId = 6
	  localStorage.setItem('timeId',this.timeId)
	  var data = []
	  if(flag == 1){
			   data = {
				  startDate: localStorage.getItem('value1'),
				  endDate: localStorage.getItem('value2'),
				  userId: localStorage.getItem("userId"),
			  }
			  this.value1 = localStorage.getItem('value1')
			  this.value2 = localStorage.getItem('value2')
	  }else{
		  if (this.value1 == "" || this.value2 == "") {
		    return false;
		  }
		   data = {
		  		  startDate: this.dateToString(this.value1),
		  		  endDate: this.dateToString(this.value2).split(" ")[0]+" 23:59:59",
		  		  userId: localStorage.getItem("userId"),
		  }
		  localStorage.setItem('value1',data['startDate'])
		  localStorage.setItem('value2',data['endDate'])
	  }
	  this.info = []
	  this.getInfo(data);
	},
	goBack() {
	  this.$router.back();
	  localStorage.removeItem('timeId')
	  localStorage.removeItem('value1')
	  localStorage.removeItem('value2')
	},
  },
  mounted() {
	if(localStorage.getItem('timeId') && localStorage.getItem('timeId') < 6){
		this.changeTime(Number(localStorage.getItem('timeId')));
	}else if(localStorage.getItem('timeId') && localStorage.getItem('timeId') == 6){
		this.search(1)
	}else{
		this.changeTime(1);
	}
  },
};
</script>

<style scoped lang="scss">
.time_box {
  .item {
    background-color: #f5f5f5;
    margin-left: 1.5rem;
    padding: 0.6rem 1.3rem;
    font-size: 1.2rem;
    border: 0.0625rem solid #f5f5f5;
    border-radius: 0.5rem;
  }
  .active {
    background-color: #fff !important;
    color: #f9d855;
    border-color: #f9d855;
  }
}
.total_box {
  width: 92%;
  margin: 0 auto;
  border-radius: 1rem;
  overflow: hidden;
  color: #fff;
  font-size: 1.3rem;
  margin-top: 2rem;
  .box1 {
    padding: 1rem 1rem 0;
    background-color: #f9d855;
    .name1 {
      font-size: 1.8rem;
      font-weight: 700;
    }
    .name2 {
      line-height: 3.5rem;
    }
  }
  .box2 {
    background-color: #f9d899;
    .item {
      width: 49%;
      text-align: center;
      padding: 1rem 0;
    }
    .fg {
      border-right: 0.0625rem solid #eee;
    }
  }
}
.list_box {
  margin-top: 2rem;
  margin-bottom: 5rem;
  .item {
    width: 92%;
    margin: 0 auto;
    box-shadow: 2px 5px 7px #ccc;
    border-radius: 0.5rem;
    border-bottom: 1.5rem;
    overflow: hidden;
	margin-top: 1rem;
    .title {
      padding: 1rem 2%;
      background-color: #f9d899;
      font-size: 1.5rem;
      .price {
        font-weight: 700;
      }
    }
    .content {
      line-height: 4rem;
      padding: 0 2%;
      font-size: 1.3rem;
    }
  }
}
.time_boxs {
  margin-top: 2rem;
  .block {
    width: 12rem;
    margin-left: 3%;
  }
  .search_btn {
    margin-left: 3%;
    background-color: #f9d855;
    color: #fff;
  }
}

.page_header {
  background-color: #fff;
  line-height: 4rem;
}
.icon {
  position: absolute;
  font-size: 2.5rem;
  left: 1%;
}
.content {
  text-align: center;
  font-size: 1.8rem;
}
</style>
