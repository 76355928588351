<template>
  <PageHeader state="true" msg="设备地址管理"></PageHeader>
  <div v-show="load == 2" class="container_box">
    <div class="item_list">
      <div class="item clearfix" v-for="item in list" :key="item.id">
        <div class="name">
          {{ item.province }}{{ item.city }}{{ item.area }}
        </div>
        <div class="fl">
          <div>详细地址：{{ item.address }}</div>
          <div>备注：{{ item.note }}</div>
        </div>
        <div class="fr edit" @click="goEdit(item.id)">
          <img src="../../../assets/icon/bianji.png" />
        </div>
      </div>
    </div>
    <div class="btn" @click="goAdd()">添加</div>
  </div>
  <div v-show="load == 1">
    <el-empty description="暂无地址,快去添加吧" :image-size="200"></el-empty>
    <div class="btn" @click="goAdd()">添加</div>
  </div>
</template>

<script>
import { addressList } from "../../../http/index.js";
export default {
	data() {
		return {
			list: [],
			load: 1,
		};
	},
	methods: {
		getList() {
			let data = {
				userId: localStorage.getItem("userId"),
				pageNo: 1,
				pageCount: 100,
			};
			addressList(data).then((res) => {
				if (res.data.length > 0) {
					this.load = 2;
					this.list = res.data;
				}
			})
			.catch((error) => {
				console.log(error);
			});
		},
		goEdit(id) {
			this.$router.push({ name: "AddressEdit", params: { id: id } });
		},
		goAdd() {
			this.$router.push("addressAdd");
		},
	},
	mounted() {
		this.getList();
	},
};
</script>

<style scoped lang="scss">
.container_box {
	width: 92%;
	margin: 0 auto;
	padding-bottom: 10rem;
	.item {
		box-shadow: 2px 2px 7px #aaa;
		border-radius: 0.5rem;
		margin-top: 1rem;
		padding: 0.9375rem;
		.name {
			font-weight: 700;
			font-size: 1.5rem;
		}
		.edit {
			img {
				width: 2.6rem;
				height: 2.6rem;
			}
		}
	}
}
.btn {
	background-color: #f9d855;
	position: fixed;
	bottom: 3%;
	width: 90%;
	left: 5%;
	color: #fff;
	border-radius: 1rem;
	line-height: 4rem;
	text-align: center;
	font-size: 1.5rem;
}
</style>
