<template>
  <PageHeader state="true" msg="IC卡、投币器是否可用"></PageHeader>
  <div class="set3_box">
    <div class="clearfix item">
      <div class="fl">投币设置</div>
      <div class="fr">
        <el-switch
          v-model="coin"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </div>
    </div>
    <div class="clearfix item">
      <div class="fl">刷卡设置</div>
      <div class="fr">
        <el-switch v-model="ic" active-color="#13ce66" inactive-color="#ff4949">
        </el-switch>
      </div>
    </div>
    <div class="btn" @click="save()">保存设置</div>
  </div>
</template>

<script>
import { setCard } from "../../../../http/index.js";
import { ElMessage } from "element-plus";
export default {
  data() {
    return {
      coin: true,
      ic: true,
    };
  },
  methods: {
    save() {
      var coin = 0;
      if (this.coin) {
        coin = 1;
      }
      var ic = 0;
      if (this.ic) {
        ic = 1;
      }
      let data = {
        ic: ic,
        coin: coin,
        deviceUnique: localStorage.getItem("uniqueCode"),
      };
      setCard(data)
        .then((res) => {
          if (res.code == -1) {
            this.warnings(res.message);
          } else {
            this.succes("保存成功");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    warnings(mess) {
      ElMessage.warning({
        message: mess,
        type: "warning",
        center: true,
        customClass: "warnClass",
        duration: 1500,
        showClose: true,
      });
    },
    succes(mess) {
      ElMessage.success({
        message: mess,
        type: "success",
        center: true,
        customClass: "warnClass",
        duration: 2000,
        showClose: true,
      });
    },
  },
};
</script>

<style lang="scss">
.set3_box {
  font-size: 1.3rem;
  width: 92%;
  margin: 1rem auto 0;
  .item {
    line-height: 4rem;
    height: 4rem;
    border-bottom: 0.0625rem solid #ccc;
    .input_box {
      width: 10rem;
      height: 2.5rem !important;
      .el-input {
        height: 2.5rem;
        line-height: 2.5rem;
        .el-input__inner {
          height: 2.5rem;
          line-height: 2.5rem;
        }
      }
    }
  }
  .btn {
    background-color: #f9d855;
    position: fixed;
    bottom: 5%;
    width: 90%;
    left: 5%;
    color: #fff;
    border-radius: 1rem;
    line-height: 4rem;
    text-align: center;
    font-size: 1.5rem;
  }
}
</style>
