<template>
  <PageHeader state="true" msg="场地收益详情"></PageHeader>
  <div class="container_box">
    <div class="top">
      <div class="clearfix">
        <div class="fl name">{{ name }}</div>
        <div class="fl num">{{ num }}台</div>
      </div>
      <div>
        <div>时间：{{ start }} -- {{ end }}</div>
        <div>收益总额：{{ info.allAmount }}</div>
        <div>线上收益：{{ info.totalOnlineAmount }}</div>
        <div>线下收益：{{ info.totalOfflineAmount }}</div>
      </div>
    </div>

    <div class="content">
      <div
        class="item"
        v-for="item in info.addressChargerList"
        :key="item.addressId"
      >
        <div class="clearfix title">
          <div class="fl">编码：{{ item.uniqueCode }}</div>
		  <div style="margin-left: 1.5rem;" class="fl">标签：{{ item.chargerIndex }}</div>
          <div class="fr">合计：{{ item.totalAmount }}元</div>
        </div>
        <div class="clearfix">
          <div class="fl money fg">
            <div>线上收益</div>
            <div>{{ item.onLineAmount }}元</div>
          </div>
          <div class="fl money">
            <div>线下收益</div>
            <div>{{ item.offLineAmount }}元</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addressOperationInfo } from "../../../http/index.js";
export default {
  data() {
    return {
      start: "", //开始时间
      end: "", //结束时间
      info: [], //获取的场地信息
      num: 0, //该场地的充电桩数量
      name: "", //场地名称
    };
  },
  mounted() {
    this.start = this.$route.params.start;
    this.end = this.$route.params.end;
    let data = {
      addressId: this.$route.params.id,
      startDate: this.$route.params.start,
      endDate: this.$route.params.end,
    };
    this.getInfo(data);
  },
  methods: {
    getInfo(data) {
      addressOperationInfo(data)
        .then((res) => {
          this.info = res.data;
          this.num = res.data.addressChargerList.length;
          this.name = res.data.addressChargerList[0]["addressName"];
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.container_box {
  height: calc(100vh - 4rem);
  background-color: #f5f5f5;
  .top {
    background-color: #fff;
    padding: 1rem 3%;
    line-height: 2.8rem;
    font-size: 1.3rem;
    .name {
      font-size: 1.5rem;
      font-weight: 700;
    }
    .num {
      background-color: #f9d855;
      color: #fff;
      padding: 0 1rem;
      margin-left: 0.5rem;
      border-radius: 0.5rem;
    }
  }
  .content {
    margin-top: 2rem;
    font-size: 1.3rem;
    text-align: center;
    .item {
      padding: 0.5rem 4%;
      background-color: #fff;
      margin-top: 1rem;
      .title {
        border-bottom: 0.0625rem solid #ccc;
        padding-bottom: 0.5rem;
      }
      .money {
        width: 49%;
        padding-top: 0.5rem;
      }
      .fg {
        border-right: 0.0625rem solid #ccc;
      }
    }
  }
}
</style>
