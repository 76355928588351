<template>
  <PageHeader state="true" msg="设备故障管理"></PageHeader>
  <div class="nav_box">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
      active-text-color="#f9d855"
    >
      <el-menu-item index="0">未处理</el-menu-item>
      <el-menu-item index="1">已处理</el-menu-item>
    </el-menu>
  </div>
  <div class="container_box">
    <div class="item_list">
      <ul
        infinite-scroll-immediate="false"
        class="infinite-list"
        v-infinite-scroll="getList"
        style="overflow: auto; height: 100%"
      >
        <div
          class="item"
          v-for="item in list"
          :key="item.id"
          @click="goDetail(item.id)"
        >
          <div class="clearfix">
            <div class="fl">设备编号：{{ item.chargerId }}</div>
          </div>
          <div class="clearfix">
            <div class="fl">报修时间：{{ item.busCreateTime }}</div>
            <div class="fr">报修人编号：{{ item.addUserId }}</div>
          </div>
          <div>故障描述：</div>
          <div>
            {{ item.desc }}
          </div>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import { chargerFaultList } from "../../../http/index.js";
export default {
  data() {
    return {
      activeIndex: "0",
      list: [],
      count: 1,
    };
  },
  methods: {
    handleSelect(key) {
      this.activeIndex = key;
      this.list = [];
      this.count = 1;
      this.getList();
    },
    getList() {
      let data = {
        userId: localStorage.getItem("userId"),
        status: this.activeIndex,
        pageNo: this.count,
        pageCount: 10,
      };
      chargerFaultList(data)
        .then((res) => {
          this.list = this.list.concat(res.data.data);
          this.count = this.count + 1;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goDetail(id) {
      this.$router.push({ name: "FaultDetail", params: { id: id } });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped lang="scss">
.el-menu-item {
  width: 50%;
  padding: 0 !important;
  text-align: center;
}
.container_box {
  background-color: #f5f5f5;
  height: calc(100vh - 10.1rem);
  width: 100%;
  .item_list {
    padding-top: 1rem;
    height: 90%;
    // background-color: red;
  }
  .item {
    width: 84%;
    margin: 1rem auto 0;
    box-shadow: 2px 2px 7px #aaa;
    background-color: #fff;
    padding: 0.625rem 4%;
    border-radius: 0.5rem;
    font-size: 1.3rem;
    > div {
      margin-top: 0.6rem;
    }
  }
}
</style>
