<template>
  <PageHeader state="true" msg="银行卡管理"></PageHeader>
  <div v-show="btn == 1" class="container_box">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="账户类型" prop="bankAcctType">
        <el-radio-group v-model="ruleForm.bankAcctType">
          <el-radio label="对私" @click="si()"></el-radio>
          <el-radio label="对公" @click="gong()"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-show="type == 1" label="开户行所在省市">
        <el-cascader
          v-model="value"
          :options="options"
          @change="handleChange" placeholder="选择开户行省市"
        ></el-cascader>
      </el-form-item>
      <el-form-item v-show="type == 1" label="开户行">
        <el-select
          v-model="value2"
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键词"
          :remote-method="getBankCodes"
          :loading="loading"
        >
          <el-option
            v-for="item in options2"
            :key="item.bankCode"
            :label="item.bankName"
            :value="item.bankCode + ',' + item.bankName"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-show="type == 2" label="开户行">
        <el-input v-model="ruleForm.bankName"></el-input>
      </el-form-item>
      <el-form-item label="银行卡号" prop="bankCard">
        <el-input v-model="ruleForm.bankCard"></el-input>
      </el-form-item>
      <el-form-item label="开户名" prop="userName">
        <el-input v-model="ruleForm.userName"></el-input>
      </el-form-item>

      <el-form-item label="手机号" prop="telNum">
        <el-input v-model="ruleForm.telNum"></el-input>
      </el-form-item>
      <div class="btn" @click="submitForm('ruleForm')">保存</div>
    </el-form>
  </div>
  
  <div v-show="btn == 2" class="container_box">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="账户类型" prop="bankAcctType">
        <el-radio-group v-model="info.bankAcctType">
          <el-radio label="对私" @click="si()"></el-radio>
          <el-radio label="对公" @click="gong()"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-show="type == 1" label="开户行所在省市">
        <el-cascader
          v-model="value"
          :options="options"
          @change="handleChange"
        ></el-cascader>
      </el-form-item>
      <el-form-item v-show="type == 1" label="开户行">
        <el-select
          v-model="value2"
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键词"
          :remote-method="getBankCodes"
          :loading="loading"
        >
          <el-option
            v-for="item in options2"
            :key="item.bankCode"
            :label="item.bankName"
            :value="item.bankCode + ',' + item.bankName"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-show="type == 2" label="开户行">
        <el-input v-model="info.bankName"></el-input>
      </el-form-item>
      <el-form-item label="银行卡号" prop="bankCard">
        <el-input v-model="info.bankCard"></el-input>
      </el-form-item>
      <el-form-item label="开户名" prop="userName">
        <el-input v-model="info.userName"></el-input>
      </el-form-item>
  
      <el-form-item label="手机号" prop="telNum">
        <el-input v-model="info.telNum"></el-input>
      </el-form-item>
      <!-- <div class="btn" @click="submitForm('ruleForm')">保存</div> -->
    </el-form>
  </div>
</template>

<script>
import aa from "../../../assets/json/Adapay.json";
import { agentBank, getBankCode } from "../../../http/index.js";
import { ElMessage } from "element-plus";
export default {
	data() {
		return {
			btn: 1,
			type: 2, //1对公  2对私
			info: [],
			loading: false,
			options2: [
				{
					'bankCode': "000",
					'bankName': "请输入关键词"
				}
			],
			value2: "",
			options: aa, //省市信息
			value: "", //选择的省市
			provCode: "", //省编码
			provName: "", //省名
			areaCode: "", //市编码
			areaName: "", //市名
			ruleForm: {
				bankCard: "",
				userName: "",
				bankName: "",
				telNum: "",
				bankAcctType: "对私",
			}, //form表单数据
			rules: {
				bankCard: [
					{ required: true, message: "请输入银行卡号", trigger: "blur" },
				],
				userName: [
					{ required: true, message: "请输入开户名", trigger: "blur" },
				],
				telNum: [
					{ required: true, message: "请输入您的手机号码", trigger: "blur" },
					{ min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
				],
				bankAcctType: [
					{ required: true, message: "请选择账户类型", trigger: "change" },
				],
			}, //form表单验证
		};
	},
	methods: {
		submitForm(formName) {
			let that = this
			this.$refs[formName].validate((valid) => {
				if (valid) {
					let data = {
						userId: localStorage.getItem("userId"),
						bankCard: this.ruleForm.bankCard,
						userName: this.ruleForm.userName,
						telNum: this.ruleForm.telNum,
					};
					//判断对公和对私
					if (this.ruleForm.bankAcctType == "对公") {
						data["bankAcctType"] = 1;
						if (this.areaCode == "") {
							this.warnings("请选择开户行所在省市");
							return false;
						} else {
							data["provCode"] = this.provCode;
							data["areaCode"] = this.areaCode;
							data["provName"] = this.provName;
							data["areaName"] = this.areaName;
						}
						if (this.value2 !== "" && this.value2.split(",").length === 2) {
							data["bank"] = this.value2.split(",")[1];
							data["bankCode"] = this.value2.split(",")[0];
						} else {
							this.warnings("请选择正确的开户行");
							return false;
						}
					} else {
						data["bankAcctType"] = 2;
						data["bankName"] = this.ruleForm.bankName;
					}
					agentBank(data).then((res) => {
						if (res.code == -1) {
							this.warnings(res.message);
						} else {
							this.succes("银行卡绑定成功");
							let userInfo = JSON.parse(localStorage.getItem('userInfo'))
							userInfo['agentBankStatus'] = 1
							localStorage.setItem('userInfo',JSON.stringify(userInfo))
							setTimeout(function(){
								that.$router.back();
							},1500)
						}
					})
					.catch((error) => {
						console.log(error);
					});
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		handleChange(value) {
			this.provCode = value[0].split(",")[0];
			this.areaCode = value[1].split(",")[0];
			this.provName = value[0].split(",")[1];
			this.areaName = value[1].split(",")[1];
		},
		gong() {
			this.type = 1;
		},
		si() {
			this.type = 2;
		},
		warnings(mess) {
			ElMessage.warning({
				message: mess,
				type: "warning",
				center: true,
				customClass: "warnClass",
				duration: 1500,
				showClose: true,
			});
		},
		succes(mess) {
			ElMessage.success({
				message: mess,
				type: "success",
				center: true,
				customClass: "warnClass",
				duration: 2000,
				showClose: true,
			});
		},
		del() {
			console.log("删除");
		},
		getBankCodes(query) {
			if (query !== "") {
				this.loading = true;
				setTimeout(() => {
					this.loading = false;
					let data = {
						bankName: query,
					};
					getBankCode(data).then((res) => {
						this.options2 = res.data;
					})
					.catch((error) => {
						console.log(error);
					});
				}, 200);
			} else {
				this.options2 = [];
			}
		},
	},
	mounted() {
		let userInfo = JSON.parse(localStorage.getItem('userInfo'))
		
		if (localStorage.getItem("bank") && userInfo['agentBankStatus'] == 1) {
			this.btn = 2;
			this.infos = JSON.parse(localStorage.getItem("bank"));
			this.info = {
				bankCard: this.infos.bankCard,
				userName: this.infos.userName,
				bankName: this.infos.bankName,
				telNum: this.infos.telNum,
			};
			if (this.infos.bankAcctType == 1) {
				this.info["bankAcctType"] = "对公";
				this.type = 1
			} else {
				this.info["bankAcctType"] = "对私";
				this.type = 2
			}
		}
	},
};
</script>

<style scoped lang="scss">
.container_box {
  width: 92%;
  margin: 1.875rem auto 0;
}
.btn {
  background-color: #f9d855;
  // position: fixed;
  // bottom: 5%;
  width: 90%;
  // left: 5%;
  margin: 10rem auto 0;
  color: #fff;
  border-radius: 1rem;
  line-height: 4rem;
  text-align: center;
  font-size: 1.5rem;
}
</style>
