<template>
	<PageHeader msg="收益分成查询" state="true"></PageHeader>
	<div class="clearfix nav_box">
		<!-- <div class="fl nav_item" :class="{selected: navState == 1}" @click="changeNav(1)">结算查询</div> -->
		<div class="fl nav_item" :class="{selected: navState == 2}" @click="changeNav(2)">按人员查询</div>
		<div class="fl nav_item" :class="{selected: navState == 3}" @click="changeNav(3)">按场地查询</div>
	</div>
	<div class="nav1">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="6rem" class="demo-ruleForm">
			<div class="nav1_item" v-show="navState == 2">
				<label>人员</label>
				<el-select size='small' v-model="ruleForm.bindingUserId" placeholder="请选择分成人员">
					<el-option v-for="item in personalList" :key="item.id" :label="item.realName" :value="item.bindingUserId"></el-option>
				</el-select>
			</div>
			<div class="nav1_item" v-show="navState == 3">
				<label>场地</label>
				<el-select size='small' v-model="ruleForm.addressIds" placeholder="请选择场地">
					<el-option v-for="item in addressProportionList" :key="item.id" :label="item.addressName" :value="item.addressId"></el-option>
				</el-select>
			</div>
			<div class="nav1_item">
				<label>时间</label>
				<el-date-picker size="small" v-model="beginDate" type="date" placeholder="开始时间"></el-date-picker> 至 
				<el-date-picker size="small" v-model="endDate" type="date" placeholder="结束时间"></el-date-picker>
			</div>
			<div class="btn" @click="query()">确定</div>
		</el-form>
	</div>
	
	<!-- 按时间 -->
	<div v-show="navState == 2" class="navs2">
		<div class="navs2_th clearfix">
			<div class="fl">时间</div>
			<div class="fl">金额</div>
		</div>
		<div class="navs2_td clearfix" v-for="item in agentSplitLogList2" :key="item.id">
			<div class="fl">{{item.busCreateTime}}</div>
			<div class="fl">{{item.amount}}</div>
		</div>
	</div>
	<!-- 按场地 -->
	<div v-show="navState == 3" class="navs2">
		<div class="navs2_th clearfix">
			<div class="fl">场地</div>
			<div class="fl">金额</div>
		</div>
		<div class="navs2_td clearfix" v-for="item in agentSplitLogList3" :key="item.id">
			<div class="fl">{{item.addressName}}</div>
			<div class="fl">{{item.amount}}</div>
		</div>
	</div>
</template>

<script>
	import { agentSplitList,addressProportionList,agentSplitLogList } from "../../../http/index.js";
	import { ElMessage } from "element-plus";
	export default{
		data() {
			return{
				agentSplitLogList2:[],
				agentSplitLogList3:[],
				navState:2,
				personalList:[],
				addressProportionList:[],
				beginDate:'',//开始时间
				endDate:'',//结束时间
				ruleForm: {
					bindingUserId: "",//分成人员id
					addressIds:'',//场地id
				}, //form表单数据
				rules: {
					proportion: [
						{ required: true, message: "请设置分成人员比例", trigger: "blur" },
					],
					bindingUserId: [
						{ required: true, message: "请选择分成人员", trigger: "change" },
					],
				}, //form表单验证
			}
		},
		methods:{
			changeNav(num){
				this.navState = num
				// this.query()
			},
			getList(){
				let data = {
					userId:localStorage.getItem('userId')
				}
				agentSplitList(data).then((res)=>{
					if(res.code == 200){
						// console.log(res.data)
						this.personalList = res.data
					}
				})
			},
			getAddressProportionList(){
				let data = {
					userId:localStorage.getItem('userId')
				}
				addressProportionList(data).then((res=>{
					// console.log(res)
					if(res.code == 200){
						this.addressProportionList = res.data
					}
				}))
			},
			dateFormat(dateData) {
				var date = new Date(dateData)
				var y = date.getFullYear()
				var m = date.getMonth() + 1
				m = m < 10 ? ('0' + m) : m
				var d = date.getDate()
				d = d < 10 ? ('0' + d) : d
				const time = y + '-' + m + '-' + d
				return time
			},
			query(){
				if(this.navState == 2){
					this.agentSplitLogList2 = []
				}else if(this.navState == 3){
					this.agentSplitLogList3 = []
				}
				let data = {
					userId:this.ruleForm.bindingUserId,
					addressIds:this.ruleForm.addressIds,
					beginDate:this.dateFormat(this.beginDate),
					endDate:this.dateFormat(this.endDate),
					type:this.navState
				}
				agentSplitLogList(data).then((res)=>{
					// console.log(res)
					if(res.code == 200 && res.data.length == 0){
						this.warnings('暂无相关内容，请更换查询条件')
					}else{
						if(this.navState == 2){
							this.agentSplitLogList2 = res.data
						}else if(this.navState == 3){
							this.agentSplitLogList3 = res.data
						}
						
					}
					
				})
			},
			warnings(mess) {
				ElMessage.warning({
					message: mess,
					type: "warning",
					center: true,
					customClass: "warnClass",
					duration: 1500,
					showClose: true,
				});
			},
			succes(mess) {
				ElMessage.success({
					message: mess,
					type: "success",
					center: true,
					customClass: "warnClass",
					duration: 2000,
					showClose: true,
				});
			},
		},
		mounted() {
			this.getList()
			this.getAddressProportionList()
		}
	}
</script>

<style scoped lang="scss">
	.nav_box{
		margin: 2rem auto 0;
		// margin-top: 2rem;
		width: 75%;
		.nav_item{
			color: #F9D855;
			border:0.1rem solid #F9D855;
			width: calc(50% - 0.2rem);
			text-align: center;
			line-height: 3rem;
		}
	}
	.nav1{
		margin-top: 2rem;
		.nav1_item{
			margin-bottom: 0.5rem;
			label{
				display: inline-block;
				width: 6rem;
				text-align: center;
			}
		}
	}
	.selected{
		color: #fff !important;
		background-color: #F9D855;
	}
	.btn{
		text-align: right;
		width: 5rem;
		margin-left: calc(96% - 5rem);
		background-color: #F9D855;
		color: #fff;
		border-radius: 0.5rem;
		text-align: center;
		line-height: 3rem;
	}
	.navs2{
		margin-top: 2rem;
	}
	.navs2_th{
		line-height: 3rem;
		div{
			width: 50%;
			text-align: center;
			background-color: rgba($color: #f9d855, $alpha: 0.5);
		}
	}
	.navs2_td{
		line-height: 3rem;
		div{
			width: 50%;
			text-align: center;
			// background-color: rgba($color: #f9d855, $alpha: 0.5);
			border-bottom: 0.1rem solid #ccc;
		}
	}
</style>
