<template>
	<PageHeader state="true" msg="端口管理"></PageHeader>
	<div class="port_box">
		<div class="clearfix num_box">
			<div class="fl item c1">空闲:{{ num1 }}</div>
			<div class="fl item c2">使用中:{{ num2 }}</div>
			<div class="fl item c3">维护中:{{ num3 }}</div>
			<div class="fl item c4">禁用:{{ num4 }}</div>
		</div>
		<div class="list_box">
			<div class="item clearfix" v-for="(item, index) in arr" :key="index">
				<div class="fl">端口号：{{ index + 1 }}</div>
        <!-- 				<div v-show="item == 1" class="fr">空闲</div>
				<div v-show="item == 2" class="fr">使用中</div>
				<div v-show="item == 3" class="fr">维护中</div> -->
				<div v-show="item == 4" class="fr c2">禁用</div>
				<div @click="start(index + 1)" v-show="item != 4" class="fr c1">启用</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getAllStatus, forbiddenChargerPort } from "../../../../http/index.js";
import { ElMessage } from "element-plus";
export default {
  data() {
    return {
      arr: [],
      num1: 0,
      num2: 0,
      num3: 0,
      num4: 0,
      chargerId: "",
    };
  },
  methods: {
    getInfo() {
      let data = {
        deviceUnique: localStorage.getItem("uniqueCode"),
      };
      getAllStatus(data)
        .then((res) => {
          if (res.code == -1) {
            this.warnings(res.message);
          } else {
            this.succes("获取信息成功");
            var str = res.data.replace("[", "").replace("]", "");
            var arr = str.split(",");
            this.arr = arr;
            var num1 = 0;
            var num2 = 0;
            var num3 = 0;
            var num4 = 0;
            for (var i = 0; i < arr.length; i++) {
              if (arr[i] == 1) {
                num1++;
              } else if (arr[i] == 2) {
                num2++;
              } else if (arr[i] == 3) {
                num3++;
              } else if (arr[i] == 4) {
                num4++;
              }
            }
            this.num1 = num1;
            this.num2 = num2;
            this.num3 = num3;
            this.num4 = num4;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    warnings(mess) {
      ElMessage.warning({
        message: mess,
        type: "warning",
        center: true,
        customClass: "warnClass",
        duration: 1500,
        showClose: true,
      });
    },
    succes(mess) {
      ElMessage.success({
        message: mess,
        type: "success",
        center: true,
        customClass: "warnClass",
        duration: 2000,
        showClose: true,
      });
    },
    start(port) {
      let data = {
        chargerId: this.chargerId,
        portNum: port,
      };
      // console.log(data);
      forbiddenChargerPort(data)
        .then((res) => {
          // console.log(res);
          if (res.code == -1) {
            this.warnings(res.message);
          } else {
            this.succes("设置成功");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    // console.log(this.$route.params.id);
    this.chargerId = this.$route.params.id;
    this.getInfo();
  },
};
</script>

<style scoped lang="scss">
.port_box {
  width: 92%;
  margin: 1rem auto 0;
  font-size: 1.3rem;
  .list_box {
    margin-top: 1rem;
    .item {
      padding: 1rem 0;
      border-bottom: 0.0625rem solid #ccc;
      .c1 {
        background-color: green;
        color: #fff;
        padding: 0 1rem;
      }
      .c2 {
        background-color: red;
        color: #fff;
        padding: 0 1rem;
      }
    }
  }

  .num_box {
    .item {
      margin-right: 1rem;
      padding: 0.5rem 1rem;
      color: #fff;
    }
    .c1 {
      background-color: green;
    }
    .c2 {
      background-color: red;
    }
    .c3 {
      background-color: #666;
    }
    .c4 {
      background-color: #333;
    }
  }
}
</style>
