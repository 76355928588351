<template>
	<PageHeader state="true" msg="查询整机所有充电端口的充电状态"></PageHeader>
	<div class="set7_box">
		<div class="item clearfix" v-for="(item, index) in arr" :key="index">
			<div class="fl">端口号：{{ index + 1 }}</div>
			<div v-show="item == 1" class="fr">空闲</div>
			<div v-show="item == 2" class="fr">使用中</div>
			<div v-show="item == 3" class="fr">维护中</div>
			<div v-show="item == 4" class="fr">禁用</div>
		</div>
	</div>
</template>

<script>
import { getAllStatus } from "../../../../http/index.js";
import { ElMessage } from "element-plus";
export default {
	data() {
		return {
			arr: [],
		};
	},
	methods: {
		getInfo() {
			let data = {
				// deviceUnique:92022674
				deviceUnique: localStorage.getItem("uniqueCode"),
			};
			getAllStatus(data).then((res) => {
				if (res.code == -1) {
					this.warnings(res.message);
				} else {
					this.succes("获取信息成功");
					var str = res.data.replace("[", "").replace("]", "");
					var arr = str.split(",");
					this.arr = arr;
				}
			})
			.catch((err) => {
				console.log(err);
			});
		},
		warnings(mess) {
			ElMessage.warning({
				message: mess,
				type: "warning",
				center: true,
				customClass: "warnClass",
				duration: 1500,
				showClose: true,
			});
		},
		succes(mess) {
			ElMessage.success({
				message: mess,
				type: "success",
				center: true,
				customClass: "warnClass",
				duration: 2000,
				showClose: true,
			});
		},
	},
	mounted() {
		this.getInfo();
	},
};
</script>

<style scoped lang="scss">
.set7_box {
	width: 92%;
	margin: 1rem auto 0;
	font-size: 1.3rem;
	.item {
		padding: 1rem 0;
		border-bottom: 0.0625rem solid #ccc;
	}
}
</style>
