<template>
  <PageHeader state="true" msg="设备地址转移"></PageHeader>
  <div class="container_box">
    <div class="title">设备唯一码：{{ code }}</div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="投放地址" prop="region">
        <el-select v-model="ruleForm.region" placeholder="请选择投放地址">
          <el-option
            v-for="item in addressList"
            :key="item.id"
            :label="item.area + item.addressName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="设备编号" prop="chargerIndex">
        <el-input
          v-model.number="ruleForm.chargerIndex"
          placeholder="自定义设备编号,同一场地不可相同"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="desc">
        <el-input
          v-model.number="ruleForm.desc"
          placeholder="请输入备注"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="btn" @click="submitForm('ruleForm')">保存</div>
  </div>
</template>

<script>
import { addressList, changeCharger } from "../../../../http/index.js";
import { ElMessage } from "element-plus";
export default {
  data() {
    return {
      code: "",
      chargerId: "",
      addressList: [],
      ruleForm: {
        chargerIndex: "",
        desc: "",
        region: "",
      },
      rules: {
        chargerIndex: [
          {
            required: true,
            message: "请输入设备编号，以便区分充电桩",
            trigger: "blur",
          },
        ],

        region: [
          { required: true, message: "请选择投放地址", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    getAddressList() {
      let data = {
        userId: localStorage.getItem("userId"),
        pageNo: 1,
        pageCount: 100,
      };
      addressList(data)
        .then((res) => {
          this.addressList = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            chargerId: this.chargerId,
            desc: this.ruleForm.desc,
            addressId: this.ruleForm.region,
            chargerIndex: this.ruleForm.chargerIndex,
          };
          changeCharger(data)
            .then((res) => {
              if (res.code == 200) {
                this.succes("设备地址转移成功");
              } else {
                this.warnings(res.message);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    warnings(mess) {
      ElMessage.warning({
        message: mess,
        type: "warning",
        center: true,
        customClass: "warnClass",
        duration: 1500,
        showClose: true,
      });
    },
    succes(mess) {
      ElMessage.success({
        message: mess,
        type: "success",
        center: true,
        customClass: "warnClass",
        duration: 2000,
        showClose: true,
      });
    },
  },
  mounted() {
    this.getAddressList();
    this.code = localStorage.getItem("uniqueCode");
    this.chargerId = this.$route.params.id;
  },
};
</script>

<style scoped lang="scss">
.container_box {
  width: 88%;
  margin: 0 auto;
  background-color: #ffff;
  padding: 1.5rem 1rem;
  border-radius: 0.8rem;
}
.title {
  width: 92%;
  margin: 0 auto;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 3rem;
  padding-bottom: 1rem;
}
.btn {
  background-color: #f9d855;
  position: fixed;
  bottom: 5%;
  width: 90%;
  left: 5%;
  color: #fff;
  border-radius: 1rem;
  line-height: 4rem;
  text-align: center;
  font-size: 1.5rem;
}
</style>
