<template>
  <PageHeader state="true" msg="帮助中心"></PageHeader>
  <div class="box" v-html="content"></div>
</template>

<script>
import { chargerGuideInfo } from "../../../http/index.js";
export default {
  data() {
    return {
      content: "",
    };
  },
  methods: {
    getInfo() {
      let data = {
        id: 1,
      };
      chargerGuideInfo(data)
        .then((res) => {
          this.content =
            "<p>" + res.data.data.content.replace(/\r\n/g, "</p> <p>") + "</p>";
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getInfo();
  },
};
</script>

<style scoped lang="scss">
.box {
  width: 92%;
  margin: 1rem auto 0;
  font-size: 1.3rem;
  line-height: 3rem;
}
</style>
