<template>
	<PageHeader msg='编辑广告图片' state='true'></PageHeader>
	<div id="" class="img_box">
		<div class="img" v-show="src != ''">
			<img :src="src" >
		</div>
		<el-upload
		  action="https://test.cxhdkj.cn/chargerApi/upload/Image"
		  list-type="picture-card" 
		  name='file' :on-success='succ'
		>
		  <i class="el-icon-plus"></i>
		</el-upload>
	</div>
	
	<div class="form_box">
		<el-form
		  :model="ruleForm"
		  :rules="rules"
		  ref="ruleForm"
		  label-width="100px"
		  class="demo-ruleForm"
		>
		  <el-form-item label="跳转地址">
		    <el-input v-model="ruleForm.name" placeholder="请输入广告跳转链接"></el-input>
		  </el-form-item>
		  
		  <el-form-item label="是否推送">
		      <el-switch v-model="ruleForm.put"></el-switch>
		    </el-form-item>
		  
		  <el-form-item>
		    <el-button type="primary" @click="submitForm('ruleForm')"
		      >确认上传</el-button
		    >
		    <el-button @click="del()">删除</el-button>
		  </el-form-item>
		</el-form>
		
	</div>
</template>

<script>
	import { agentEditAdvertisement,agentDelAdvertisement } from "../../../http/index.js";
	import { ElMessage } from "element-plus";
	export default{
		data() {
			return {
				src:'',
				ruleForm: {
					name: '',   
					put:true
				},
				rules: {
					name: [
						{ required: true, message: '请输入广告跳转链接', trigger: 'blur' },
					],
				},
			}
		},
		methods: {
			succ(response){
				this.src = response.data.imgUrl
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
				  if (valid) {
					let that = this
					var puts = 0
					if(this.ruleForm.put){
						puts = 1
					}
					if(!this.src){
						that.warnings('请选择添加图片')
						return false;
					}
					let data = {
					  imgUrl: this.src,
					  jumpUrl: this.ruleForm.name,
					  advertisementId: this.advertisementId,
					  userId: localStorage.getItem('userId'),
					  put:puts
					};
					agentEditAdvertisement(data).then((res)=>{
						if(res.code == 200){
							this.succes('操作成功')
							setTimeout(function(){
								that.$router.back()
							},2000)
						}else{
							this.warnings(res.message)
						}
					})
					.catch((err)=>{
						console.log(err)
					})
				  } else {
					console.log('error submit!!')
					return false
				  }
				})
			  },
			  del(){
				 let that = this
				  let data = {
					  advertisementId:this.advertisementId
				  }
				  agentDelAdvertisement(data).then((res)=>{
					  if(res.code == 200){
					  	this.succes('删除成功')
					  	setTimeout(function(){
					  		that.$router.back()
					  	},2000)
					  }else{
					  	this.warnings(res.message)
					  }
				  })
			  },
			  warnings(mess) {
			    ElMessage.warning({
			      message: mess,
			      type: "warning",
			      center: true,
			      customClass: "warnClass",
			      duration: 1500,
			      showClose: true,
			    });
			  },
			  succes(mess) {
			    ElMessage.success({
			      message: mess,
			      type: "success",
			      center: true,
			      customClass: "warnClass",
			      duration: 2000,
			      showClose: true,
			    });
			  },
		},
		mounted() {
			this.src = this.$route.params.adImgUrl
			if(this.$route.params.put == 1){
				this.ruleForm = {
					put:true,
				}
			}else{
				this.ruleForm = {
					put:false,
				}
			}
			this.ruleForm['name'] = this.$route.params.adJumpUrl,
			this.advertisementId = this.$route.params.id
		}
	}
</script>

<style scoped lang="scss">
	.img_box{
		height: 8rem;
		width: 92%;
		margin: 1rem auto;
		.img{
			width: 8rem;
			height: 8rem;
			border-radius: 0.5rem;
			overflow: hidden;
			float: left;
			margin-right: 1rem;
			img{
				width: 8rem;
				height: 8rem;
			}
		}
	}
	.form_box{
		width: 92%;
	}
</style>
