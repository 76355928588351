<template>
	<PageHeader state="true" msg="远程端口停止充电"></PageHeader>
	<div class="set4_box">
		<div class="item clearfix">
			<div class="fl">端口号</div>
			<div class="fr input_box">
				<el-input v-model="port" placeholder="1~10之间"></el-input>
			</div>
		</div>
		<div class="btn" @click="save()">保存设置</div>
	</div>
</template>

<script>
import { stopPort } from "../../../../http/index.js";
import { ElMessage } from "element-plus";
export default {
	data() {
		return {
			port: "",
		};
	},
	methods: {
		save() {
			let data = {
				port: this.port,
				deviceUnique: localStorage.getItem("uniqueCode"),
			};
			stopPort(data).then((res) => {
				if (res.code == -1) {
					this.warnings(res.message);
				} else {
					this.succes("保存成功");
				}
			})
			.catch((err) => {
				console.log(err);
			});
		},
		warnings(mess) {
			ElMessage.warning({
				message: mess,
				type: "warning",
				center: true,
				customClass: "warnClass",
				duration: 1500,
				showClose: true,
			});
		},
		succes(mess) {
			ElMessage.success({
				message: mess,
				type: "success",
				center: true,
				customClass: "warnClass",
				duration: 2000,
				showClose: true,
			});
		},
	},
};
</script>

<style lang="scss">
.set4_box {
	font-size: 1.3rem;
	width: 92%;
	margin: 1rem auto 0;
	.item {
		line-height: 4rem;
		height: 4rem;
		border-bottom: 0.0625rem solid #ccc;
		.input_box {
			width: 10rem;
			height: 3rem !important;
			.el-input {
				height: 3rem;
				line-height: 3rem;
				.el-input__inner {
					height: 3rem;
					line-height: 3rem;
				}
			}
		}
	}
	.btn {
		background-color: #f9d855;
		position: fixed;
		bottom: 5%;
		width: 90%;
		left: 5%;
		color: #fff;
		border-radius: 1rem;
		line-height: 4rem;
		text-align: center;
		font-size: 1.5rem;
	}
}
</style>
