<template>
	<PageHeader state="false" msg="长兴华电科技"></PageHeader>
	<div class="container_box">
		<div class="info_box">
			<div class="info_top clearfix">
			<!-- <div class="avatar_img fl">
				  <img src="../../assets/img/1.jpg" />
				</div> -->
				<div class="info_sim fl">
					<div class="name">{{ name }}</div>
					<div class="phone">{{ mobile }}</div>
				</div>
			</div>
		</div>
		<div class="nav_box">
			<el-row>
				<el-col :span="8">
					<div class="nav_item" @click="goPersonal()">
						<div>
							<img src="../../assets/icon/gerenziliao.png" />
						</div>
						<div>个人资料</div>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="nav_item" @click="a()">
						<div>
							<img src="../../assets/icon/shouyi.png" />
						</div>
						<div>场地收益</div>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="nav_item" @click="b()">
						<div>
							<img src="../../assets/icon/qushi.png" />
						</div>
						<div>收益趋势</div>
					</div>
				</el-col>
			</el-row>

			<el-row>
				<el-col :span="8">
					<div class="nav_item" @click="goMember()">
						<div>
							<img src="../../assets/icon/huiyuan.png" />
						</div>
						<div>会员管理</div>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="nav_item" @click="c()">
						<div>
							<img src="../../assets/icon/dianpu.png" />
						</div>
						<div>修理店管理</div>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="nav_item" @click="d()">
						<div>
							<img src="../../assets/icon/bangzhu.png" />
						</div>
						<div>帮助中心</div>
					</div>
				</el-col>
			</el-row>
		</div>
		
		<!-- 第二个菜单栏 -->
		<div class="nav_box">
			<el-row>
				<el-col :span="8">
					<div class="nav_item" @click="goFashionableHome()">
						<div>
							<img src="../../assets/icon/fenzhangguanli.png" />
						</div>
						<div>设置分账</div>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="nav_item" @click="goAdv()">
						<div>
							<img src="../../assets/icon/guanggaotu.png" />
						</div>
						<div>广告图</div>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="nav_item" @click="goBanner()">
						<div>
							<img src="../../assets/icon/lunbotu.png" />
						</div>
						<div>轮播图</div>
					</div>
				</el-col>
				<!-- <el-col :span="8">
					<div class="nav_item" @click="goGoods()">
						<div>
							<img src="../../assets/icon/lunbotu.png" />
						</div>
						<div>商品管理</div>
					</div>
				</el-col> -->
			</el-row>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			mobile: "",
			name: "",
		};
	},
	methods: {
		goPersonal() {
			this.$router.push("personalHome");
		},
		a() {
			this.$router.push("earnings");
		},
		b() {
			this.$router.push("earningstrend");
		},
		goMember() {
			this.$router.push("memberHome");
		},
		c() {
			this.$router.push("bikeHome");
		},
		d() {
			this.$router.push("helpHome");
		},
		goAdv() {
			this.$router.push("advHome");
		},
		goBanner() {
			this.$router.push("bannerHome");
		},
		goGoods(){
			this.$router.push("goodsHome");
		},
		goFashionableHome(){
			this.$router.push("fashionableHome");
		}
	},
	mounted() {
		this.name = JSON.parse(localStorage.getItem("userInfo"))["username"];
		var mobile = JSON.parse(localStorage.getItem("userInfo"))["mobile"];
		this.mobile = mobile.substr(0, 3) + "****" + mobile.substr(7);
	},
};
</script>

<style lang="scss" scoped>
.container_box {
	background-color: #f5f5f5;
	height: calc(100vh - 4rem);
	.info_box {
		width: 100%;
		height: 10rem;
		background-color: #fff;
		border-bottom-left-radius: 1.875rem;
		border-bottom-right-radius: 1.875rem;
		box-shadow: 0px 6px 6px #ccc;
		.info_top {
			width: 90%;
			padding: 2rem 5%;
			.avatar_img {
				width: 5.5rem;
				height: 5.5rem;
				border-radius: 100%;
				border: 0.0625rem solid #000;
				overflow: hidden;
				img {
					width: 100%;
					height: 100%;
				}
			}
			.info_sim {
				width: 60%;
				margin-left: 3%;
				.name {
					font-weight: 700;
					font-size: 2rem;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
					overflow: hidden;
					margin-bottom: 0.625rem;
				}
				.phone {
					color: #aaa;
					font-size: 1.7rem;
				}
			}
		}
		.info_bottom {
			text-align: center;
			font-size: 1.5rem;
			.num {
				font-size: 1.75rem;
				font-weight: 600;
			}
		}
	}
	.nav_box {
		width: 92%;
		margin-left: 4%;
		background-color: #fff;
		margin-top: 2.1875rem;
		// height: 7.5rem;
		border-radius: 2rem;
		text-align: center;
		.nav_item {
			padding: 1rem 0;
		}
		img {
			width: 3rem;
			height: 3rem;
		}
	}
}
</style>
