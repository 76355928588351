<template>
  <PageHeader state="true" msg="添加设备投放地址"></PageHeader>
  <div class="container_box">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="省市区" prop="citys">
        <el-cascader
          size="large"
          :options="options"
          v-model="ruleForm.citys"
          @change="handleChange" placeholder="选择省市区"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input readonly v-model="ruleForm.address" @click="open()"></el-input>
      </el-form-item>
      <el-form-item label="场地名称" prop="addressName">
        <el-input v-model="ruleForm.addressName" placeholder="请输入地址便签,以便您更好的管理充电桩"></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="ruleForm.note"></el-input>
      </el-form-item>
      <div class="btn" @click="submitForm('ruleForm')">保存</div>
    </el-form>
  </div>
  <el-drawer
    v-model="drawer"
    :direction="direction"
    :with-header="false"
    size="40rem"
    destroy-on-close 
	z-index = '111'
  >
    <div v-show="mapState == true" id='map_box'>
		<div class="map_search">
			<el-input v-model="mapValue" id="keyword" placeholder="请输入地址搜索"></el-input>
		</div>
      <div id="aaa" style="width: 100%; height: 40rem"></div>
    </div>
  </el-drawer>
</template>

<script>
import { addAddress } from "../../../http/index.js";
import { ElMessage } from "element-plus";
import { regionData, CodeToText } from "element-china-area-data";
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
  data() {
    return {
		mapState:false,
		mapValue:'',
      direction: "btt",
      drawer: false,
      area: "",
      city: "",
      province: "",
      options: regionData,
      selectedOptions: [],
      lat: "",
      lng: "",
      ruleForm: {
        address: "",
        note: "",
        citys: "",
        addressName: "",
      },
      rules: {
        address: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
        addressName: [
          { required: true, message: "请输入地址标签", trigger: "blur" },
        ],
        citys: [{ required: true, message: "请选择省市区", trigger: "change" }],
      },
    };
  },
  methods: {
    submitForm(formName) {
		let that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            userId: localStorage.getItem("userId"),
            lng: this.lng,
            lat: this.lat,
            province: this.province,
            city: this.city,
            area: this.area,
            address: this.ruleForm.address,
            isDefault: 0,
            note: this.ruleForm.note,
            addressName: this.ruleForm.addressName,
          };
          addAddress(data)
            .then((res) => {
              if (res.code == 200) {
                this.succes("保存地址成功");
				setTimeout(function(){
					that.$router.back();
				},1500)
              } else {
                this.warnings(res.message);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    warnings(mess) {
      ElMessage.warning({
        message: mess,
        type: "warning",
        center: true,
        customClass: "warnClass",
        duration: 1500,
        showClose: true,
      });
    },
    succes(mess) {
      ElMessage.success({
        message: mess,
        type: "success",
        center: true,
        customClass: "warnClass",
        duration: 2000,
        showClose: true,
      });
    },
    
    open() {
		var that = this;
		this.drawer = true;
		AMapLoader.load({
			key: "1b39665d13beae59c6c66e4180b47b13", // 申请好的Web端开发者Key，首次调用 load 时必填
			securityJsCode:'814eb2ae092a456dcc65f0738569dda8',
			version: "1.4.16", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
			plugins: ['AMap.Autocomplete','AMap.PlaceSearch'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
			AMapUI: {
				// 是否加载 AMapUI，缺省不加载
				version: "1.1", // AMapUI 缺省 1.1
				plugins: ["misc/PositionPicker", "overlay/SimpleMarker"], // 需要加载的 AMapUI ui插件
			},
			Loca: {
				// 是否加载 Loca， 缺省不加载
				version: "1.3.2", // Loca 版本，缺省 1.3.2
			},
		})
        .then(() => {
			this.mapState = true
			var map = new window.AMap.Map("aaa", { zoom: 12 ,center: [Number(localStorage.getItem('lng')), Number(localStorage.getItem('lat'))],});
			var positionPicker = new window.AMapUI.PositionPicker({
				map: map,
				mode: "dragMap",
			});
			//拖拽选点
			positionPicker.on("success", function (positionResult) {
				that.ruleForm["address"] = positionResult["nearestPOI"];
				that.lat = positionResult.position["lat"];
				that.lng = positionResult.position["lng"];
			});
			positionPicker.on("fail", function (positionResult) {
				console.log(positionResult);
			});
			positionPicker.start(map.getBounds().getSouthWest());
			
			var autoOptions = {
				input: "keyword"
			};
			var auto = new window.AMap.Autocomplete(autoOptions);
			var placeSearch = new window.AMap.PlaceSearch({
				map: map
			}); //构造地点查询类
			
			window.AMap.event.addListener(auto, "select", function(e){
				placeSearch.setCity(e.poi.adcode);
				placeSearch.search(e.poi.name); //关键字查询查询
				var info = e.poi;//地理信息
				console.log(info)
			}); //注册监听，当选中某条记录时会触发
			window.AMap.event.addListener(auto, "error", function(e){
				console.log(e)
				console.log("定位失败")
			}); //注册监听，当选中某条记录时会触发
						
        })
        .catch((e) => {
			console.log(e);
        });
    },
    handleChange(value) {
      var name = "";
      value.map((item) => (name += CodeToText[item] + "/"));
      let arr = name.split("/");
      this.province = arr[0];
      this.city = arr[1];
      this.area = arr[2];
    },
  },
  mounted() {
    // this.loadPosition();
	// this.open()
  },
};
</script>

<style scoped>
.container_box {
  width: 92%;
  margin: 1.875rem auto 0;
}
.btn {
  background-color: #f9d855;
  margin: 0rem auto 0;
  width: 90%;
  color: #fff;
  border-radius: 1rem;
  line-height: 4rem;
  text-align: center;
  font-size: 1.5rem;
}
.map_search{
	width: 100%;
	height: 4rem;
	padding: 1rem 0;
}
</style>
