<template>
	<PageHeader msg='轮播图管理' state='true'></PageHeader>
	
	<div v-if="list.length == 0">
		<el-empty description="暂无轮播图,快去上传吧" :image-size="200"></el-empty>
	</div>
	<div v-else>
		<div class="list_box">
			<div class="item clearfix" v-for="item in list" :key="item.id">
				<div class="item_img fl">
					<img :src="item.imgsUrl" >
				</div>
				<div class="fl item_info">
					跳转地址：
					<div class="item_jump">
						{{item.jumpUrl}}
					</div>
					<div class="item_time">
						上传时间:{{item.createTime}}
					</div>
				</div>
				<div class="fl item_icon" @click="goDetail(item.id,item.imgsUrl,item.jumpUrl)">
					<img src="../../../assets/icon/bianji.png" >
				</div>
					
				
				
			</div>
		</div>
	</div>
	
	<div class="btn" @click="goAdd()">
		添加轮播图
	</div>
</template>

<script>
	import { agentRotationList } from "../../../http/index.js";
	export default{
		data(){
			return {
				list:[],
				val:''
			}
		},
		methods:{
			goAdd(){
				this.$router.push('bannerAdd')
			},
			getList(){
				let data = {
					userId:localStorage.getItem('userId')
				}
				agentRotationList(data).then((res)=>{
					if(res.data.code == 200){
						this.list = res.data.data
					}
				})
			},
			goDetail(id,imgsUrl,jumpUrl){
				this.$router.push({ 
					name: "BannerEdit", 
					params: { 
						id: id, 
						imgsUrl:imgsUrl,
						jumpUrl:jumpUrl
					} ,
				});
			}
		},
		mounted() {
			this.getList()
		}
	}
</script>

<style scoped lang="scss">
	.btn{
		background-color: #f9d855;
		position: fixed;
		bottom: 5%;
		width: 90%;
		left: 5%;
		color: #fff;
		border-radius: 1rem;
		line-height: 4rem;
		text-align: center;
		font-size: 1.5rem;
	}
	
	.item{
		width: 92%;
		margin: 0 auto 1rem;
		.item_img{
			width: 9rem;
			height: 9rem;
			border-radius: 0.5rem;
			overflow: hidden;
		}
		.item_img img{
			width: 9rem;
			height: 9rem;
		}
		.item_info{
			margin-left: 1rem;
			width: 20rem;
			line-height: 2.5rem;
			font-size: 1.6rem;
			.item_jump{
				text-overflow: ellipsis;
				display:-webkit-box;
				-webkit-line-clamp:2;
				-webkit-box-orient:vertical;
				overflow: hidden;
			}
			.item_time{
				color: #999;
				font-size: 1.2rem;
			}
		}
		.item_icon{
			width: 3rem;
			height: 3rem;
			margin-top: 3rem;
			margin-left: 1rem;
			img{
				width: 3rem;
				height: 3rem;
			}
		}
		
	}
	
</style>
