<template>
  <PageHeader state="true" msg="编辑店铺"></PageHeader>
  <div class="container_box">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="省市区" prop="citys">
        <el-cascader
          size="large"
          :options="options"
          v-model="ruleForm.citys"
          @change="handleChange" placeholder="选择省市区"
        >
        </el-cascader>
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input v-model="ruleForm.address" @click="open()"></el-input>
      </el-form-item>
      <el-form-item label="店铺名称" prop="shopName">
        <el-input v-model="ruleForm.shopName"></el-input>
      </el-form-item>
	  <el-form-item label="店铺类型" prop="shopType">
	      <el-select v-model="ruleForm.shopType" placeholder="请选择店铺类型">
	        <el-option label="电瓶车店铺" value="1"></el-option>
	  			<el-option label="电瓶车修理" value="2"></el-option>
	  			<el-option label="品牌电池" value="3"></el-option>
	  			<el-option label="家政" value="4"></el-option>
	      </el-select>
	    </el-form-item>
      <el-form-item label="联系人姓名" prop="contacts">
        <el-input v-model="ruleForm.contacts"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="tel">
        <el-input v-model.number="ruleForm.tel"></el-input>
      </el-form-item>
      <!-- <div class="btn" @click="submitForm('ruleForm')">保存</div> -->
      <div class="clearfix">
        <div class="btn fl" @click="submitForm('ruleForm')">保存</div>
        <div class="btn btn1 fl" @click="del()">删除</div>
      </div>
    </el-form>
  </div>
  <el-drawer
    v-model="drawer"
    :direction="direction"
    :with-header="false"
    size="40rem"
    destroy-on-close
  >
    <div>
      <div id="aaa" style="width: 100%; height: 40rem"></div>
    </div>
  </el-drawer>
</template>

<script>
import {
  bikeShopInfo,
  editBikeShop,
  delBikeShop,
} from "../../../http/index.js";
import AMapLoader from "@amap/amap-jsapi-loader";
import { ElMessage } from "element-plus";
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
export default {
  data() {
    return {
      id: "",
      direction: "btt",
      drawer: false,
      area: "",
      city: "",
      province: "",
      options: regionData,
      selectedOptions: [],
      lat: "",
      lng: "",
      ruleForm: {
        address: "",
        tel: "",
        citys: "",
        shopName: "",
        contacts: "",
		shopType:''
      },
      rules: {
        address: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
        tel: [
          { required: true, message: "请输入联系人手机号码", trigger: "blur" },
          {
            type: "number",
            message: "请输入11位数字的手机号码",
            trigger: "blur",
          },
        ],
        shopName: [
          { required: true, message: "请输入店铺名称", trigger: "blur" },
        ],
		shopType:[
			{required: true, message: "请选择店铺类型", trigger: "change"}
		],
        contacts: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
        ],
        citys: [{ required: true, message: "请选择省市区", trigger: "change" }],
      },
    };
  },
	methods: {
		getInfo(data) {
			let datas = {
				eBikeId: data,
			};
			bikeShopInfo(datas).then((res) => {
				console.log(res)
				var data = res.data;
				this.lat = data.lat;
				this.lng = data.lng;
				this.province = data.province;
				this.city = data.city;
				this.area = data.area;
		
				let a = TextToCode[data.province]["code"].toString();
				let b = TextToCode[data.province][data.city]["code"].toString();
				let c = TextToCode[data.province][data.city][data.area]["code"].toString();
				var shopTypeName = ''
				if(data.shopType == 1){
					shopTypeName = '电瓶车店铺'
				}else if(data.shopType == 2){
					shopTypeName = '电瓶车修理'
				}else if(data.shopType == 3){
					shopTypeName = '品牌电池'
				}else if(data.shopType == 4){
					shopTypeName = '家政'
				}
				this.ruleForm = {
					citys: [a, b, c],
					address: data.address,
					tel: Number(data.tel),
					shopName: data.shopName,
					contacts: data.contacts,
					shopType:shopTypeName
				};
			})
			.catch((error) => {
				console.log(error);
			});
		},
		open() {
		var that = this;
      this.drawer = true;
      AMapLoader.load({
        key: "1768cf4203d4f32744232fd62ddd47c3", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "1.4.16", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: "1.1", // AMapUI 缺省 1.1
          plugins: ["misc/PositionPicker", "overlay/SimpleMarker"], // 需要加载的 AMapUI ui插件
        },
        Loca: {
          // 是否加载 Loca， 缺省不加载
          version: "1.3.2", // Loca 版本，缺省 1.3.2
        },
      })
        .then(() => {
          var map = new window.AMap.Map("aaa", { zoom: 12 });
          var positionPicker = new window.AMapUI.PositionPicker({
            map: map,
            mode: "dragMap",
          });
          positionPicker.on("success", function (positionResult) {
            that.ruleForm["address"] = positionResult["nearestPOI"];
            that.lat = positionResult.position["lat"];
            that.lng = positionResult.position["lng"];
          });
          positionPicker.on("fail", function (positionResult) {
            console.log(positionResult);
          });
          positionPicker.start(map.getBounds().getSouthWest());
        })
        .catch((e) => {
          console.log(e);
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            eBikeId: this.id,
            userId: localStorage.getItem("userId"),
            province: this.province,
            city: this.city,
            area: this.area,
            address: this.ruleForm["address"],
            lat: this.lat,
            lng: this.lng,
            shopName: this.ruleForm["shopName"],
            tel: this.ruleForm["tel"],
            contacts: this.ruleForm["contacts"],
			shopType:this.ruleForm['shopType']
          };
          editBikeShop(data)
            .then((res) => {
              if (res.code == 200) {
                this.succes("编辑店铺成功");
              } else {
                this.warnings(res.message);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    del() {
      let data = {
        eBikeIds: this.id,
      };
      delBikeShop(data)
        .then((res) => {
          if (res.code == 200) {
            this.succes("删除店铺成功");
          } else {
            this.warnings(res.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    warnings(mess) {
      ElMessage.warning({
        message: mess,
        type: "warning",
        center: true,
        customClass: "warnClass",
        duration: 1500,
        showClose: true,
      });
    },
    succes(mess) {
      ElMessage.success({
        message: mess,
        type: "success",
        center: true,
        customClass: "warnClass",
        duration: 2000,
        showClose: true,
      });
    },
    handleChange(value) {
      var name = "";
      value.map((item) => (name += CodeToText[item] + "/"));
      let arr = name.split("/");
      this.province = arr[0];
      this.city = arr[1];
      this.area = arr[2];
    },
  },
  mounted() {
    this.getInfo(this.$route.params.eBikeId);
    this.id = this.$route.params.eBikeId;
  },
};
</script>

<style scoped>
.container_box {
	width: 92%;
	margin: 1.875rem auto 0;
}
.btn {
	background-color: #f9d855;
	margin: 10rem auto 0;
	width: 42.5%;
	color: #fff;
	border-radius: 1rem;
	line-height: 4rem;
	text-align: center;
	font-size: 1.5rem;
}
.btn1 {
	margin-left: 15%;
	background-color: red;
}
</style>
