<template>
  <PageHeader state="true" msg="收益趋势"></PageHeader>
  <div class="container_box">
    <div class="clearfix time_box">
      <div
        @click="changeTime(1)"
        class="fl item"
        :class="{ active: timeId == 1 }"
      >
        近30天
      </div>
      <div
        @click="changeTime(2)"
        class="fl item"
        :class="{ active: timeId == 2 }"
      >
        近半年
      </div>
    </div>
    <div class="info_box">
      <div class="top">
        <div class="title">合计收益</div>
      </div>
      <div class="bottom">
        <div class="clearfix">
          <div class="fl time">{{ info.payTime }}</div>
          <div class="fl price">{{ info.totalAmount }}元</div>
        </div>
        <div class="clearfix on_off">
          <div class="fl item">
            <div>线上支付</div>
            <div>{{ info.onLineAmount }}元</div>
          </div>
          <div class="fl item">
            <div>线下支付</div>
            <div>{{ info.offLineAmount }}元</div>
          </div>
        </div>
      </div>
    </div>

    <div id="aa"></div>
  </div>
</template>

<script>
import { revenueTrend } from "../../../http/index.js";
import * as echarts from "echarts";
export default {
  data() {
    return {
      timeId: 1,
      info: [],
    };
  },
  methods: {
    changeTime(val) {
      this.timeId = val;
      var now = new Date();
      switch (val) {
        case 1:
          now.setDate(now.getDate() - 30);
          var start = this.dateToString(
            new Date(new Date(now.getTime()).setHours(0, 0, 0, 0))
          );
          var end = this.dateToString(
            new Date(new Date(new Date().getTime()).setHours(23, 59, 59, 999))
          );
          var data = {
            startDate: start,
            endDate: end,
            userId: localStorage.getItem("userId"),
            // userId:147,
            month: false,
          };
          this.getInfo(data);
          break;
        case 2:
          now.setMonth(now.getMonth() - 6);
          var start2 = this.dateToString(
            new Date(new Date(now.getTime()).setHours(0, 0, 0, 0))
          );
          var end2 = this.dateToString(
            new Date(new Date(new Date().getTime()).setHours(23, 59, 59, 999))
          );
          var data2 = {
            startDate: start2,
            endDate: end2,
            userId: localStorage.getItem("userId"),
            // userId:147,
            month: true,
          };
          this.getInfo(data2);
          break;
        default:
      }
    },
    getInfo(data) {
      var flag = 0;
      if (data["month"] == true) {
        flag = 1;
      } else {
        flag = 2;
      }
      revenueTrend(data)
        .then((res) => {
          // console.log(res)
          if (res.data.length > 0) {
            this.echartInit(res.data, flag);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    dateToString(date) {
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let minute = date.getMinutes();
      let second = date.getSeconds();
      month = month > 9 ? month : "0" + month;
      day = day > 9 ? day : "0" + day;
      hour = hour > 9 ? hour : "0" + hour;
      minute = minute > 9 ? minute : "0" + minute;
      second = second > 9 ? second : "0" + second;
      const dateTime =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second;
      return dateTime;
    },
    echartInit(data, flag) {
      var that = this;
      that.info = data[data.length - 1];
      var myChart = echarts.init(document.getElementById("aa"));
      var option = {
        xAxis: {
          boundaryGap: false,
          type: "category",
          data: [],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [],
            type: "line",
            smooth: true,
            symbol: "circle",
            symbolSize: 8,
            itemStyle: {
              normal: {
                color: "#000",
                lineStyle: {
                  color: "#f9d855",
                },
              },
            },
          },
        ],
      };
      for (var i = 0; i < data.length; i++) {
        if (flag == 2) {
          option["xAxis"]["data"].push(
            data[i]["payTime"].split("-")[1] +
              "-" +
              data[i]["payTime"].split("-")[2]
          );
        } else {
          option["xAxis"]["data"].push(data[i]["payTime"]);
        }
        option["series"][0]["data"].push(data[i]["totalAmount"]);
      }
      myChart.setOption(option);
      myChart.on("click", function (params) {
        that.info = data[params.dataIndex];
      });
    },
  },
  mounted() {
    this.changeTime(1);
  },
};
</script>

<style scoped lang="scss">
.container_box {
  background-color: #f5f5f5;
  height: calc(100vh - 4rem);
}
.time_box {
  background-color: #fff;
  padding-bottom: 0.8rem;
  .item {
    background-color: #f5f5f5;
    margin-left: 1.5rem;
    padding: 0.6rem 1.3rem;
    font-size: 1.2rem;
    border: 0.0625rem solid #f5f5f5;
    border-radius: 0.5rem;
  }
  .active {
    background-color: #fff !important;
    color: #f9d855;
    border-color: #f9d855;
  }
}
#aa {
  width: 92%;
  margin: 0 auto;
  height: 30rem;
}
.info_box {
  background-color: #fff;
  width: 88%;
  margin: 1rem auto 0;
  border-radius: 0.8rem;
  padding: 1rem 2%;
  .top {
    border-bottom: 0.2rem solid #ccc;
    .title {
      color: #f9d855;
      width: 20%;
      text-align: center;
      margin: 0 auto;
      border-bottom: 0.3rem solid #f9d855;
      font-size: 1.5rem;
      padding-bottom: 0.5rem;
    }
  }
  .bottom {
    margin-top: 1rem;
    font-size: 1.3rem;
    line-height: 2.5rem;
    .time {
      color: #fff;
      background-color: #f9d855;
      padding: 0 1rem;
      border-radius: 0.5rem;
    }
    .price {
      color: #f9d855;
      font-weight: 600;
      margin-left: 1rem;
    }
    .on_off {
      padding-top: 0.5rem;
      .item {
        text-align: center;
        color: #999;
        width: 49%;
      }
    }
  }
}
</style>
