import { get, post } from "./http.js";

export const agentReg = (data) => post("user/agentReg", data); //注册
export const loginByPwd = (data) => post("user/loginByPwd", data); //登录
export const chargerPackageList = (data) =>
  get("chargerPackage/chargerPackageList", data); //套餐列表
export const addChargerPackage = (data) =>
  post("chargerPackage/addChargerPackage", data); //套餐增加
export const editChargerPackage = (data) =>
  post("chargerPackage/editChargerPackage", data); //套餐编辑
export const delChargerPackage = (data) =>
  post("chargerPackage/delChargerPackage", data); //套餐删除
export const getChargerPackage = (data) =>
  get("chargerPackage/getChargerPackage", data); //获取单个套餐信息
export const addressList = (data) => post("chargerAddress/addressList", data); //地址列表
export const addAddress = (data) => post("chargerAddress/addAddress", data); //地址增加
export const editAddress = (data) => post("chargerAddress/editAddress", data); //地址编辑
export const delAddress = (data) => post("chargerAddress/delAddress", data); //地址删除
export const getAddress = (data) => get("chargerAddress/getAddress", data); //获取单个地址信息
export const chargerFaultList = (data) =>
  get("chargerFault/chargerFaultList", data); //故障列表
export const faultDetails = (data) => get("chargerFault/faultDetails", data); //故障详情
export const handleFault = (data) => post("chargerFault/handleFault", data); //故障处理
export const addCharger = (data) => post("charger/addCharger", data); //设备注册
export const unbindCharger = (data) => post("charger/unbindCharger", data); //设备解绑
export const addChargerCharging = (data) =>
  post("chargerCharging/addChargerCharging", data); //增加设备套餐
export const editChargerCharging = (data) =>
  post("chargerCharging/editChargerCharging", data); //编辑设备套餐
export const delChargerCharging = (data) =>
  post("chargerCharging/delChargerCharging", data); //删除设备套餐
export const getUserInfo = (data) => get("user/getUserInfo", data); //获取个人信息
export const agentIdentity = (data) => post("user/agentIdentity", data); //实名认证
export const agentBank = (data) => post("user/agentBank", data); //绑定银行卡
export const getBankCode = (data) => post("bankCode/getBankCode", data); //获取银行code
export const chargerList = (data) => post("charger/chargerList", data); //设备列表
export const operationInfo = (data) => post("operation/operationInfo", data); //场地收益统计
export const addressOperationInfo = (data) =>
  post("operation/addressOperationInfo", data); //场地收益详情
export const revenueTrend = (data) => post("operation/revenueTrend", data); //收益趋势
export const agentUserRank = (data) => post("user/agentUserRank", data); //会员管理
export const agentUserOrder = (data) => post("user/agentUserOrder", data); //会员详情
export const bikeShopList = (data) => post("bikeShop/bikeShopList", data); //店铺列表

export const addBikeShop = (data) => post("bikeShop/addBikeShop", data); //添加修理店
export const editBikeShop = (data) => post("bikeShop/editBikeShop", data); //编辑修理店
export const bikeShopInfo = (data) => post("bikeShop/bikeShopInfo", data); //获取修理店信息
export const delBikeShop = (data) => post("bikeShop/delBikeShop", data); //删除修理店
export const chargerGuideInfo = (data) =>
  get("chargerGuide/chargerGuideInfo", data); //帮助中心

export const setMaxPower = (data) => post("charger/setMaxPower", data); //2
export const getMaxPower = (data) => post("charger/getMaxPower", data); //2
export const setFree = (data) => post("charger/setFree", data); //7
export const setFloating = (data) => post("charger/setFloating", data); //8
export const getVersion = (data) => post("charger/getVersion", data); //6
export const setCard = (data) => post("charger/setCard", data); //3
export const getConsumption = (data) => post("charger/getConsumption", data); //1
export const stopPort = (data) => post("charger/stopPort", data); //4
export const getAllStatus = (data) => post("charger/getAllStatus", data); //5

export const startUp = (data) => post("charger/startUp", data); //启动充电桩端口
export const forbiddenCharger = (data) =>
  post("charger/forbiddenCharger", data); //禁用充电桩
export const forbiddenChargerPort = (data) =>
  post("charger/forbiddenChargerPort", data); //禁用充电桩端口

export const chargerChargingList = (data) =>
  get("chargerCharging/chargerChargingList", data); //充电桩套餐列表
export const chargerChargingInfo = (data) =>
  get("chargerCharging/chargerChargingInfo", data); //充电桩套餐单个信息
export const changeCharger = (data) => post("charger/changeCharger", data); //充电桩地址转移
export const agentAddressChargerList = (data) => post("charger/agentAddressChargerList", data); //
export const addressChargerList = (data) => post("charger/addressChargerList", data); //

export const Image = (data) => post("upload/Image", data); //
export const agentAddRotation = (data) => post("rotation/agentAddRotation", data); //添加轮播图
export const agentRotationList = (data) => get("rotation/agentRotationList", data); //列表轮播图
export const agentEditRotation = (data) => post("rotation/agentEditRotation", data); //编辑轮播图
export const agentDelRotation = (data) => post("rotation/agentDelRotation", data); //删除轮播图

export const agentAddAdvertisement = (data) => post("advertisement/agentAddAdvertisement", data); //添加广告图
export const agentAdvertisementList = (data) => get("advertisement/agentAdvertisementList", data); //列表广告图
export const agentEditAdvertisement = (data) => post("advertisement/agentEditAdvertisement", data); //编辑广告图
export const agentDelAdvertisement = (data) => post("advertisement/agentDelAdvertisement", data); //删除广告图

export const getSMSCode = (data) => post("/user/getSMSCode", data); //注册获取验证码

export const flow = (data) => post("/agentFlow/flow", data); //流量续费订单
export const flowWeCharPay = (data) => post("/agentFlow/flowWeCharPay", data); //流量续费订单

export const agentEnterpriseIdentity = (data) => post("user/agentEnterpriseIdentity", data); //对公认证


export const agentForgetPwdReq = (data) => post("user/agentForgetPwdReq", data); //忘记密码

export const addAgentSplit = (data) => post("agentSplit/addAgentSplit", data); //添加分成人员
export const agentSplitList = (data) => post("agentSplit/agentSplitList", data); //分成人员列表
export const delAgentSplit = (data) => post("agentSplit/delAgentSplit", data); //删除分成人员
export const agentSplitDetail = (data) => post("agentSplit/agentSplitDetail", data); //分成人员详情
export const addressProportionList = (data) => post("agentProportion/proportionList", data); //分成场地列表
export const addAgentProportion = (data) => post("agentProportion/addAgentProportion", data); //场地添加分成
export const delAgentProportion = (data) => post("agentProportion/delAgentProportion", data); //场地删除分成
export const editAgentProportion = (data) => post("agentProportion/editAgentProportion", data); //场地编辑分成
 
export const agentProportionList = (data) => post("agentProportion/agentProportionList", data); //场地分成人员
export const agentSplitLogList = (data) => post("agentSplit/agentSplitLogList", data); //分成查询