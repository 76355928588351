<template>
	<PageHeader state="false" msg="长兴华电科技"></PageHeader>
	<div class="container_box">
		<div class="top_box">
			<div>今日总收益</div>
			<div>{{ allAmount }}元</div>
		</div>
		<!-- 菜单栏 -->
		<div class="nav_box">
			<el-row>
				<el-col :span="8">
					<div class="nav_item" @click="goManage()">
						<div><img src="../../assets/icon/guanli.png" /></div>
						<div>设备管理</div>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="nav_item" @click="goReg()">
						<div><img src="../../assets/icon/zhuce.png" /></div>
						<div>设备注册</div>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="nav_item" @click="goFault()">
						<div><img src="../../assets/icon/guzhang.png" /></div>
						<div>设备报修</div>
					</div>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="8">
					<div class="nav_item" @click="goAddress()">
						<div><img src="../../assets/icon/dizhi.png" /></div>
						<div>设备地址</div>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="nav_item" @click="goPackage()">
						<div><img src="../../assets/icon/taocan.png" /></div>
						<div>设备套餐</div>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
import { operationInfo } from "../../http/index.js";
import { ElMessageBox } from 'element-plus'
export default {
	data() {
		return {
			allAmount: "",
		};
	},
	methods: {
		goPackage() {
			this.$router.push("packageHome");
		},
		goAddress() {
			this.$router.push("addressHome");
		},
		goFault() {
			this.$router.push("faultHome");
		},
		goReg() {
			let userInfo = JSON.parse(localStorage.getItem('userInfo'))
			if(userInfo.identityStatus == 0){
				ElMessageBox.confirm(
					'您还没有实名，将无法进行设备注册?',
					'提示',
					{
						confirmButtonText: '去实名',
						cancelButtonText: '取消',
						type: 'warning',
					}
				)
				.then((res) => {
					if(res == 'confirm'){
						this.$router.push('identity')
					}
				})
				.catch(() => {})
				return false;
			}
			if(userInfo.agentBankStatus == 0){
				ElMessageBox.confirm(
					'您还没有绑定银行卡，将无法进行设备注册?',
					'提示',
					{
					  confirmButtonText: '去绑定',
					  cancelButtonText: '取消',
					  type: 'warning',
					}
				)
				.then((res) => {
					if(res == 'confirm'){
						this.$router.push('bankAdd')
					}
				})
				.catch(() => {})
				return false;
			}
			this.$router.push("regHome");
		},
		goManage() {
			let userInfo = JSON.parse(localStorage.getItem('userInfo'))
			if(userInfo.identityStatus == 0){
				ElMessageBox.confirm(
					'您还没有实名，将无法进行设备注册?',
					'提示',
					{
						confirmButtonText: '去实名',
						cancelButtonText: '取消',
						type: 'warning',
					}
				)
				.then((res) => {
					if(res == 'confirm'){
						this.$router.push('identity')
					}
				})
				.catch(() => {})
				return false;
			}
			if(userInfo.agentBankStatus == 0){
				ElMessageBox.confirm(
					'您还没有绑定银行卡，将无法进行设备注册?',
					'提示',
					{
					  confirmButtonText: '去绑定',
					  cancelButtonText: '取消',
					  type: 'warning',
					}
				)
				.then((res) => {
					if(res == 'confirm'){
						this.$router.push('bankAdd')
					}
				})
				.catch(() => {})
				return false;
			}
			this.$router.push("manageHome");
		},
		getInfo() {
			var start = this.dateToString(new Date(new Date(new Date().getTime()).setHours(0, 0, 0, 0)));
			var end = this.dateToString(new Date(new Date(new Date().getTime()).setHours(23, 59, 59, 999)));
			var data = {
				startDate: start,
				endDate: end,
				userId: localStorage.getItem("userId"),
			};
			operationInfo(data).then((res) => {
				this.allAmount = res.data.allAmount;
			})
			.catch((error) => {
				console.log(error);
			});
		},
		dateToString(date) {
			const year = date.getFullYear();
			let month = date.getMonth() + 1;
			let day = date.getDate();
			let hour = date.getHours();
			let minute = date.getMinutes();
			let second = date.getSeconds();
			month = month > 9 ? month : "0" + month;
			day = day > 9 ? day : "0" + day;
			hour = hour > 9 ? hour : "0" + hour;
			minute = minute > 9 ? minute : "0" + minute;
			second = second > 9 ? second : "0" + second;
			const dateTime = year + "-" +month +"-" +day +" " +hour +":" +minute +":" +second;
			return dateTime;
		},
	},
	mounted() {
		this.getInfo();
		let userInfo = JSON.parse(localStorage.getItem('userInfo'))
		if(userInfo.identityStatus == 0){
			ElMessageBox.confirm(
				'您还没有实名，将无法进行设备注册?',
				'提示',
				{
					confirmButtonText: '去实名',
					cancelButtonText: '取消',
					type: 'warning',
				}
			)
			.then((res) => {
				if(res == 'confirm'){
					this.$router.push('identity')
				}
			})
			.catch(() => {})
		}
	},
};
</script>

<style scoped lang="scss">
.container_box {
	background-color: #f5f5f5;
	height: calc(100vh - 6rem);
	.top_box {
		background-color: #f9d855;
		height: 15rem;
		width: 100%;
		text-align: center;
		color: #fff;
		font-size: 2.5rem;
		line-height: 5rem;
		padding: 5rem 0 0;
	}
}
.nav_box {
	width: 92%;
	margin-left: 4%;
	background-color: #fff;
	margin-top: 2.1875rem;
	border-radius: 2rem;
	text-align: center;
	.nav_item {
		padding: 1rem 0;
	}
	img {
		width: 3rem;
		height: 3rem;
	}
}
</style>
