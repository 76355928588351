<template>
	<PageHeader state="true" msg="设备参数设置"></PageHeader>
	<div>
		<div>
			<div class="item clearfix" @click="fun1()">
				<div class="fl">1.查询消费总金额数据</div>
					<div class="fr img_box">
						<img src="../../../assets/icon/right.png" />
					</div>
				</div>
				<div class="item clearfix" @click="fun2()">
					<div class="fl">2.IC卡、投币、最大功率设置</div>
					<div class="fr img_box">
						<img src="../../../assets/icon/right.png" />
					</div>
				</div>
				<div class="item clearfix" @click="fun3()">
					<div class="fl">3.IC卡、投币器是否可用</div>
					<div class="fr img_box">
						<img src="../../../assets/icon/right.png" />
					</div>
				</div>
				<div class="item clearfix" @click="fun4()">
					<div class="fl">4.远程端口停止充电</div>
					<div class="fr img_box">
						<img src="../../../assets/icon/right.png" />
					</div>
				</div>
      <!-- <div class="item clearfix">
				<div class="fl">5.3档计费功率、比例</div>
				<div class="fr img_box">
					<img src="../../../assets/icon/right.png" >
				</div>
			</div>
			<div class="item clearfix">
				<div class="fl">6.充满自停、刷卡是否退费</div>
				<div class="fr img_box">
					<img src="../../../assets/icon/right.png" >
				</div>
			</div> -->
			<div class="item clearfix" @click="fun5()">
				<div class="fl">5.查询整机所有充电端口的充电状态</div>
				<div class="fr img_box">
					<img src="../../../assets/icon/right.png" />
				</div>
			</div>
			<div class="item clearfix" @click="fun6()">
				<div class="fl">6.读取设备的版本号</div>
				<div class="fr img_box">
					<img src="../../../assets/icon/right.png" />
				</div>
			</div>
			<div class="item clearfix" @click="fun7()">
				<div class="fl">7.设置免费充电模式、音量调节</div>
				<div class="fr img_box">
					<img src="../../../assets/icon/right.png" />
				</div>
			</div>
			<div class="item clearfix" @click="fun8()">
				<div class="fl">8.设置最低浮充功率、浮充时间</div>
				<div class="fr img_box">
					<img src="../../../assets/icon/right.png" />
				</div>
			</div>
      <!-- <div class="item clearfix">
				<div class="fl">11.查询设备其他动态参数</div>
				<div class="fr img_box">
					<img src="../../../assets/icon/right.png" >
				</div>
			</div>
			<div class="item clearfix">
				<div class="fl">12.读取设备所有设置数据</div>
				<div class="fr img_box">
					<img src="../../../assets/icon/right.png" >
				</div>
			</div>
			<div class="item clearfix">
				<div class="fl">13.设置浮充功率、浮充时间、充电器移除...</div>
				<div class="fr img_box">
					<img src="../../../assets/icon/right.png" >
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
export default {
  methods: {
    fun1() {
		this.$router.push("paramSet1");
    },
    fun2() {
		this.$router.push("paramSet2");
    },
    fun3() {
		this.$router.push("paramSet3");
    },
    fun4() {
		this.$router.push("paramSet4");
    },
    fun5() {
		this.$router.push("paramSet5");
    },
    fun6() {
		this.$router.push("paramSet6");
    },
    fun7() {
		this.$router.push("paramSet7");
    },
    fun8() {
		this.$router.push("paramSet8");
    },
  },
};
</script>

<style scoped lang="scss">
.item {
	width: 92%;
	margin: 0 auto;
	padding: 1rem 0;
	line-height: 2.5rem;
	font-size: 1.3rem;
	border-bottom: 0.0625rem solid #ccc;
	.img_box {
		width: 2rem;
		height: 2rem;
		margin-top: 0.25rem;
		img {
			width: 100%;
			height: 100%;
		}
	}
}
</style>
